import React from 'react'
import {Navbar} from 'reactstrap'
import {Route, Switch} from 'react-router-dom'

import ToolTimeJumbotron from '../../../components/ToolTimeJumbotron'
import ToolTimeNav from '../../../components/ToolTimeNav'
import ToolTimeTabs from '../../../components/ToolTimeTabs'
import AddSoldOutDates from './SoldOutDates/AddSoldOutDates'
import {ADD_SOLD_OUT_DATES, EDIT_SOLD_OUT_DATES, ROOT_PRODUCT_ROUTE, SOLD_OUT_DATES} from '../routes/routes'
import SoldOutDates from './SoldOutDates/SoldOutDates'

import '../../../static/scss/core-tbt-admin/tbt-admin.scss'

const NAVIGATION_MENU = [{id: 1, name: 'Sold Out Dates', url: '/product-management/sold-out-dates'}]

const Products = () => (
  <section>
    <Navbar className="tooltime-navbar" color="inverse" fixed="top">
      <ToolTimeNav title="Manage Products" tabsList={<ToolTimeTabs tabsList={NAVIGATION_MENU} dark />} />
    </Navbar>
    <Route
      exact
      path={ROOT_PRODUCT_ROUTE}
      render={() => <ToolTimeJumbotron title="Product Management" lead="Everything you need to manage products" />}
    />
    <div className="pt-3">
      <Switch>
        <Route exact path={SOLD_OUT_DATES} component={SoldOutDates} />
        <Route path={ADD_SOLD_OUT_DATES} component={AddSoldOutDates} />
        <Route path={EDIT_SOLD_OUT_DATES} component={AddSoldOutDates} />
      </Switch>
    </div>
  </section>
)

export default Products
