import React from 'react'
import PropTypes from 'prop-types'
import {Field, formValueSelector} from 'redux-form'
import {connect} from 'react-redux'

import * as store from '../constants/store'
import * as en from '../constants/enums'
import {ReduxFormInput} from '../../../components/ReduxFormInput'
import {TwoColumnRow, FourColumnRow, Label} from './ReusableComponents/FormattedRows'
import {SurveySelectField} from './ReusableComponents/FormSelectFields'
import {stringToNumber, currentAge, validateWholeNumber} from '../constants/lib'

// HEIGHT //
export function Height() {
  return (
    <FourColumnRow
      col1={<Label label="Height" />}
      col2={
        <TwoColumnRow
          col1={<SurveySelectField formFieldName={en.surveyFormHeightFeet} />}
          col2={<SurveySelectField formFieldName={en.surveyFormHeightInches} />}
        />
      }
      rowClass="sp-form-row height"
    />
  )
}

// WEIGHT //
function WeightUnconnected({surveyValidators, surveyIsDisabled}) {
  return (
    <FourColumnRow
      col1={<Label label="Weight" />}
      col2={
        <TwoColumnRow
          col1={
            <Field
              name={en.surveyFormWeight}
              component={ReduxFormInput}
              parse={stringToNumber}
              id={en.surveyFormWeight}
              type="number"
              validate={[validateWholeNumber(surveyValidators.weight)]}
              readOnly={surveyIsDisabled}
              className="sp-form-field"
            />
          }
          col2={<div className="sp-display-field">LBS</div>}
        />
      }
      rowClass="sp-form-row weight"
    />
  )
}

WeightUnconnected.propTypes = {
  surveyValidators: PropTypes.object.isRequired,
  surveyIsDisabled: PropTypes.bool.isRequired,
}

function mapStateToPropsWeight(state) {
  return {
    surveyIsDisabled: store.surveyIsDisabled(state),
    surveyValidators: store.surveyValidators(state),
  }
}

export const Weight = connect(mapStateToPropsWeight)(WeightUnconnected)

// AGE //
// This component must be separate from the BirthYear component
// formValueSelector rerenders the entire form when the value it's selecting changes
// So every change would unmount the BirthYear field and bugs would crop up
function AgeUnconnected({yearBorn}) {
  return <div className="sp-display-field">{currentAge(yearBorn)}</div>
}

AgeUnconnected.defaultProps = {
  yearBorn: undefined,
}

AgeUnconnected.propTypes = {
  yearBorn: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const selector = formValueSelector(en.formName)
function mapStateToPropsAge(state) {
  return {
    yearBorn: selector(state, en.surveyFormBirthdate),
  }
}

export const Age = connect(mapStateToPropsAge)(AgeUnconnected)

// BIRTH YEAR //
function BirthYearUnconnected({surveyValidators, surveyIsDisabled}) {
  return (
    <FourColumnRow
      col1={<Label label="Birth Year" />}
      col2={
        <TwoColumnRow
          col1={
            <Field
              name={en.surveyFormBirthdate}
              component={ReduxFormInput}
              parse={stringToNumber}
              type="number"
              id={en.surveyFormBirthdate}
              validate={[validateWholeNumber(surveyValidators.birthdate)]}
              readOnly={surveyIsDisabled}
              className="sp-form-field"
            />
          }
          col2={<Age />}
        />
      }
      rowClass="sp-form-row birth-year"
    />
  )
}

BirthYearUnconnected.propTypes = {
  surveyValidators: PropTypes.object.isRequired,
  surveyIsDisabled: PropTypes.bool.isRequired,
}

function mapStateToPropsBirthYear(state) {
  return {
    surveyIsDisabled: store.surveyIsDisabled(state),
    surveyValidators: store.surveyValidators(state),
  }
}

export const BirthYear = connect(mapStateToPropsBirthYear)(BirthYearUnconnected)
