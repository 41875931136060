import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Input} from 'reactstrap'

export const optionsShape = {
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
}

export function createOptions(options) {
  return options.map((option) => (
    <option key={option.key} value={option.value} disabled={option.disabled}>
      {option.label}
    </option>
  ))
}

export function ToolTimeInput({input, disabled, className, type, id, options}) {
  const selectOptions = options ? createOptions(options) : null
  const classes = classNames(className, {
    'custom-select': type === 'select',
    'custom-control-input': type === 'checkbox',
  })

  return (
    <Input
      {...input} // for redux-form compatibility
      disabled={disabled}
      className={classes}
      type={type}
      id={id}
    >
      {selectOptions}
    </Input>
  )
}

ToolTimeInput.defaultProps = {
  input: {},
  disabled: false,
  className: null,
  id: null,
  options: null,
}

ToolTimeInput.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.shape(optionsShape)),
}
