import {fromJS} from 'immutable'
import {reducer} from './helpers'
import {CLOSE_SITE_MENU, OPEN_SITE_MENU} from '../actions/siteMenu'

const INITIAL_STATE = fromJS({
  isOpen: false,
})

export default reducer(INITIAL_STATE, (state, action) => {
  switch (action.type) {
    case CLOSE_SITE_MENU:
      return {isOpen: false}
    case OPEN_SITE_MENU:
      return {isOpen: true}
    default:
      return null
  }
})
