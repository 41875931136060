export function getAPI() {
  return window.__RUNTIME_CONFIG__.REACT_APP_PRODUCT_SERVICE_URL
}

export function getHeaders(token) {
  const headers = new Headers({
    Authorization: `jwt ${token}`,
    'Content-Type': 'application/json',
  })
  return headers
}
