import {fsa, fsaError, fsaPayload, fsaPayloadByKeys} from '../helpers'
import {Customer} from '../../../types'

export enum PPCustomerAction {
  GET_CUSTOMER_INFO = 'customer/get customer info',
  GET_CUSTOMER_INFO_SUCCESS = 'customer/get customer info succeeded',
  GET_CUSTOMER_INFO_FAILURE = 'customer/get customer info failed',
  GET_CUSTOMER_INFO_PENDING = 'customer/get customer info pending',
}

export interface GetCustomerInfo {
  type: PPCustomerAction.GET_CUSTOMER_INFO
}

export interface GetCustomerInfoSuccess {
  type: PPCustomerAction.GET_CUSTOMER_INFO_SUCCESS
  payload: Customer
}

export interface GetCustomerInfoFailure {
  type: PPCustomerAction.GET_CUSTOMER_INFO_FAILURE
}

export interface GetCustomerInfoPending {
  type: PPCustomerAction.GET_CUSTOMER_INFO_PENDING
}

type GetCustomerInfoFn = (customerId: number) => GetCustomerInfo
type GetCustomerInfoSuccessFn = (payload: Customer) => GetCustomerInfoSuccess
type GetCustomerInfoFailureFn = (e: Error) => GetCustomerInfoFailure
type GetCustomerInfoPendingFn = () => GetCustomerInfoPending

export const getCustomerInfo: GetCustomerInfoFn = fsaPayloadByKeys(PPCustomerAction.GET_CUSTOMER_INFO, ['customerId'])
export const getCustomerInfoSuccess: GetCustomerInfoSuccessFn = fsaPayload(PPCustomerAction.GET_CUSTOMER_INFO_SUCCESS)
export const getCustomerInfoFailure: GetCustomerInfoFailureFn = fsaError(PPCustomerAction.GET_CUSTOMER_INFO_FAILURE)
export const getCustomerInfoPending: GetCustomerInfoPendingFn = fsa(PPCustomerAction.GET_CUSTOMER_INFO_PENDING)
