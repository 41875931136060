export enum MODALS {
  NEW_CREDIT_CARD_MODAL = 'NEW_CREDIT_CARD_MODAL',
  NEW_PP_CREDIT_CARD_MODAL = 'NEW_PP_CREDIT_CARD_MODAL',
  PAYMENT_FAILED_MODAL = 'PAYMENT_FAILED_MODAL',
  PAGE_OVERLAY_THROBBER = 'PAGE_OVERLAY_THROBBER',
  PRODUCT_SEARCH_MODAL = 'PRODUCT_SEARCH_MODAL',
  PAYMENT_PORTAL_REASON_MODAL = 'PAYMENT_PORTAL_REASON_MODAL',
  ITEMS_NOT_FOUND_MODAL = 'ITEMS_NOT_FOUND_MODAL',
  PAYMENT_PORTAL_UNABLE_TO_RETRIEVE_DATA_MODAL = 'PAYMENT_PORTAL_UNABLE_TO_RETRIEVE_DATA_MODAL',
}
