import {includes, invoke} from 'lodash'

import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, GET_MANY_REFERENCE, UPDATE} from 'react-admin'
import mapResourceTypeToResolver from './mapResourceTypeToResolver'
import buildQuery from './buildQuery'
import apolloClient from './defaultApolloClient'

const QUERY_TYPES = [GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE]

export default async function dataProvider(type, resource, params) {
  const data = await invoke(
    apolloClient,
    includes(QUERY_TYPES, type) ? 'query' : 'mutate',
    buildQuery(type, resource, params),
  ).then(({data: response}) => response[mapResourceTypeToResolver(type, resource)])
  switch (type) {
    case GET_LIST:
    case GET_MANY:
    case GET_MANY_REFERENCE:
      return {data: data ? data.results : [], total: data ? data.count : 0}
    case GET_ONE:
    case CREATE:
    case UPDATE:
    case DELETE:
      return {data}
    default:
      throw new Error('Unsupported fetch type')
  }
}
