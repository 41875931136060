import {isSlugValid} from '../../utils'

export const createValidation = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = ['Name is required.']
  }
  if (!values.setType) {
    errors.setType = ['Please select a set type.']
  }
  if (!values.setSpecs) {
    errors.setSpecs = {displayCategoryId: 'Please select a category.'}
  }
  if (!values.urlSlug) {
    errors.urlSlug = ['URL Slug is required.']
  }
  if (!values.slug) {
    errors.slug = ['Slug is required.']
  }
  if (values.urlSlug !== values.slug) {
    errors.urlSlug = ['URL Slug must match slug.']
    errors.slug = ['URL Slug must match slug.']
  }
  if (values.urlSlug && !isSlugValid(values.urlSlug)) {
    errors.urlSlug = ['May only include letters, numbers and dashes.']
  }
  if (values.slug && !isSlugValid(values.slug)) {
    errors.slug = ['May only include letters, numbers and dashes.']
  }
  if (values.setType === 'single') {
    if (!values.style) {
      errors.style = ['Style code is required.']
    }
  }
  return errors
}
