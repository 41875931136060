import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {ArrayField, Datagrid, FormTab, ImageField, TextField} from 'react-admin'
import Typography from '@material-ui/core/Typography'

import {isKitOrGroup, isSingle} from '../../utils'

import DeleteProductInSetButton from '../../buttons/DeleteProductInSetButton'
import AddProductToSetButton from '../../buttons/AddProductToSetButton'

const styles = {
  notAvailable: {
    padding: '24px 0',
  },
}

const dataGridStyles = {
  table: {
    width: '100%',
  },
}

const NotAvailable = withStyles(styles)(({classes}) => (
  <Typography className={classes.notAvailable} component="p">
    Not available for this product set type.
  </Typography>
))

const StyledDataGrid = withStyles(dataGridStyles)(({classes, ...props}) => (
  <Datagrid classes={{table: classes.table}} {...props} />
))

const ProductsInSetTab = ({record}) =>
  record && (
    <FormTab label="Products In Set">
      {isKitOrGroup(record.setType) && (
        <ArrayField label="Products In Set" source="setChildren">
          <StyledDataGrid>
            <ImageField label="Image" source="childSet.setImages[0].url" />
            <TextField label="Product ID" source="childSet.id" />
            <TextField label="URL Slug" source="childSet.urlSlug" />
            <TextField label="Category Slug" source="childSet.displayCategorySlug" />
            <DeleteProductInSetButton />
          </StyledDataGrid>
        </ArrayField>
      )}
      {isSingle(record.setType) ? <NotAvailable /> : <AddProductToSetButton />}
    </FormTab>
  )

export default ProductsInSetTab
