import get from 'lodash/get'
import {GET_MANY_REFERENCE} from 'react-admin'

import mapResourceTypeToResolver from './mapResourceTypeToResolver'
import ProductSets from '../queries/ProductSets'
import {omitKeys} from '../utilities/formatting'

function sanitizeParams(params) {
  return omitKeys(params, ['__typename'])
}

function buildVariables(type, params) {
  switch (type) {
    case GET_MANY_REFERENCE:
      return {
        ...params,
        filter: {
          [get(params, 'target')]: get(params, 'id'),
        },
      }
    default:
      return params
  }
}

export default function buildQuery(type, resource, params) {
  const sanitizedParams = sanitizeParams(params)
  const gqlVariables = buildVariables(type, sanitizedParams)

  switch (resource) {
    case 'product-set':
      return ProductSets[mapResourceTypeToResolver(type, resource)](gqlVariables)
    default:
      throw new Error(`Unknown resource type: ${resource}`)
  }
}
