import {
  PRODUCT_OK,
  PRODUCTS_OK,
  PRODUCTS_NOT_OK,
  IMPACTED_PRODUCTS_OK,
  IMPACTED_PRODUCTS_NOT_OK,
  SINGLE_PRODUCT_OK,
  SINGLE_PRODUCT_NOT_OK,
} from '../constants/ActionTypes'
import {getAPI, getHeaders} from '../config'
import logErrorException from '../../../utils/logErrorException'

export function fetchImpactedProducts(productId) {
  return (dispatch, getState) => {
    const token = getState().session.get('token')

    return fetch(`${getAPI()}/products/${productId}/related-product-sets`, getHeaders(token))
      .then((response) => {
        if (response.status >= 400) {
          dispatch({
            type: IMPACTED_PRODUCTS_NOT_OK,
          })

          logErrorException('fetchImpactedProducts', response.status, undefined)
          return
        }
        response.json().then((responseJSON) => {
          dispatch({
            type: IMPACTED_PRODUCTS_OK,
            payload: responseJSON,
          })
        })
      })
      .catch((error) => {
        dispatch({
          type: IMPACTED_PRODUCTS_NOT_OK,
          payload: error.toLocaleString(),
        })

        logErrorException('fetchImpactedProducts', null, error)
      })
  }
}

export function getProducts() {
  return (dispatch, getState) => {
    const token = getState().session.get('token')

    return fetch(`${getAPI()}/products`, getHeaders(token))
      .then((response) => {
        if (response.status >= 400) {
          dispatch({
            type: PRODUCTS_NOT_OK,
          })

          logErrorException('getProducts', response.status, undefined)
          return
        }
        response.json().then((responseJSON) => {
          dispatch({
            type: PRODUCTS_OK,
            payload: responseJSON,
          })
        })
      })
      .catch((error) => {
        dispatch({
          type: PRODUCTS_NOT_OK,
          payload: error.toLocaleString(),
        })

        logErrorException('getProducts', null, error)
      })
  }
}

export function fetchProduct(productId) {
  return (dispatch, getState) => {
    const token = getState().session.get('token')

    return fetch(`${getAPI()}/products/${productId}`, getHeaders(token))
      .then((response) => {
        if (response.status >= 400) {
          dispatch({
            type: SINGLE_PRODUCT_NOT_OK,
          })

          logErrorException('fetchProduct', response.status, undefined)
          return
        }
        response.json().then((responseJSON) => {
          dispatch({
            type: SINGLE_PRODUCT_OK,
            payload: responseJSON,
          })
        })
      })
      .catch((error) => {
        dispatch({
          type: SINGLE_PRODUCT_NOT_OK,
          payload: error.toLocaleString(),
        })

        logErrorException('fetchProduct', null, error)
      })
  }
}

export function displayProduct(slug) {
  return {
    type: PRODUCT_OK,
    slug,
  }
}
