import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Col, Container, Row} from 'reactstrap'

const defaultProps = {
  className: undefined,
}

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const ToolTimeContainer = ({children, ...props}) => {
  const {className, ...attributes} = props

  const classes = classNames(className, ['tooltime-container'])

  return (
    <Container {...attributes} className={classes}>
      <Row>
        <Col>{children}</Col>
      </Row>
    </Container>
  )
}

ToolTimeContainer.defaultProps = defaultProps
ToolTimeContainer.propTypes = propTypes

export default ToolTimeContainer
