import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import camelCase from 'lodash/camelCase'
import capitalize from 'lodash/capitalize'
import includes from 'lodash/includes'
import isPlainObject from 'lodash/isPlainObject'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import pluralize from 'pluralize'
import snakeCase from 'lodash/snakeCase'

import {TransactionReason, TransactionType} from '../types'

/*
 * Used to convert a string or number into a dollar format with decimal places
 * Remove dollar signs, and commas for string values > 1000
 */
export const stripDollarSigns = (amount) => {
  if (!isString(amount)) return amount
  return amount.replace('$', '').replace(',', '')
}

export const getDecimalDollarFormat = (amount, places = 2) =>
  ((value) => {
    const shouldFormat = (isNumber(value) || isString(value)) && isFinite(+value)
    // format with dollar sign, to given places, and add commas for values > 1000
    const num = shouldFormat
      ? `$${(+value)
          .toFixed(places)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      : value
    return num
  })(stripDollarSigns(amount))

export function renameKeys(obj, renameMap) {
  return reduce(
    obj,
    (acc, value, key) => ({
      ...acc,
      [renameMap[key]]: isPlainObject(value) ? renameKeys(value, renameMap) : value,
    }),
    {},
  )
}

export function omitKeys(obj, omitKeyArr) {
  return reduce(
    obj,
    (acc, value, key) => {
      if (includes(omitKeyArr, key)) {
        return {...acc}
      }

      if (isPlainObject(value)) {
        return {
          ...acc,
          [key]: omitKeys(value, omitKeyArr),
        }
      }

      return {
        ...acc,
        [key]: value,
      }
    },
    {},
  )
}

// Use '-' as a delimiter when passing multiple words
export const pluralizeResource = (res) => {
  const arr = res.split('-')
  const capWords = map(arr, (word, i) => {
    if (i + 1 === arr.length) {
      return pluralize(capitalize(word))
    }
    return capitalize(word)
  })
  return capWords.join('')
}

export const normalizeResource = (res) => {
  const arr = res.split('-')
  return map(arr, capitalize).join('')
}

/**
 * Recursively walks an object and applies the formatting function to each object key.
 *
 * @param {Function} formatter Function to transform an object's key
 * @param {Object} obj Object to be formatted
 */
const formatObjectKeys = (formatter, obj) => {
  if (Array.isArray(obj)) {
    return obj.map((each) => formatObjectKeys(formatter, each))
  }

  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        [formatter(key)]: formatObjectKeys(formatter, obj[key]),
      }),
      {},
    )
  }
  return obj
}

/**
 * Given an input object, this function recursively renames all object keys in camelCase format.
 *
 * @param {Object} obj Input object to transform
 * @return {Object} Returns object with all keys in camelCase
 */
export const camelCaseObjectKeys = (obj) => formatObjectKeys(camelCase, obj)

/**
 * Given an input object, this function recursively renames all object keys in snake_case format.
 *
 * @param {Object} obj Input object to transform
 * @return {Object} Returns object with all keys in snake_case
 */
export const snakeCaseObjectKeys = (obj) => formatObjectKeys(snakeCase, obj)

export const addIntegerCommas = (intNum) => `${intNum}`.replace(/(\d)(?=(\d{3})+$)/g, '$1,')

export const normalizePhoneNumber = (phoneNumber) => {
  const sanitizedNumber = `${phoneNumber}`.replace(/\D+/g, '')

  if (sanitizedNumber.length === 10) {
    return sanitizedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  return phoneNumber
}

/**
 * Used by `transformOrdersItems` to configure the key to map an item's price to.
 */
const priceKeysByReason = {
  [TransactionReason.AssetRecovery]: 'assetRecoveryPrice',
  [TransactionReason.RentToKeep]: 'rentToKeepPrice',
}

/**
 * Transforms the orders metadata from `/v1/events/${eventId}/orders?reason=${reason}`
 * so that `price` refers the correct price value, using the right key dependent on `reason`.
 * Also sets `skuCode` so that downstream consumers of this data don't have to access nested property.
 * @param {string} reason corresponding to the metadata
 * // TODO: Refactor the return type once this file is in ts
 * @returns {function(): void}} maps orders to data transformed with price information from the API response
 */
export const transformOrdersItems = (reason) => (apiResponse) => {
  const orders = apiResponse.data.orders

  const transformedOrders = orders.map((order) => {
    const priceKey = priceKeysByReason[reason]
    const transformedShipmentItems = order.shipmentItems.map((item) => ({
      ...item,
      price: item[priceKey],
      skuCode: item.sku.code,
    }))
    return {...order, shipmentItems: transformedShipmentItems}
  })

  const transformedData = {...apiResponse.data, orders: transformedOrders}

  return {...apiResponse, data: transformedData}
}

/**
 * Checks if a string is defined and not empty.
 *
 * @param {string} value to check
 * @returns boolean
 */
export const isNonEmptyString = (value) => {
  if (value && value.trim() !== '') {
    return true
  }
  return false
}

/**
 * Formats the customer name to either be the full name of the customer or email address if full
 * name has undefined or empty strings.
 *
 * @param {string|null} firstName of customer
 * @param {string|null} lastName of customer
 * @param {string} email of customer
 * @returns string
 */
export const formatCustomerName = (firstName, lastName, email) => {
  if (isNonEmptyString(firstName) && isNonEmptyString(lastName)) {
    return `${firstName} ${lastName}`
  }
  return email
}

/**
 * Snake cases object keys and pulls the necessary data
 * according to the transaction reason for the API request
 *
 * @param {Object} transactionItem that is to be added or removed
 * @param {string} transactionType of the current operation ("charges" or "refunds")
 * @returns Object
 */
export const formatTransactionPayloadByReason = (transactionItem, transactionType) => {
  const {amount, description, orderId, productSetId, reason, shipmentItemId, transactionItemId} = transactionItem

  switch (reason) {
    case TransactionReason.AccountBalanceCredit:
      if (transactionType === TransactionType.Charges) {
        return snakeCaseObjectKeys({
          amount,
          orderId,
          reason,
        })
      }

      return snakeCaseObjectKeys({description, transactionItemId: parseInt(transactionItemId)})

    case TransactionReason.AccountBalanceDebit:
      return snakeCaseObjectKeys({description, transactionItemId: orderId})

    case TransactionReason.AssetRecovery:
      if (transactionType === TransactionType.Charges) {
        return snakeCaseObjectKeys({
          reason,
          shipmentItemId,
        })
      }

      return snakeCaseObjectKeys({description, transactionItemId: parseInt(shipmentItemId)})

    case TransactionReason.Fee:
      return snakeCaseObjectKeys({reason})

    case TransactionReason.Miscellaneous:
      return snakeCaseObjectKeys({
        amount,
        description,
        orderId,
        reason: transactionType === TransactionType.Charges ? reason : transactionItem.refundReason,
      })

    case TransactionReason.Product:
      if (transactionType === TransactionType.Charges) {
        return snakeCaseObjectKeys({
          productSetId,
          reason,
          orderId,
        })
      }

      return snakeCaseObjectKeys({description, transactionItemId: parseInt(productSetId)})

    case TransactionReason.RentToKeep:
      if (transactionType === TransactionType.Charges) {
        return snakeCaseObjectKeys({
          reason,
          shipmentItemId,
        })
      }
      return snakeCaseObjectKeys({description, transactionItemId: parseInt(shipmentItemId)})

    case TransactionReason.Shipping:
      const {partialShippingAmount, shippingType} = transactionItem
      return snakeCaseObjectKeys({
        amount,
        orderId,
        reason,
        shippingType,
        partialShippingAmount,
      })

    default:
      return {}
  }
}

/**
 * Transforms the user data from `/users/${userId}/info`
 * @param {number} userId
 * // TODO: Refactor the return type once this file is in ts
 * @returns {function(): void} maps customerInfo keys to match expected keys in customer state
 */
export const transformUserInfo = (apiResponse) => {
  const {email, firstName, id, last4, lastName} = apiResponse.data
  const transformedData = {
    email,
    firstName,
    id,
    last4CreditCardDigits: last4,
    lastName,
  }

  return {...apiResponse, data: transformedData}
}

/**
 * Adds parenthesis to a refund transactionType value
 * @param {String} transactionType that is a charge or refund
 * @param {Number} value of the current transaction modal
 * @returns {String}
 */
export const formatDollarValueByTransactionType = (transactionType, value) =>
  transactionType === TransactionType.Charges ? getDecimalDollarFormat(value) : `(${getDecimalDollarFormat(value)})`
