import get from 'lodash/get'
import {createSelector, Selector} from 'reselect'

import {ApiRequestStates} from '../../../api/const'
import {Address, Event, Order, OrderType} from '../../../types'
import {PaymentPortalReducer} from '../../reducers/PaymentPortal'
import {RootState} from '../../../store'

export const order: Selector<PaymentPortalReducer, Order> = (state) => get(state, 'paymentPortal.order')
export const orderId: Selector<RootState, number | null> = (state) => get(order(state), 'id')
export const orderType: Selector<RootState, OrderType | null> = (state) => get(order(state), 'orderType')
export const event: Selector<PaymentPortalReducer, Event> = (state) => get(order(state), 'event')
export const eventName: Selector<PaymentPortalReducer, string> = (state) => get(event(state), 'name')
export const eventId: Selector<PaymentPortalReducer, number | null> = (state) => get(event(state), 'id')
export const shipping: Selector<PaymentPortalReducer, Address> = (state) => get(order(state), 'shipping')
export const address1: Selector<PaymentPortalReducer, string> = (state) => get(shipping(state), 'address1')
export const address2: Selector<PaymentPortalReducer, string | null> = (state) => get(shipping(state), 'address2')
export const city: Selector<PaymentPortalReducer, string> = (state) => get(shipping(state), 'city')
export const zip: Selector<PaymentPortalReducer, string> = (state) => get(shipping(state), 'zip')
export const state: Selector<PaymentPortalReducer, string> = (state) => get(shipping(state), 'state')

export const getCurrentOrderDetailsStatus: Selector<RootState, ApiRequestStates> = (state) =>
  get(order(state), 'getCurrentOrderDetailsStatus')

export const isGetCurrentOrderDetailsFailed: Selector<RootState, boolean> = createSelector(
  getCurrentOrderDetailsStatus,
  (status) => status === ApiRequestStates.ERROR,
)

export const isGetCurrentOrderDetailsPending: Selector<RootState, boolean> = createSelector(
  getCurrentOrderDetailsStatus,
  (status) => status === ApiRequestStates.PENDING,
)

export const isGetCurrentOrderDetailsSuccessful: Selector<RootState, boolean> = createSelector(
  getCurrentOrderDetailsStatus,
  (status) => status === ApiRequestStates.SUCCESS,
)
