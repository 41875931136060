import {
  SOLD_OUT_DATES_OK,
  SOLD_OUT_DATES_NOT_OK,
  SEARCH_SOLD_OUT,
  PRODUCT_SOLD_OUT_PERIODS_OK,
  PRODUCT_SOLD_OUT_PERIODS_NOT_OK,
  ADD_SOLD_OUT_OK,
  ADD_SOLD_OUT_NOT_OK,
  DELETE_SOLD_OUT_OK,
  DELETE_SOLD_OUT_NOT_OK,
  RESET_SOLD_OUT_STATE,
} from '../constants/ActionTypes'
import {getAPI, getHeaders} from '../config'
import logErrorException from '../../../utils/logErrorException'

export function getSoldOutDates() {
  return (dispatch, getState) => {
    const token = getState().session.get('token')

    return fetch(`${getAPI()}/products`, getHeaders(token))
      .then((response) => {
        if (response.status >= 400) {
          dispatch({
            type: SOLD_OUT_DATES_NOT_OK,
          })

          logErrorException('getSoldOutDates', response.status, undefined)
          return
        }
        response.json().then((responseJSON) => {
          dispatch({
            type: SOLD_OUT_DATES_OK,
            payload: responseJSON,
          })
        })
      })
      .catch((error) => {
        dispatch({
          type: SOLD_OUT_DATES_NOT_OK,
          payload: error.toLocaleString(),
        })

        logErrorException('getSoldOutDates', null, error)
      })
  }
}

export function searchSoldOut(value) {
  return {
    type: SEARCH_SOLD_OUT,
    value,
  }
}

export function fetchProductSoldOutPeriods(productId) {
  return (dispatch, getState) => {
    const token = getState().session.get('token')

    return fetch(`${getAPI()}/products/${productId}/soldout-periods`, getHeaders(token))
      .then((response) => {
        if (response.status >= 400) {
          dispatch({
            type: PRODUCT_SOLD_OUT_PERIODS_NOT_OK,
          })

          logErrorException('fetchProductSoldOutPeriods', response.status, undefined)
          return
        }
        response.json().then((responseJSON) => {
          dispatch({
            type: PRODUCT_SOLD_OUT_PERIODS_OK,
            payload: responseJSON.results,
          })
        })
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT_SOLD_OUT_PERIODS_NOT_OK,
          payload: error.toLocaleString(),
        })

        logErrorException('fetchProductSoldOutPeriods', null, error)
      })
  }
}

export function postSoldOutPeriod(payload, productId) {
  return (dispatch, getState) => {
    const token = getState().session.get('token')

    return fetch(`${getAPI()}/products/${productId}/soldout-periods`, {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch({
            type: ADD_SOLD_OUT_NOT_OK,
          })

          logErrorException('postSoldOutPeriod', response.status, undefined)
          return
        }
        response.json().then((responseJSON) => {
          dispatch({
            type: ADD_SOLD_OUT_OK,
            payload: responseJSON,
          })
        })
      })
      .catch((error) => {
        dispatch({
          type: ADD_SOLD_OUT_NOT_OK,
          payload: error.toLocaleString(),
        })

        logErrorException('postSoldOutPeriod', null, error)
      })
  }
}

export function deleteSoldOutPeriod(productId, soldOutId) {
  return (dispatch, getState) => {
    const token = getState().session.get('token')

    return fetch(`${getAPI()}/products/${productId}/soldout-periods/${soldOutId}`, {
      method: 'DELETE',
      headers: getHeaders(token),
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch({
            type: DELETE_SOLD_OUT_NOT_OK,
          })

          logErrorException('deleteSoldOutPeriod', response.status, undefined)
          return
        }
        // DELETE 204 returns a null response body
        // 204 (no content) response back from the server and handle it without the use of response.json() call.
        if (response.status === 204) {
          dispatch({
            type: DELETE_SOLD_OUT_OK,
            payload: soldOutId,
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SOLD_OUT_NOT_OK,
          payload: error.toLocaleString(),
        })

        logErrorException('deleteSoldOutPeriod', null, error)
      })
  }
}

export function resetSoldOutState() {
  return {
    type: RESET_SOLD_OUT_STATE,
  }
}
