import {pluralizeResource, normalizeResource} from '../utilities/formatting'

import {CREATE, DELETE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE} from 'react-admin'

export default function mapResourceTypeToResolver(type, resource) {
  const normalizedResourceName = normalizeResource(resource)

  switch (type) {
    case GET_LIST:
    case GET_MANY:
    case GET_MANY_REFERENCE:
      return `all${pluralizeResource(resource)}`
    case GET_ONE:
      return `one${normalizedResourceName}`
    case CREATE:
      return `create${normalizedResourceName}`
    case DELETE:
      return `delete${normalizedResourceName}`
    case UPDATE:
      return `update${normalizedResourceName}`
    default:
      throw new Error(`Unknown resource type ${resource}`)
  }
}
