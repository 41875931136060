import {Order, WorldpayErrorResponse} from '../../../types'
import {defaultProcessPaymentError, processPaymentError} from './enums'
import {SERVER_ERROR} from '../../../api/helpers'
import ProcessOrderPaymentError from '../../../exceptions'

// TODO Validate the data from the backend and remove the any type
export const formatOrderPayload = (order: any): Order => {
  const payload = {
    id: order.id,
    event: order.event,
    orderType: order.transactionType.toUpperCase(),
    shipping: {
      address1: order.shippingAddress1,
      address2: order.shippingAddress2,
      city: order.shippingCity,
      state: order.shippingState,
      zip: order.shippingZip,
    },
  }

  return payload
}

/**
 * Creates and sets the message, status and internalError states in a ProcessOrderPaymentError instance.
 */
export const setProcessOrderPaymentError = (internalErrorCode: WorldpayErrorResponse, status: number) => {
  const errorMessage =
    status === SERVER_ERROR ? defaultProcessPaymentError : processPaymentError[status] || defaultProcessPaymentError
  throw new ProcessOrderPaymentError(errorMessage, status, internalErrorCode)
}
