import axios from 'axios'

import {camelCaseObjectKeys} from '../utilities/formatting'
import {constructOptions} from './utils'

/**
 *
 * @param {Object}            payload                   Pricing totals API request payload object
 * @param {String}            payload.transaction_type  Type of order transaction ("rent" or "buy")
 * @param {String[]}          payload.product_set_ids   Child product set IDs for items to order
 * @param {String}            payload.address_1         Customer's primary street address
 * @param {String|undefined}  payload.address_2         Customer's secondary street address
 * @param {String}            payload.city              Customer's city address
 * @param {String}            payload.state             Customer's state address
 * @param {string}            payload.zip_code          Customer's zip code address
 */
export const getProductsPricingTotals = (payload) =>
  axios
    .post(`${window.__RUNTIME_CONFIG__.REACT_APP_MONOLITH_URL}/v1/pricing/totals`, payload, constructOptions())
    .then(camelCaseObjectKeys)
