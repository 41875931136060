import {fsa, fsaError, fsaPayload, fsaPayloadByKeys} from './helpers'

export const SEND_CONFIRMATION_EMAIL = 'SEND_CONFIRMATION_EMAIL'

export const GET_ORDER_INFO_FAILURE = 'orders/get order info failed'
export const getOrderInfoFailure = fsaError(GET_ORDER_INFO_FAILURE)

export const GET_ORDER_INFO = 'orders/get order info'
export const getOrderInfo = fsaPayloadByKeys(GET_ORDER_INFO, ['orderId'])

export const GET_ORDER_INFO_PENDING = 'orders/get order info pending'
export const getOrderInfoPending = fsa(GET_ORDER_INFO_PENDING)

/**
 * @param {Object} payload An object of the order info
 */
export const GET_ORDER_INFO_SUCCESS = 'orders/get order info succeeded'
export const getOrderInfoSuccess = fsaPayload(GET_ORDER_INFO_SUCCESS)

export const GET_PRODUCTS_BY_ORDER_ID_FAILURE = 'orders/get products by orderId failed'
export const getProductsByOrderIdFailure = fsaError(GET_PRODUCTS_BY_ORDER_ID_FAILURE)

export const GET_PRODUCTS_BY_ORDER_ID = 'orders/get products by orderId'
export const getProductsByOrderId = fsaPayloadByKeys(GET_PRODUCTS_BY_ORDER_ID, ['orderId'])

export const GET_PRODUCTS_BY_ORDER_ID_PENDING = 'orders/get products by orderId pending'
export const getProductsByOrderIdPending = fsa(GET_PRODUCTS_BY_ORDER_ID_PENDING)

/**
 * @param {Array} payload An array of product objects in an order
 */
export const GET_PRODUCTS_BY_ORDER_ID_SUCCESS = 'orders/get products by orderId succeeded'
export const getProductsByOrderIdSuccess = fsaPayload(GET_PRODUCTS_BY_ORDER_ID_SUCCESS)

export const sendConfirmationEmail = (orderId) => ({
  type: SEND_CONFIRMATION_EMAIL,
  orderId,
})
