import logErrorException from '../utils/logErrorException'
import {DELETE_AUTH_JWT_TOKEN, FETCH_AUTH_INF_TOKEN, FETCH_AUTH_JWT_TOKEN, RECEIVE_AUTH_JWT_TOKEN} from '../constants/ActionTypes'

const authServiceEnv = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_URL
const TBT_ADMIN_KEY = 'the-black-tux-admin'
const tbtAuthRedirectKey = 'tbtAuthRedirect'

export function fetchAuthInfToken() {
  const tbtAuthRedirectValue = window.location.href
  const toolTimeHost = window.location.host
  window.localStorage.setItem(tbtAuthRedirectKey, tbtAuthRedirectValue)

  return (dispatch) => {
    dispatch({
      type: FETCH_AUTH_INF_TOKEN,
    })
    window.location.replace(`${authServiceEnv}/login?origin=${toolTimeHost}`)
  }
}

export function fetchAuthJwtToken(inf) {
  return (dispatch) => {
    dispatch({
      type: FETCH_AUTH_JWT_TOKEN,
    })

    fetch(`${authServiceEnv}/inf/${inf}`)
      .then((response) => {
        if (response.status >= 400) {
          logErrorException('fetchAuthJwtToken', response.status, undefined)
          return
        }
        response.json().then((data) => {
          dispatch({
            type: RECEIVE_AUTH_JWT_TOKEN,
            data,
          })
          window.location.replace(window.localStorage.getItem(tbtAuthRedirectKey))
          window.localStorage.removeItem(tbtAuthRedirectKey)
        })
      })
      .catch((error) => {
        logErrorException('fetchAuthJwtToken', null, error)
      })
  }
}

export function deleteAuthJwtToken() {
  return (dispatch) => {
    window.localStorage.removeItem(TBT_ADMIN_KEY)

    dispatch({
      type: DELETE_AUTH_JWT_TOKEN
    })
  }
}
