import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import * as store from '../constants/store'
import * as en from '../constants/enums'
import * as data from '../constants/FormFieldData'
import {calculationWarning, determineValueFromMap, autoCalculateMessage} from '../constants/lib'
import CategoryContainer from './CategoryContainer'
import FormRow from './ReusableComponents/FormRow'
import {DropRow} from './Drop'

function JacketVestSizes({sizeValidators, computeField}) {
  const calculateVestSize = determineValueFromMap(sizeValidators.chestToVestMap)
  const calculateVestLength = determineValueFromMap(sizeValidators.jacketLengthToVestLengthMap)
  const vestWarning = calculationWarning(calculateVestSize, en.assignedFormChest)

  // Jacket assignedSizes onChange => calc vest size
  data.jacketSizeRow.assignedSizes.onChange = computeField(en.assignedFormVest, calculateVestSize)

  // JacketLength assignedSizes onChange => calc vest length
  data.jacketLengthRow.assignedSizes.onChange = computeField(en.assignedFormVestLength, calculateVestLength)

  // Vest size warning if not computed vest
  data.vestSizeRow.assignedSizes.warning = (curr, allVals) => autoCalculateMessage(curr) || vestWarning(curr, allVals)

  // Vest length warning if not computed vest length
  data.vestLengthRow.assignedSizes.warning = calculationWarning(calculateVestLength, en.assignedFormJacketLength)

  return (
    <CategoryContainer header="Jacket & Vest Sizes">
      <FormRow {...data.jacketSizeRow} />
      <FormRow {...data.jacketLengthRow} />
      <FormRow {...data.jacketSleeveAdjustRow} />
      <FormRow {...data.vestSizeRow} />
      <FormRow {...data.vestLengthRow} />
      <DropRow />
    </CategoryContainer>
  )
}

function mapStateToProps(state) {
  return {
    sizeValidators: store.assignedSizeValidators(state),
  }
}

JacketVestSizes.propTypes = {
  sizeValidators: PropTypes.object.isRequired,
  computeField: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(JacketVestSizes)
