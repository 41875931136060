import get from 'lodash/get'
import {createSelector} from 'reselect'

import {ApiRequestStates} from '../../../api/const'

export const order = (state) => get(state, 'mti.order')
export const orderId = (state) => get(order(state), 'id')
export const arrivalDate = (state) => get(order(state), 'arrivalDate')
export const transactionType = (state) => get(order(state), 'transactionType')
export const products = (state) => get(order(state), 'products')
export const customer = (state) => get(order(state), 'customer')
export const customerId = (state) => get(customer(state), 'id')
export const customerEmail = (state) => get(customer(state), 'email')
export const last4CreditCardDigits = (state) => get(customer(state), 'last4CreditCardDigits')
export const event = (state) => get(order(state), 'event')
export const eventDate = (state) => get(event(state), 'date')
export const shipping = (state) => get(order(state), 'shipping')
export const shippingAddress1 = (state) => get(shipping(state), 'address1')
export const shippingAddress2 = (state) => get(shipping(state), 'address2')
export const shippingCity = (state) => get(shipping(state), 'city')
export const shippingState = (state) => get(shipping(state), 'state')
export const shippingZip = (state) => get(shipping(state), 'zip')
export const getCurrentOrderDetailsStatus = (state) => get(order(state), 'getCurrentOrderDetailsStatus')

export const isGetCurrentOrderDetailsFailed = createSelector(
  getCurrentOrderDetailsStatus,
  (status) => status === ApiRequestStates.ERROR,
)

export const isGetCurrentOrderDetailsPending = createSelector(
  getCurrentOrderDetailsStatus,
  (status) => status === ApiRequestStates.PENDING,
)

export const isGetCurrentOrderDetailsSuccessful = createSelector(
  getCurrentOrderDetailsStatus,
  (status) => status === ApiRequestStates.SUCCESS,
)
