import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Link} from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {MuiThemeProvider, makeStyles} from '@material-ui/core/styles'
import ExitToApp from '@material-ui/icons/ExitToApp'
import * as sessionSelectors from '../redux/selectors/session'

import theme from '../theme'

interface Props {
  children: React.ReactNode
  isLoggedIn: boolean
  title: string | React.ReactNode
}

const useStyles = makeStyles({
  main: {
    backgroundColor: '#f3f3f3',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    height: '100%',
    padding: '0 72px',
  },
  typography: {
    fontWeight: 500,
  },
  title: {
    flexGrow: 1,
  },
  icon: {
    color: 'white',
  },
  link: {
    color: 'white',
    '&:hover': {
      color: 'teal',
    },
  },
})

const Page: React.FC<Props> = ({children, isLoggedIn, title}) => {
  const styles = useStyles()

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="h1" className={styles.title}>
            {title}
          </Typography>
          {isLoggedIn && (
            <Link to="/logout">
              <ExitToApp className={styles.icon} />
            </Link>
          )}
        </Toolbar>
      </AppBar>
      <main className={styles.main}>{children}</main>
    </MuiThemeProvider>
  )
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: sessionSelectors.isLoggedIn,
})

export default connect(mapStateToProps)(Page)
