import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Icon} from 'react-fa'

import * as store from '../constants/store'

function SizeCheckedBadge({sizeChecked}) {
  return sizeChecked ? (
    <span className="sp-size-checked-badge text-success">
      <Icon name="fa-check" />
      Size Checked
    </span>
  ) : null
}

SizeCheckedBadge.propTypes = {
  sizeChecked: PropTypes.bool,
}

SizeCheckedBadge.defaultProps = {
  sizeChecked: false,
}

function mapStateToProps(state) {
  return {
    sizeChecked: store.assignedSizes(state) && store.assignedSizes(state).sizeChecked,
  }
}

export default connect(mapStateToProps)(SizeCheckedBadge)
