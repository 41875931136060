import React from 'react'
import {Route} from 'react-router-dom'

/**
 * Lazy-loaded route declarations
 */
const AddItems = React.lazy(() =>
  import('./components/ManualTransactions/AddItems' /* webpackChunkName: "add-items" */),
)
const Credits = React.lazy(() =>
  import('./components/AccountBalance/Credits/Credits' /* webpackChunkName: "account-balance-credits" */),
)
const CreditsSuccess = React.lazy(() =>
  import(
    './components/AccountBalance/Credits/CreditsSuccess' /* webpackChunkName: "account-balance-credits-success" */
  ),
)
const ESSearchPage = React.lazy(() => import('./components/ESSearch/pages/Main' /* webpackChunkName: "search-main" */))
const ESSeeAll = React.lazy(() =>
  import('./components/ESSearch/pages/ESSeeAll' /* webpackChunkName: "search-see-all" */),
)
const InventoryAvailability = React.lazy(() =>
  import('./components/InventoryAvailability' /* webpackChunkName: "inventory-availability" */),
)
const LogOutPage = React.lazy(() => import('./pages/AuthPages/LogOut') /* webpackChunkName: "log-out-page" */)
const UserBalance = React.lazy(() =>
  import('./components/AccountBalance/UserBalance/UserBalance' /* webpackChunkName: "account-balance-user-balance" */),
)
const UserBalanceSuccess = React.lazy(() =>
  import(
    './components/AccountBalance/UserBalance/Success' /* webpackChunkName: "account-balance-user-balance-success" */
  ),
)
const TransactionsCharge = React.lazy(() =>
  import('./pages/PaymentPortal/TransactionsCharge' /* webpackChunkName: "transactions-charge" */),
)
const TransactionsSuccess = React.lazy(() =>
  import('./pages/PaymentPortal/TransactionsSuccess' /* webpackChunkName: "transactions-success" */),
)
const TransactionsRefund = React.lazy(() =>
  import('./pages/PaymentPortal/TransactionsRefund' /* webpackChunkName: "transactions-refund" */),
)
const Products = React.lazy(() =>
  import('./legacy/sub-apps/product/containers/Products' /* webpackChunkName: "sold-out-products" */),
)
const SizesEntry = React.lazy(() =>
  import('./legacy/sub-apps/sizes/components/SizesEntry' /* webpackChunkName: "user-fitprofile" */),
)

const legacyRoutes = [
  <Route path="/product-management" component={Products} noLayout />,
  <Route exact path="/customer-care/users/:userId/sizes" component={SizesEntry} noLayout />,
]

const routes = [
  ...legacyRoutes,
  <Route exact path="/logout" component={LogOutPage} noLayout />,
  <Route exact path="/search" component={ESSearchPage} noLayout />,
  <Route exact path="/search/:resourceType" component={ESSeeAll} noLayout />,
  <Route path="/inventory-availability" component={InventoryAvailability} />,
  <Route path="/customer-care/orders/:orderId/account-balance/success" component={CreditsSuccess} noLayout />,
  <Route path="/customer-care/orders/:orderId/account-balance" component={Credits} noLayout />,
  <Route path="/customer-care/orders/:orderId/charges/success" component={TransactionsSuccess} noLayout />,
  <Route path="/customer-care/orders/:orderId/charges" component={TransactionsCharge} noLayout />,
  <Route path="/customer-care/orders/:orderId/refunds/success" component={TransactionsSuccess} noLayout />,
  <Route path="/customer-care/orders/:orderId/refunds" component={TransactionsRefund} noLayout />,
  <Route path="/customer-care/users/:userId/account-balance/success" component={UserBalanceSuccess} noLayout />,
  <Route path="/customer-care/users/:userId/account-balance" component={UserBalance} noLayout />,
  <Route path="/customer-care/add-items/:orderId" component={AddItems} noLayout />,
]

export default routes
