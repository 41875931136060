import {fsa, fsaError, fsaPayload, fsaPayloadByKeys} from '../helpers'

export const GET_CURRENT_ORDER_DETAILS_FAILURE = 'mti/get current order details failed'
export const getCurrentOrderDetailsFailure = fsaError(GET_CURRENT_ORDER_DETAILS_FAILURE)

export const GET_CURRENT_ORDER_DETAILS = 'mti/get current order details'
export const getCurrentOrderDetails = fsaPayloadByKeys(GET_CURRENT_ORDER_DETAILS, ['orderId'])

export const GET_CURRENT_ORDER_DETAILS_PENDING = 'mti/get current order details pending'
export const getCurrentOrderDetailsPending = fsa(GET_CURRENT_ORDER_DETAILS_PENDING)

/**
 * @param {Object} payload
 * @param {Object} payload.order Order info object
 * @param {Array} payload.products An array of products in the order
 * @param {Object} payload.customer Customer info object
 */
export const GET_CURRENT_ORDER_DETAILS_SUCCESS = 'mti/get current order details succeeded'
export const getCurrentOrderDetailsSuccess = fsaPayload(GET_CURRENT_ORDER_DETAILS_SUCCESS)
