import gql from 'graphql-tag'
import omit from 'lodash/omit'

const allProductSets = ({pagination: {page}}) => ({
  query: gql`
    query GetAllProductSets($filter: ListFilterInput) {
      allProductSets(filter: $filter) {
        count
        results {
          buyPrice
          id
          isPurchasable
          name
          price
          productSetId
          rentPrice
          setSpecs {
            displayCategoryName
          }
          setType
          urlSlug
        }
      }
    }
  `,
  variables: {
    filter: {page: page.toString(), page_size: '20'},
  },
})

const oneProductSet = ({id}) => ({
  query: gql`
    query GetSingleProductSet($id: String) {
      oneProductSet(productSetId: $id) {
        description
        id
        isPublished
        isPurchasable
        name
        price
        rentPrice
        buyPrice
        productSetId
        setImages {
          id
          name
          position
          url
        }
        setChildren {
          id
          childSet {
            id
            displayCategorySlug
            name
            price
            rentPrice
            buyPrice
            setImages {
              id
              name
              url
              position
            }
            slug
            urlSlug
          }
        }
        setSpecs {
          additionalBullet1
          additionalBullet2
          additionalBullet3
          displayCategorySlug
          displayCategoryId
          displayCategoryName
          material
          setSpecsId
          lapel
          buttons
          seoTag
          sizing
          isHto
          isPartyAvailable
          hasUgc
          hasReviews
        }
        setType
        slug
        style
        urlSlug
      }
    }
  `,
  variables: {
    id,
  },
})

const updateProductSet = ({data}) => ({
  mutation: gql`
    mutation UpdateProductSet($input: ProductSetInput, $authToken: String) {
      updateProductSet(input: $input, authToken: $authToken) {
        description
        id
        isPublished
        isPurchasable
        name
        price
        rentPrice
        buyPrice
        productSetId
        style
        setSpecs {
          setSpecsId
          displayCategorySlug
          displayCategoryId
          displayCategoryName
          material
          lapel
          buttons
          additionalBullet1
          additionalBullet2
          additionalBullet3
          seoTag
          sizing
          isHto
          isPartyAvailable
          hasUgc
          hasReviews
        }
        setType
        slug
        urlSlug
      }
    }
  `,
  variables: {
    // Required since Since set_items & set_images are mutated with their own APIs and aren't part of the CRUD payload
    // Once we have these as their own resources we can remove the omission
    input: omit(data, ['setChildren', 'setImages']),
    authToken: JSON.parse(localStorage.getItem('the-black-tux-admin')).token,
  },
})

const createProductSet = ({data}) => ({
  mutation: gql`
    mutation CreateProductSet($input: ProductSetInput, $authToken: String) {
      createProductSet(input: $input, authToken: $authToken) {
        id
        name
        setType
        price
        rentPrice
        buyPrice
        description
        slug
        urlSlug
        setSpecs {
          displayCategoryId
          material
          lapel
          buttons
          additionalBullet1
          additionalBullet2
          additionalBullet3
          seoTag
          sizing
          isHto
          isPartyAvailable
          hasUgc
          hasReviews
        }
        style
      }
    }
  `,
  variables: {
    input: data,
    authToken: JSON.parse(localStorage.getItem('the-black-tux-admin')).token,
  },
})

const queries = {
  allProductSets,
  oneProductSet,
  updateProductSet,
  createProductSet,
}

export default queries
