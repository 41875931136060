import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import {isEmpty} from 'lodash'
import {NavItem, Navbar} from 'reactstrap'

import ToolTimeNav from '../../../components/ToolTimeNav'
import ToolTimeTabs from '../../../components/ToolTimeTabs'

function UserProfileNav({user}) {
  const tabsList = [{id: 1, name: 'Sizes', url: `/customer-care/users/${user.userId}/sizes`}]
  const navItems = (
    <NavItem className="float-right">
      <div className="last-logged-in">
        Last Logged In
        <br />
        {user.lastLogin ? moment(user.lastLogin).format('MMMM Do YYYY, h:mm:ss a') : 'Has not created account yet.'}
      </div>
    </NavItem>
  )

  return (
    <section>
      <Navbar className="tooltime-navbar" color="inverse" fixed="top">
        <ToolTimeNav
          navItems={navItems}
          subTitle={user.email ? user.email : 'Email address not saved yet'}
          tabsList={<ToolTimeTabs tabsList={tabsList} dark />}
          title={user.fullName || (!isEmpty(user) && 'N/A') || 'User Not Found'}
        />
      </Navbar>
    </section>
  )
}

UserProfileNav.defaultProps = {
  user: {
    lastLogin: null,
    email: null,
    fullName: 'Loading',
    userId: null,
  },
}

UserProfileNav.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    lastLogin: PropTypes.string,
    fullName: PropTypes.string,
  }),
}

export default UserProfileNav
