import {BaseError} from 'make-error'

export default class ProcessOrderPaymentError extends BaseError {
  constructor(message, statusCode, internalErrorCode) {
    super(message)
    this.message = message
    this.statusCode = statusCode
    this.internalErrorCode = internalErrorCode
  }
}
