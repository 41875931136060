import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {ApolloProvider} from '@apollo/client'

import defaultApolloClient from './apollo/defaultApolloClient'
import store from './store'

import App from './App'
import ModalConductorContainer from './containers/ModalConductorContainer'

import './styles/index.scss'

function render(Component) {
  ReactDOM.render(
    <Provider store={store}>
      <ApolloProvider client={defaultApolloClient}>
        <Component />
        <ModalConductorContainer />
      </ApolloProvider>
    </Provider>,
    document.getElementById('root'),
  )
}

render(App)

if (module.hot) {
  module.hot.accept(() => {
    render(require('./App').default) // eslint-disable-line
  })
}
