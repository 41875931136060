import React, {Component} from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ActionAddButton from '@material-ui/icons/Add'
import {green} from '@material-ui/core/colors'

import {addProductImage as addProductImageAction} from '../../../actions/product'
import {getImageContentTypes} from '../../../actions/product'

class AddProductImageButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    record: PropTypes.shape({
      setImages: PropTypes.arrayOf(PropTypes.shape({})),
      productSetId: PropTypes.string.isRequired,
    }),
    addProductImage: PropTypes.func.isRequired,
  }

  state = {imageUrlValue: '', contentTypes: [], select: 'none'}

  componentDidMount() {
    getImageContentTypes().then((response) => this.setState({contentTypes: response}))
  }

  handleChange = (e) => {
    this.setState({imageUrlValue: e.target.value})
  }

  handleContentTypeChange = (e) => {
    this.setState({select: e.target.value})
  }

  handleClick = () => {
    const {
      record: {setImages, productSetId},
      addProductImage,
    } = this.props
    const payload = {
      url: this.state.imageUrlValue,
      content_type: this.state.select,
      position: isEmpty(setImages) ? 1 : setImages.length + 1,
    }
    addProductImage(productSetId, payload, setImages)
  }

  render() {
    const {select, contentTypes} = this.state
    const {className} = this.props
    return (
      <div className={className}>
        <TextField onChange={this.handleChange} placeholder="Add image URL" />
        <Select onChange={this.handleContentTypeChange} value={select}>
          <MenuItem value="none" disabled>
            Select Content Type
          </MenuItem>
          {contentTypes.map(({slug, name}) => (
            <MenuItem value={slug}>{name}</MenuItem>
          ))}
        </Select>
        <Button onClick={this.handleClick}>
          <ActionAddButton nativeColor={green[500]} />
        </Button>
      </div>
    )
  }
}

export default connect(null, {addProductImage: addProductImageAction})(AddProductImageButton)
