import React from 'react'
import PropTypes from 'prop-types'
import {Col, Row} from 'reactstrap'

export function TwoColumnRow({col1, col2, widths}) {
  return (
    <Row>
      <Col sm={widths.col1}>{col1}</Col>
      <Col sm={widths.col2}>{col2}</Col>
    </Row>
  )
}
TwoColumnRow.defaultProps = {
  col1: null,
  col2: null,
  widths: {
    col1: 6,
    col2: 6,
  },
}

TwoColumnRow.propTypes = {
  col1: PropTypes.any,
  col2: PropTypes.any,
  widths: PropTypes.shape({
    col1: PropTypes.number,
    col2: PropTypes.number,
  }),
}

export function FourColumnRow({col1, col2, col3, col4, widths, rowClass}) {
  return (
    <Row className={rowClass}>
      <Col sm={widths.col1}>{col1}</Col>
      <Col sm={widths.col2}>{col2}</Col>
      <Col sm={widths.col3}>{col3}</Col>
      <Col sm={widths.col4}>{col4}</Col>
    </Row>
  )
}

FourColumnRow.defaultProps = {
  col1: null,
  col2: null,
  col3: null,
  col4: null,
  widths: {
    col1: 3,
    col2: 3,
    col3: 3,
    col4: 3,
  },
  rowClass: null,
}

FourColumnRow.propTypes = {
  col1: PropTypes.any,
  col2: PropTypes.any,
  col3: PropTypes.any,
  col4: PropTypes.any,
  widths: PropTypes.shape({
    col1: PropTypes.number,
    col2: PropTypes.number,
    col3: PropTypes.number,
    col4: PropTypes.number,
  }),
  rowClass: PropTypes.string,
}

export function Label(props) {
  return <h6 className="sp-label">{props.label}</h6>
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
}
