import React, {Fragment} from 'react'
import {BooleanInput, FormDataConsumer, FormTab, LongTextInput, SelectInput, TextInput} from 'react-admin'

import {isSingle, isNotGroup} from '../../utils'

// TODO: Static for now until we have the Display-Categories GraphQL queries ready in another ticket
const displayCategories = {
  single: [
    {id: 'cf3e136a-e3c2-4c8e-b1d2-d169f2d55594', name: 'Jackets'},
    {id: '0bd600f2-6362-4f1d-b9e7-5ce992971cf6', name: 'Pants'},
    {id: '853f9d9d-4c10-459c-bbd5-616251c508ba', name: 'Shirts'},
    {id: '722ce6a9-fdeb-4041-a6fe-6d113cc7c262', name: 'Neckwear'},
    {id: 'a8c834d2-453f-47d4-a272-47e0bb0348bb', name: 'Accessory'},
    {id: 'ad2acbf7-c5c7-4816-8987-4ba55db40a2c', name: 'Shoes'},
    {id: '23b0d1f7-fb0c-4d41-965d-9fc48c88883d', name: 'Cufflinks & Studs'},
    {id: '68877654-f52e-43b0-95b3-57a7bfb6efad', name: 'Belts'},
    {id: '53cfd431-1cd6-4f83-81f3-0563debc46de', name: 'Vests'},
    {id: '52a149f9-b4a8-45b2-9082-d692c64d3aaa', name: 'Boys'},
  ],
  kit: [
    {id: '547e3cd8-cafe-4a36-b883-f4323b21248d', name: 'Tuxedos'},
    {id: '9a8f8d80-f94a-4c63-9403-9c7e33cabd1f', name: 'Suits'},
    {id: '52a149f9-b4a8-45b2-9082-d692c64d3aaa', name: 'Boys'},
    {id: '12494daf-f2ba-4af8-a76a-3b730c390812', name: 'Vest & Pant'},
  ],
  group: [{id: 'b67c750e-dbcf-4364-9816-92b0d3361fc4', name: 'Outfits'}],
}

const ProductDetailsTab = () => (
  <FormTab label="Product Details">
    <SelectInput
      label="Type"
      source="setType"
      choices={[
        {id: 'single', name: 'Single'},
        {id: 'group', name: 'Group'},
        {id: 'kit', name: 'Kit'},
      ]}
    />
    <TextInput label="Name" source="name" />
    <FormDataConsumer>
      {({formData, ...rest}) => isSingle(formData.setType) && <TextInput {...rest} label="Style Code" source="style" />}
    </FormDataConsumer>
    <FormDataConsumer>
      {({formData, ...rest}) =>
        isNotGroup(formData.setType) && (
          <Fragment>
            <TextInput {...rest} label="Rent Price" source="rentPrice" defaultValue="0" />
            <TextInput {...rest} label="Buy Price" source="buyPrice" defaultValue="0" />
          </Fragment>
        )
      }
    </FormDataConsumer>
    <BooleanInput label="Is Purchasable" source="isPurchasable" defaultValue={false} />
    {/* Remove Once 'Price' field is removed from product-service */}
    <TextInput style={{display: 'none'}} label="Price" source="price" defaultValue="0" />
    <LongTextInput label="Description" source="description" />
    {Object.keys(displayCategories).map((setType) => (
      <FormDataConsumer key={setType}>
        {({formData, ...rest}) =>
          setType === formData.setType && (
            <SelectInput
              {...rest}
              label="Category"
              source="setSpecs.displayCategoryId"
              choices={displayCategories[setType]}
            />
          )
        }
      </FormDataConsumer>
    ))}
    <TextInput label="URL Slug" source="urlSlug" />
    <TextInput label="Slug" source="slug" />
  </FormTab>
)

export default ProductDetailsTab
