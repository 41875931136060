import {SubmissionError} from 'redux-form'
import {get} from 'lodash'
import gql from 'graphql-tag'

import * as mutations from '../constants/Mutations'
import {formatServerErrors, queryError, parseIfJSON} from '../constants/lib'
import {query, userQueryName} from '../constants/Queries'
import initializeForm from './initializeForm'
import {sendSizeChangedEmail} from './sizeChangedEmail'
import * as actions from './sizeData'
import apolloClient from '../../../api/graphql/apolloClient'

export function shouldThrowError(errors, data) {
  if (errors) {
    const non404ErrorsExist = errors.some((error) => {
      const parsedError = parseIfJSON(error.message) || {}
      return parsedError.status !== 404
    })
    const userData = data && data[userQueryName]
    // throw errors if no data exists at all
    // throw all errors that aren't 404s if we could successfully retreive the user (if not,
    // those errors are handled elsewhere so letting them pass through)
    return (non404ErrorsExist && userData) || !data
  }
  return false
}

export function requestSizePageData(variables) {
  return (dispatch) => {
    dispatch(actions.sizeQueryLoading())
    return apolloClient
      .query({
        query: gql`
          ${query}
        `,
        variables,
      })
      .then((response) => {
        const {errors, data} = response
        if (shouldThrowError(errors, data)) {
          throw new Error(queryError(errors))
        } else {
          const userData = data[userQueryName]
          dispatch(
            initializeForm({
              assignedSizes: userData && userData.sizes,
              survey: userData && userData.fitProfile,
            }),
          )
          dispatch(actions.sizeQuerySuccess(response))
        }
      })
      .catch((error) => dispatch(actions.sizeQueryError(error)))
  }
}

// SUBMIT FUNCTIONS
/* In order to take advantage of the features redux-form provides, the submit function needs to
   return a promise. It then takes care of "isFetching" flags, "successful submit", etc. behind
   the scenes - which is why these are not typical actions.
*/
export function submit(mutation, responseFunction) {
  return (dispatch) => (variables) =>
    apolloClient
      .mutate({
        mutation: gql`
          ${mutation}
        `,
        variables,
      })
      .then((response) => responseFunction(response, dispatch))
      .catch((error) => {
        if (error instanceof SubmissionError) {
          throw error
        } else {
          throw new SubmissionError({_error: [error.message]})
        }
      })
}

export function formatAndThrowErrors(errors) {
  const formattedErrors = formatServerErrors(errors)
  throw new SubmissionError({_error: formattedErrors})
}

export function surveyMutationResponse(response, dispatch) {
  const returnedData = get(response, `data.${mutations.surveyMutationName}`)
  if (returnedData && returnedData.fitProfile && returnedData.sizes) {
    dispatch(
      initializeForm({
        assignedSizes: returnedData.sizes,
        survey: returnedData.fitProfile,
      }),
    )
    dispatch(actions.surveyMutationSuccess(returnedData))
  } else {
    formatAndThrowErrors(response.errors)
  }
}

export function sizeMutationResponse(response, dispatch) {
  const returnedData = get(response, `data.${mutations.assignedSizeMutationName}`)
  if (returnedData) {
    dispatch(sendSizeChangedEmail()) // only send email if sizes were successfully saved
    dispatch(initializeForm({assignedSizes: returnedData}))
    dispatch(actions.sizeMutationSuccess(returnedData))
  } else {
    formatAndThrowErrors(response.errors)
  }
}

export const submitAssignedSizes = submit(mutations.assignedSizeMutation, sizeMutationResponse)
export const submitSurvey = submit(mutations.surveyMutation, surveyMutationResponse)
