import get from 'lodash/get'
import {createSelector} from 'reselect'

import {ApiRequestStates} from '../../../api/const'

export const user = (state) => get(state, 'mti.user')
export const accountBalance = (state) => get(user(state), 'accountBalance')
export const userSizes = (state) => get(user(state), 'sizes')
export const getUserAccountBalanceStatus = (state) => get(user(state), 'getUserAccountBalanceStatus')
export const getUserSizesStatus = (state) => get(user(state), 'getUserSizesStatus')
export const hasAppliedAccountBalance = (state) => get(user(state), 'hasAppliedAccountBalance')

export const isGetUserAccountBalancePending = createSelector(
  getUserAccountBalanceStatus,
  (status) => status === ApiRequestStates.PENDING,
)
