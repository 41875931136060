import {reducer} from '../helpers'
import {ApiRequestStates} from '../../../api/const'
import {TransactionItem} from '../../../types'

import * as transactionItemsActions from '../../actions/PaymentPortal/transactionItems'

export interface TransactionsState {
  items: TransactionItem[]
  requestState: ApiRequestStates
}

const initialState: TransactionsState = {
  items: [],
  requestState: ApiRequestStates.IDLE,
}

type TransactionMetadataAction =
  | transactionItemsActions.AddTransactionItemsAction
  | transactionItemsActions.RemoveTransactionItemAction

export default reducer(
  initialState,
  (state: TransactionsState, action: TransactionMetadataAction): Partial<TransactionsState> | null => {
    switch (action.type) {
      case transactionItemsActions.PaymentPortalTransactionItemsActionName.AddTransactionItems:
        return {
          items: [...state.items, ...action.payload.items],
        }
      case transactionItemsActions.PaymentPortalTransactionItemsActionName.RemoveTransactionItem:
        const removeItemId = action.payload
        const updatedItems = state.items.filter((item) => item.id !== removeItemId)
        return {items: updatedItems}
      default:
        return null
    }
  },
)
