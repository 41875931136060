import {combineReducers} from 'redux'
import {adminReducer, defaultI18nProvider, i18nReducer} from 'react-admin'
import {reducer as formReducer} from 'redux-form'
import {routerReducer} from 'react-router-redux'

import modal from './reducers/modal'
import mti from './reducers/MTI/index'
import paymentPortal from './reducers/PaymentPortal'
import session from '../legacy/reducers/session'
import siteMenu from '../legacy/reducers/siteMenu'
import worldpay from '../redux/reducers/worldpay'
import {productReducer} from '../legacy/sub-apps/product/root'
import {sizeDataReducer} from '../legacy/sub-apps/sizes/root'

export default combineReducers({
  admin: adminReducer,
  form: formReducer,
  i18n: i18nReducer('en', defaultI18nProvider('en')),
  modal,
  mti,
  router: routerReducer,
  paymentPortal,
  session,
  siteMenu,
  worldpay,
  ...productReducer,
  ...sizeDataReducer,
})
