import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'

import {MODALS} from '../../redux/enums'
import asyncComponent from '../HOC/asyncComponent'
import * as modalSelectors from '../../redux/selectors/modal'

const LazyItemsNotFoundModal = asyncComponent(() =>
  import('./ItemsNotFoundModal' /* webpackChunkName: "items-not-found-modal" */),
)

const LazyNewCreditCardModal = asyncComponent(() =>
  import('./NewCreditCardModal' /* webpackChunkName: "new-credit-card-modal" */),
)

const LazyNewPPCreditCardModal = asyncComponent(() =>
  import('./NewPPCreditCardModal' /* webpackChunkName: "new-credit-card-modal" */),
)

const LazyPaymentErrorModalFactory = asyncComponent(() =>
  import('./PaymentErrorModalFactory' /* webpackChunkName: "payment-error-modal-factory" */),
)

const LazyProductSearchModal = asyncComponent(() =>
  import('./ProductSearchModal' /* webpackChunkName: "product-search-modal" */),
)

const LazyPaymentPortalReasonModal = asyncComponent(() =>
  import('./PaymentPortalReasonModal' /* webpackChunkName: "payment-portal-reason-modal" */),
)

const LazyPageOverlayThrobber = asyncComponent(() =>
  import('./PageOverlayThrobber' /* webpackChunkName: "page-overlay-throbber" */),
)

const LazyPaymentPortalUnableToRetrieveDataModal = asyncComponent(() =>
  import('./UnableToRetrieveDataModal' /* webpackChunkName: "unable-to-retrieve-data-modal" */),
)

const ModalConductor = ({currentModalId}) => {
  switch (currentModalId) {
    case MODALS.ITEMS_NOT_FOUND_MODAL:
      return <LazyItemsNotFoundModal />
    case MODALS.NEW_CREDIT_CARD_MODAL:
      return <LazyNewCreditCardModal />
    case MODALS.NEW_PP_CREDIT_CARD_MODAL:
      return <LazyNewPPCreditCardModal />
    case MODALS.PAGE_OVERLAY_THROBBER:
      return <LazyPageOverlayThrobber />
    case MODALS.PAYMENT_FAILED_MODAL:
      return <LazyPaymentErrorModalFactory />
    case MODALS.PRODUCT_SEARCH_MODAL:
      return <LazyProductSearchModal />
    case MODALS.PAYMENT_PORTAL_REASON_MODAL:
      return <LazyPaymentPortalReasonModal />
    case MODALS.PAYMENT_PORTAL_UNABLE_TO_RETRIEVE_DATA_MODAL:
      return <LazyPaymentPortalUnableToRetrieveDataModal />
    default:
      return null
  }
}

ModalConductor.propTypes = {
  currentModalId: PropTypes.string.isRequired,
}

const mapStateToProps = createStructuredSelector({
  currentModalId: modalSelectors.currentModalId,
})

export default connect(mapStateToProps)(ModalConductor)
