import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ActionAddButton from '@material-ui/icons/Add'
import {green} from '@material-ui/core/colors'

import {addProductToSet as addProductToSetAction} from '../../../actions/product'

class AddProductToSetButton extends Component {
  static propTypes = {
    record: PropTypes.shape({
      productSetId: PropTypes.string.isRequired,
    }),
    addProductToSet: PropTypes.func.isRequired,
  }

  state = {addProductValue: ''}

  handleChange = (e) => {
    this.setState({addProductValue: e.target.value})
  }

  handleClick = () => {
    const {record, addProductToSet} = this.props
    const payload = {child_set: this.state.addProductValue}
    addProductToSet(record, payload)
  }

  render() {
    return (
      <div>
        <TextField onChange={this.handleChange} placeholder="Product Set ID" />
        <Button onClick={this.handleClick}>
          <ActionAddButton nativeColor={green[500]} />
        </Button>
      </div>
    )
  }
}

export default connect(null, {addProductToSet: addProductToSetAction})(AddProductToSetButton)
