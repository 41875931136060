import React from 'react'
import {Route} from 'react-router-dom'

import Products from '../containers/Products'

export const ROOT_PRODUCT_ROUTE = '/product-management'
export const SOLD_OUT_DATES = '/product-management/sold-out-dates'
export const ADD_SOLD_OUT_DATES = '/product-management/sold-out-dates/new'
export const EDIT_SOLD_OUT_DATES = '/product-management/sold-out-dates/edit/:id'

export default <Route path={ROOT_PRODUCT_ROUTE} component={Products} />
