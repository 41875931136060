import {combineReducers} from 'redux'

import order from './order'
import orderSummary from './orderSummary'
import productsToAdd from './productsToAdd'
import user from './user'

export default combineReducers({
  order,
  orderSummary,
  productsToAdd,
  user,
})
