import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {red} from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import ActionDeleteIcon from '@material-ui/icons/Delete'

import {deleteProductImage as deleteProductImageAction} from '../../../actions/product'

class DeleteProductImageButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    deleteProductImage: PropTypes.func.isRequired,
    imageId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
  }

  handleClick = () => {
    const {imageId, productId, deleteProductImage} = this.props
    deleteProductImage(productId, imageId)
  }

  render() {
    return (
      <Button className={this.props.className} onClick={this.handleClick}>
        <ActionDeleteIcon nativeColor={red[500]} />
      </Button>
    )
  }
}

export default connect(null, {deleteProductImage: deleteProductImageAction})(DeleteProductImageButton)
