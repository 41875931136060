import Raven from 'raven-js'

/**
 * Log Error Exception to SentryJs
 *
 * @param {string} errorMsgFuncName
 * @param {Number} response
 * @param {object} error
 */
export default function logErrorException(errorMsgFuncName, response, error) {
  const errStatusMsg = `${response || error} Error: ${errorMsgFuncName}() function`
  Raven.captureMessage(errStatusMsg, {
    tags: {
      function_name: errorMsgFuncName,
    },
  })

  if (error) {
    throw new Error(error)
  }
}
