import partial from 'lodash/partial'

import {fsa, fsaPayloadByKeys} from './helpers'
import {MODALS} from '../enums'

export enum ModalAction {
  OPEN_MODAL = 'open the modal component',
  CLOSE_MODAL = 'close the modal component',
}

export interface OpenModal {
  type: ModalAction.OPEN_MODAL
}

export interface CloseModal {
  type: ModalAction.CLOSE_MODAL
}

export type OpenModalFn = () => OpenModal
// type CloseModalFn = () => CloseModal

export const openModal = fsaPayloadByKeys(ModalAction.OPEN_MODAL, ['id'])
export const closeModal = fsa(ModalAction.CLOSE_MODAL)

// Specific modals

export const openNewCreditCardModal: OpenModalFn = partial(openModal, MODALS.NEW_CREDIT_CARD_MODAL)
export const openNewPPCreditCardModal: OpenModalFn = partial(openModal, MODALS.NEW_PP_CREDIT_CARD_MODAL)
export const openPaymentFailedModal: OpenModalFn = partial(openModal, MODALS.PAYMENT_FAILED_MODAL)
export const openPageOverlayThrobber = partial(openModal, MODALS.PAGE_OVERLAY_THROBBER)
export const openProductSearchModal: OpenModalFn = partial(openModal, MODALS.PRODUCT_SEARCH_MODAL)
export const openPaymentPortalReasonModal: OpenModalFn = partial(openModal, MODALS.PAYMENT_PORTAL_REASON_MODAL)
export const openItemsNotFoundModal: OpenModalFn = partial(openModal, MODALS.ITEMS_NOT_FOUND_MODAL)
export const openUnableToRetrieveDataModal: OpenModalFn = partial(
  openModal,
  MODALS.PAYMENT_PORTAL_UNABLE_TO_RETRIEVE_DATA_MODAL,
)
