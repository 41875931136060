import {all, call, fork, put, select, take, takeLatest} from 'redux-saga/effects'

import * as customerActions from '../../actions/PaymentPortal/customer'
import * as ordersActions from '../../actions/orders'
import * as paymentPortalOrderActions from '../../actions/PaymentPortal/order'
import * as paymentPortalSelectors from '../../selectors/PaymentPortal'
import * as reasonActions from '../../actions/PaymentPortal/reasons'
import {TransactionType} from '../../../types/PaymentPortal'

import {formatOrderPayload} from './utils'

export function* getCurrentOrderDetailsWorker({payload: {orderId}}) {
  yield put(paymentPortalOrderActions.getCurrentOrderDetailsPending())

  try {
    const order = yield call(getOrderInfo, orderId)
    const payload = yield call(formatOrderPayload, order)

    const customerId = order.user.id
    yield put(customerActions.getCustomerInfo(customerId))

    const transactionType = yield select(paymentPortalSelectors.transactionType)
    if (transactionType === TransactionType.Charges) {
      yield put(reasonActions.getEligibleTransactionReasons(order.id))
    }

    const transactionPayload = {order: payload}

    yield put(paymentPortalOrderActions.getCurrentOrderDetailsSuccess(transactionPayload))
  } catch (e) {
    yield put(paymentPortalOrderActions.getCurrentOrderDetailsFailure(e))
  }
}

export function* getOrderInfo(orderId) {
  yield put(ordersActions.getOrderInfo(orderId))

  const getOrderInfoAction = yield take([ordersActions.GET_ORDER_INFO_FAILURE, ordersActions.GET_ORDER_INFO_SUCCESS])

  if (getOrderInfoAction.type === ordersActions.GET_ORDER_INFO_FAILURE) {
    return new Error(getOrderInfoAction.type)
  }
  return getOrderInfoAction.payload
}

export function* getCurrentOrderDetailsSaga() {
  yield takeLatest(paymentPortalOrderActions.PPOrderAction.GET_CURRENT_ORDER_DETAILS, getCurrentOrderDetailsWorker)
}

export function* getCurrentOrderDetailsFailureWorker({payload}) {
  yield call(alert, `${payload.message}, please try again.`)
}

export function* getCurrentOrderDetailsFailureSaga() {
  yield takeLatest(
    paymentPortalOrderActions.PPOrderAction.GET_CURRENT_ORDER_DETAILS_FAILURE,
    getCurrentOrderDetailsFailureWorker,
  )
}

export default function* paymentPortalOrderSagas() {
  yield all([fork(getCurrentOrderDetailsSaga), fork(getCurrentOrderDetailsFailureSaga)])
}
