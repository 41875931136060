import {toNumber, values} from 'lodash'
import * as en from './enums'

export function stringToNumber(originalValue) {
  let value = originalValue
  if (typeof originalValue === 'string') {
    const numericValue = toNumber(originalValue)
    value = Number.isFinite(numericValue) ? numericValue : value
  }
  return value
}

export function currentAge(yearBorn) {
  const currentYear = new Date().getFullYear()
  const age = currentYear - yearBorn
  return Number.isFinite(age) ? `${age} yrs old` : 'N/A'
}

export function calculateBeltSize(waist) {
  return Number.isFinite(waist) ? waist + 2 : en.noneValue
}

// Takes in an object with array values
export function determineValueFromMap(map = {}) {
  return (value) => {
    const computedValue = Object.keys(map).find((key) => map[key].includes(value))
    return computedValue || en.noneValue
  }
}

// compares the calculated and current value of a field to determine if a warning should be shown
export function calculationWarning(calculation, formField) {
  // this function is given to the "warn" prop, undefined indicates no warning should be shown
  return (currentValue, allValues) => {
    const calculatedValue = calculation(allValues[formField])
    return currentValue !== calculatedValue ? `Warning - Field computed to be: ${calculatedValue}` : undefined
  }
}

export function autoCalculateMessage(currentValue) {
  return currentValue === en.noneValue ? 'Auto-calculated if left blank' : undefined
}

export function generateOptionObj(option) {
  const optionObj = {
    key: option.value,
    value: option.value,
    label: option.label,
    disabled: option.disabled || false,
  }
  return optionObj
}

export function createFieldOptions(fieldOptions = [], isRequired) {
  const noneOption = {value: en.noneValue, label: en.noneLabel, disabled: isRequired}
  return [noneOption, ...fieldOptions].map((option) => generateOptionObj(option))
}

export function validateWholeNumber(validNumbers) {
  const min = Math.min(...validNumbers)
  const max = Math.max(...validNumbers)
  return (value) => {
    let error
    if (Number.isFinite(value)) {
      if (value < min) {
        error = `Cannot be less than ${min}`
      } else if (value > max) {
        error = `Cannot exceed ${max}`
      } else if (value % 1 !== 0) {
        error = 'Must be a whole number'
      }
    } else if (value !== en.noneValue) {
      // exclude default "none" value
      error = 'Must be a number'
    }
    return error
  }
}

export function parseIfJSON(jsonString) {
  try {
    if (typeof jsonString !== 'string') {
      return false
    }
    return JSON.parse(jsonString)
  } catch (error) {
    return false
  }
}

export function parseProbabilities(probabilities = []) {
  return probabilities
    .map((prediction) => ({
      probability: Math.round(prediction.probability * 100),
      value: stringToNumber(prediction.value),
    }))
    .filter((prediction) => prediction.probability > 0)
    .sort((a, b) => b.probability - a.probability) // sort by probabilities descending
    .slice(0, 3)
}

export function markMissingFields(formValues, requiredFormFields) {
  return Object.keys(formValues)
    .filter((field) => requiredFormFields.includes(field) && formValues[field] === en.noneValue)
    .reduce((obj, field) => {
      obj[field] = 'Required Field.'
      return obj
    }, {})
}

export function valueToLabel(map = []) {
  return (value) => {
    const mapObj = map.filter((obj) => obj.value === value)[0] || {}
    return mapObj.label
  }
}

export function queryError(errors) {
  return errors.map((error) => {
    const parsedError = parseIfJSON(error.message)
    const errorMessage = parsedError ? parsedError.message : error.message
    const errorPath = error.path ? `Could not retreive ${error.path.join('.')}. ` : ''
    return `${errorPath}Error: ${errorMessage}`
  })
}

export function formatServerErrors(serverErrors) {
  return serverErrors.map((error) => {
    const parsedError = parseIfJSON(error.message)
    let errors = (parsedError && parsedError.message) || parsedError || error.message
    if (errors === 'Validation Errors') {
      const validationErrorMessages = values(parsedError.errors)
      errors = validationErrorMessages.join(', ')
    }
    return errors
  })
}

export function getFromObj(form, obj) {
  return Object.keys(obj).reduce((newObj, key) => {
    if (key.includes(form)) {
      newObj[key] = obj[key]
    }
    return newObj
  }, {})
}
