import get from 'lodash/get'
import {createSelector} from 'reselect'

export const productsToAdd = (state) => get(state, 'mti.productsToAdd')
export const productsByOrderId = (state) => get(productsToAdd(state), 'productsByOrderId')
export const allProductOrderIds = (state) => get(productsToAdd(state), 'allProductOrderIds')
export const isSnackbarActive = (state) => get(productsToAdd(state), 'isSnackbarActive')
export const snackbarMessage = (state) => get(productsToAdd(state), 'snackbarMessage')

export const addedProductsList = createSelector(allProductOrderIds, productsByOrderId, (productIds, products) => {
  return productIds.map((productId) => products[productId])
})

export const allAddedProductSetIds = createSelector(addedProductsList, ($addedProductsList) =>
  $addedProductsList.map(({id}) => id),
)
