import * as en from './enums'
import {calculateBeltSize, calculationWarning, autoCalculateMessage} from './lib'

/*
  This file contains data objects to be fed into the form row component - specifying the data and
  field information to be created/displayed

  label: Row label
  assignedSizes: attributes to be given to the assigned sizes field
  survey: attributes to be given to the survey field
  predictedSize: api property returned from the fit service containing size recommendations

  Potential attributes for "assignedSizes" or "survey":
    formFieldName: name used by Redux Form to identify the field - must be unique
    warning: Redux Form warning to be displayed (function)
    className: additional class to be added to the form field
    onChange: Redux Form function invoked on change of the form field
*/

export const beltRow = {
  label: 'Belt Size',
  assignedSizes: {
    formFieldName: en.assignedFormBelt,
    warning: (curr, allVals) =>
      autoCalculateMessage(curr) || calculationWarning(calculateBeltSize, en.assignedFormWaist)(curr, allVals),
  },
}

export const inseamRow = {
  label: 'Inseam Length',
  survey: {formFieldName: en.surveyFormInseam},
  predictedSize: en.predictionInseam,
  assignedSizes: {formFieldName: en.assignedFormInseam},
}

export const inseamAdjustRow = {
  label: 'Inseam Adjustment',
  assignedSizes: {formFieldName: en.assignedFormInseamSizeAdjust},
}

export const jacketLengthRow = {
  label: 'Jacket Length',
  survey: {formFieldName: en.surveyFormJacketLength},
  predictedSize: en.predictionJacketLength,
  assignedSizes: {formFieldName: en.assignedFormJacketLength},
}

export const jacketSizeRow = {
  label: 'Jacket Size',
  survey: {formFieldName: en.surveyFormChest},
  predictedSize: en.predictionChest,
  assignedSizes: {formFieldName: en.assignedFormChest},
}

export const jacketSleeveAdjustRow = {
  label: 'Sleeve Adjustment',
  assignedSizes: {formFieldName: en.assignedFormJacketSleeveAdjust},
}

export const neckRow = {
  label: 'Neck Size',
  survey: {formFieldName: en.surveyFormNeck},
  predictedSize: en.predictionNeck,
  assignedSizes: {formFieldName: en.assignedFormNeck},
}

export const pantFitRow = {
  label: 'Pant Fit',
  assignedSizes: {
    formFieldName: en.assignedFormPantFit,
    warning: autoCalculateMessage,
  },
}

export const preferredFitRow = {
  label: 'Preferred Fit',
  survey: {formFieldName: en.surveyFormPreferredFit},
}

export const reasonRow = {
  label: 'Reason for size check',
  assignedSizes: {
    formFieldName: en.assignedFormReason,
    className: 'reason',
  },
}

export const shirtFitRow = {
  label: 'Shirt Fit',
  assignedSizes: {
    formFieldName: en.assignedFormShirtFit,
    warning: autoCalculateMessage,
  },
}

export const shoeRow = {
  label: 'Shoe Size',
  survey: {formFieldName: en.surveyFormShoe},
  assignedSizes: {formFieldName: en.assignedFormShoe},
}

export const shoulderShapeRow = {
  label: 'Shoulder Shape',
  survey: {formFieldName: en.surveyFormShoulderShape},
}

export const sleeveRow = {
  label: 'Sleeve Length',
  survey: {formFieldName: en.surveyFormSleeve},
  predictedSize: en.predictionSleeve,
  assignedSizes: {formFieldName: en.assignedFormSleeve},
}

export const stomachShapeRow = {
  label: 'Stomach Shape',
  survey: {formFieldName: en.surveyFormStomachShape},
}

export const vestLengthRow = {
  label: 'Vest Length',
  assignedSizes: {
    formFieldName: en.assignedFormVestLength,
  },
}

export const vestSizeRow = {
  label: 'Vest Size',
  assignedSizes: {formFieldName: en.assignedFormVest},
}

export const waistRow = {
  label: 'Waist Size',
  survey: {formFieldName: en.surveyFormWaist},
  predictedSize: en.predictionWaist,
  assignedSizes: {formFieldName: en.assignedFormWaist},
}
