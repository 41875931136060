import {AnyAction} from 'redux'

import {reducer} from '../helpers'
import {ApiRequestStates} from '../../../api/const'

export interface WorldpayState {
  expMonth: string
  expYear: string
  isCardOnFileCharge: boolean
  last4CreditCardDigits: string
  paypageRegistrationId: string | null
  requestState: ApiRequestStates
}

const initialState: WorldpayState = {
  expMonth: '',
  expYear: '',
  isCardOnFileCharge: true,
  last4CreditCardDigits: '',
  paypageRegistrationId: null,
  requestState: ApiRequestStates.IDLE,
}

export default reducer(initialState, (state: WorldpayState, action: AnyAction): Partial<WorldpayState> | null => {
  switch (action.type) {
    default:
      return null
  }
})
