import {OrderType, OrderUser, RevenueType} from '../index'

export enum MiscellaneousRefundReason {
  BoxReimbursement = 'box-reimbursement',
  Tailoring = 'dry-cleaning-tailoring',
  TbtError = 'tbt-error',
  PromoCodeRedemption = 'promo-code-redemption',
  Other = 'miscellaneous',
}

export enum RefundType {
  Reimbursement = 'refund_reimbursement',
  Transaction = 'refund_transaction',
}

export interface MiscellaneousRefundReasonOptions<Type> {
  [MiscellaneousRefundReason.BoxReimbursement]: Type
  [MiscellaneousRefundReason.Tailoring]: Type
  [MiscellaneousRefundReason.TbtError]: Type
  [MiscellaneousRefundReason.PromoCodeRedemption]: Type
  [MiscellaneousRefundReason.Other]: Type
}

export interface RefundsMiscellaneousTransactionsApiPayload {
  amount: number
  description: string
  orderId: number
  reason: MiscellaneousRefundReason
}

export type RefundsApiTransactionItem = RefundsMiscellaneousTransactionsApiPayload

export interface RefundsSummaryTotalsPayload {
  payerId: number
  overrideLateRefund: boolean
  refundType: RefundType
  refunds: RefundsApiTransactionItem[]
}

export interface RefundsSummaryTotalsResponse {
  accountBalanceToApply: number
  subtotal: number
  subtotalWithTax: number
  tax: number
  total: number
  totalAccountBalance: number
}

export type MiscellaneousRefundReasonDisplayName =
  | 'Box Reimbursement'
  | 'Tailoring/Dry Cleaning'
  | 'TBT Error'
  | 'Promo Code Redemption'
  | 'Other'

export type RefundsPaymentApiPayload = RefundsSummaryTotalsPayload

export interface RefundsTransactionItem {
  user: OrderUser
  id: string
  isRefundable: boolean
  orderId: number | null
  orderType: OrderType | null
  photoUrl: string | null
  pricePaid: number
  productName: string | null
  revenueType: RevenueType
  skuCode: string | null
}

export interface FormattedRefundsTransactionOrder {
  user: OrderUser
  orderId: number
  orderType: OrderType
  transactionItems: RefundsTransactionItem[]
}

export interface FormattedRefundsTransaction {
  transactionId: string
  transactionType: string
  orders: FormattedRefundsTransactionOrder[]
}
