import get from 'lodash/get'
import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'

import ProcessOrderPaymentError from '../../../exceptions'
import {snakeCaseObjectKeys} from '../../../utilities/formatting'
import * as apiUtils from '../../../api/helpers'
import * as modalActions from '../../actions/modal'
import * as mtiOrderSelectors from '../../selectors/MTI/order'
import * as mtiOrderSummaryActions from '../../actions/MTI/orderSummary'
import * as mtiProductsToAddActions from '../../actions/MTI/productsToAdd'
import * as mtiProductsToAddSelectors from '../../selectors/MTI/productsToAdd'
import * as mtiUserSelectors from '../../selectors/MTI/user'
import * as ordersApiClient from '../../../api/OrdersApiClient'
import * as pricingApiClient from '../../../api/PricingApiClient'
import * as sessionSelectors from '../../selectors/session'
import * as worldpaySelectors from '../../selectors/worldpay'

/**
 * Helper function to assemble request payload to fetch products pricing totals.
 */
export function* createProductPricingTotalsRequestBody() {
  return snakeCaseObjectKeys({
    transactionType: yield select(mtiOrderSelectors.transactionType),
    productSetIds: yield select(mtiProductsToAddSelectors.allAddedProductSetIds),
    address1: yield select(mtiOrderSelectors.shippingAddress1),
    address2: yield select(mtiOrderSelectors.shippingAddress2),
    city: yield select(mtiOrderSelectors.shippingCity),
    state: yield select(mtiOrderSelectors.shippingState),
    zipCode: yield select(mtiOrderSelectors.shippingZip),
  })
}

export function* getProductsPricingTotalsWorker() {
  yield put(mtiOrderSummaryActions.getProductsPricingTotalsPending())

  // check first if there are no items remaining from the user removing all items
  const addedProductSetIds = yield select(mtiProductsToAddSelectors.allAddedProductSetIds)
  if (!addedProductSetIds.length) {
    yield put(mtiOrderSummaryActions.getProductsPricingTotalsSuccess({subtotal: 0, tax: 0, total: 0}))
    return
  }

  try {
    const requestBody = yield call(createProductPricingTotalsRequestBody)
    const {data, status} = yield call(pricingApiClient.getProductsPricingTotals, requestBody)
    if (apiUtils.isBadResponse(status)) {
      throw new Error('Unable to fetch pricing totals for selected items.')
    }
    yield put(mtiOrderSummaryActions.getProductsPricingTotalsSuccess(data))
  } catch (err) {
    yield put(mtiOrderSummaryActions.getProductsPricingTotalsFailure(err))
  }
}

export function* getProductsPricingTotalsSaga() {
  yield takeLatest(
    [mtiProductsToAddActions.ADD_ITEM_TO_PRODUCT_LIST, mtiProductsToAddActions.REMOVE_ITEM_FROM_PRODUCT_LIST],
    getProductsPricingTotalsWorker,
  )
}

export function* getOrderPaymentPayload() {
  const addedProductsList = yield select(mtiProductsToAddSelectors.addedProductsList)
  const addedProductsFormattedForApi = addedProductsList.map((product) =>
    snakeCaseObjectKeys({
      productSetId: product.id,
      skuCode: product.skuCode,
    }),
  )
  return snakeCaseObjectKeys({
    applyAccountBalance: yield select(mtiUserSelectors.hasAppliedAccountBalance),
    cardExpiration: yield select(worldpaySelectors.worldpayExpirationDate),
    createdByUser: yield select(sessionSelectors.adminEmail),
    isCardOnFileCharge: yield select(worldpaySelectors.isCardOnFileCharge),
    items: addedProductsFormattedForApi,
    paypageRegistrationId: yield select(worldpaySelectors.paypageRegistrationId),
  })
}

export function* processOrderPaymentWorker() {
  yield put(mtiOrderSummaryActions.processOrderPaymentPending())
  try {
    const orderId = yield select(mtiOrderSelectors.orderId)
    const payload = yield call(getOrderPaymentPayload)
    const response = yield call(ordersApiClient.processOrderPayment, orderId, payload)
    apiUtils.onStatus({
      OK: ({data}) => data,
      400: () => {
        throw new ProcessOrderPaymentError(
          'Unable to process payment. Please review to make sure all product and payment information is accurate and complete.',
          400,
        )
      },
      403: ({data}) => {
        throw new ProcessOrderPaymentError(get(data.errors, '0'), 403)
      },
      500: () => {
        throw new ProcessOrderPaymentError(
          'Unable to process payment. Please try again. If issue persists, please report issue to a customer care lead at TBT headquarters.',
          500,
        )
      },
      DEFAULT: () => {
        throw new Error(
          'Unable to process payment. Please try again. If issue persists, please report issue to a customer care lead at TBT headquarters.',
        )
      },
    })(response)
    yield put(mtiOrderSummaryActions.processOrderPaymentSuccess())
  } catch (error) {
    yield put(mtiOrderSummaryActions.processOrderPaymentFailure(error))
  }
}

export function* processOrderPaymentSaga() {
  yield takeLatest(mtiOrderSummaryActions.PROCESS_ORDER_PAYMENT, processOrderPaymentWorker)
}

export function* processOrderPaymentFailureWorker() {
  yield put(modalActions.openPaymentFailedModal())
}

export function* processOrderPaymentFailurerSaga() {
  yield takeLatest(mtiOrderSummaryActions.PROCESS_ORDER_PAYMENT_FAILURE, processOrderPaymentFailureWorker)
}

export default function* mtiOrderSummarySagas() {
  yield all([fork(processOrderPaymentSaga), fork(processOrderPaymentFailurerSaga), fork(getProductsPricingTotalsSaga)])
}
