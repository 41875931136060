import React from 'react'
import * as data from '../constants/FormFieldData'
import FormRow from './ReusableComponents/FormRow'
import CategoryContainer from './CategoryContainer'
import {Height, Weight, BirthYear} from './BodyProfileFields'

// Preferred fit, shoulder, and stomach are displayed in the standard row format
// Height, weight, and birth year are not
export default function BodyProfile() {
  return (
    <CategoryContainer header="Body Profile">
      <Height />
      <Weight />
      <BirthYear />
      <FormRow {...data.preferredFitRow} />
      <FormRow {...data.shoulderShapeRow} />
      <FormRow {...data.stomachShapeRow} />
    </CategoryContainer>
  )
}
