import {reducer} from '../helpers'
import * as processPaymentActions from '../../actions/PaymentPortal/processPayment'
import {ApiRequestStates} from '../../../api/const'
import ProcessOrderPaymentError from '../../../exceptions'

interface ProcessPaymentNonFailure {
  requestError: null
  requestState: ApiRequestStates.IDLE | ApiRequestStates.PENDING | ApiRequestStates.SUCCESS
}

interface ProcessPaymentFailure {
  requestError: ProcessOrderPaymentError
  requestState: ApiRequestStates.ERROR
}

export type ProcessPaymentState = ProcessPaymentNonFailure | ProcessPaymentFailure

type ProcessPaymentActions =
  | processPaymentActions.PostPaymentAction
  | processPaymentActions.PostPaymentPendingAction
  | processPaymentActions.PostPaymentFailureAction
  | processPaymentActions.PostPaymentSuccessAction

const initialState: ProcessPaymentState = {
  requestError: null,
  requestState: ApiRequestStates.IDLE,
}

export default reducer(
  initialState,
  (state: ProcessPaymentState, action: ProcessPaymentActions): Partial<ProcessPaymentState> | null => {
    switch (action.type) {
      case processPaymentActions.ProcessPaymentActionName.PostPayment:
        return {requestError: null, requestState: ApiRequestStates.IDLE}
      case processPaymentActions.ProcessPaymentActionName.PostPaymentPending:
        return {requestError: null, requestState: ApiRequestStates.PENDING}
      case processPaymentActions.ProcessPaymentActionName.PostPaymentSuccess:
        return {requestError: null, requestState: ApiRequestStates.SUCCESS}
      case processPaymentActions.ProcessPaymentActionName.PostPaymentFailure:
        return {
          requestError: action.payload,
          requestState: ApiRequestStates.ERROR,
        }
      default:
        return null
    }
  },
)
