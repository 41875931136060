import React from 'react'
import PropTypes from 'prop-types'
import {formValueSelector} from 'redux-form'
import {connect} from 'react-redux'
import * as en from '../constants/enums'
import {FourColumnRow, Label} from './ReusableComponents/FormattedRows'

export function calculateDrop(chest, waist) {
  const drop = Number.isFinite(chest) && Number.isFinite(waist) && chest - waist
  return Number.isFinite(drop) ? drop : 'N/A'
}
const selector = formValueSelector(en.formName)

// SURVEY DROP //
function SurveyDropUnconnected({surveyChest, surveyWaist}) {
  return <div className="sp-display-field">{calculateDrop(surveyChest, surveyWaist)}</div>
}

SurveyDropUnconnected.defaultProps = {
  surveyChest: null,
  surveyWaist: null,
}

SurveyDropUnconnected.propTypes = {
  surveyChest: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  surveyWaist: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

function surveyDropMapStateToProps(state) {
  return {
    surveyWaist: selector(state, en.surveyFormWaist),
    surveyChest: selector(state, en.surveyFormChest),
  }
}

export const SurveyDrop = connect(surveyDropMapStateToProps)(SurveyDropUnconnected)

// ASSIGNED SIZES DROP //
function AssignedDropUnconnected({assignedChest, assignedWaist}) {
  return <div className="sp-display-field">{calculateDrop(assignedChest, assignedWaist)}</div>
}

AssignedDropUnconnected.defaultProps = {
  assignedChest: null,
  assignedWaist: null,
}

AssignedDropUnconnected.propTypes = {
  assignedChest: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  assignedWaist: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export function assignedDropMapStateToProps(state) {
  return {
    assignedWaist: selector(state, en.assignedFormWaist),
    assignedChest: selector(state, en.assignedFormChest),
  }
}

export const AssignedDrop = connect(assignedDropMapStateToProps)(AssignedDropUnconnected)

// DROP ROW //
export function DropRow() {
  return (
    <FourColumnRow col1={<Label label="Drop" />} col2={<SurveyDrop />} col4={<AssignedDrop />} rowClass="sp-form-row" />
  )
}
