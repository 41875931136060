import {createMuiTheme} from '@material-ui/core/styles'
import {grey} from '@material-ui/core/colors'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#fff',
    },
  },
  overrides: {
    MuiCardHeader: {
      root: {
        padding: '24px 24px 16px',
      },
      title: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1.125,
      },
    },
    MuiCardContent: {
      root: {
        padding: '0 24px 24px',
      },
    },
    MuiCardActions: {
      root: {
        padding: 24,
      },
    },
  },
  '@global': {
    '*::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 4,
    },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: grey[500],
    },
  },
})
