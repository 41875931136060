import get from 'lodash/get'
import {createSelector, Selector} from 'reselect'
import {ApiRequestStates} from '../../../api/const'
import {
  TransactionItem,
  TransactionMetadata,
  TransactionOrderItemMetadata,
  TransactionOrderMetadata,
  TransactionReason,
} from '../../../types'
import {PaymentPortalReducer} from '../../reducers/PaymentPortal'

import {TransactionMetadataState} from '../../reducers/PaymentPortal/transactionMetadata'

import * as transactionsStateSelectors from '../../selectors/PaymentPortal/transactions'

const store: Selector<PaymentPortalReducer, TransactionMetadataState> = (state) =>
  get(state, 'paymentPortal.transactionMetadata')

export const transactionMetadata: Selector<PaymentPortalReducer, TransactionMetadata | null> = (state) =>
  get(store(state), 'metadata')

export const transactionOrders: Selector<PaymentPortalReducer, TransactionOrderMetadata[] | undefined> = createSelector(
  transactionMetadata,
  transactionsStateSelectors.transactionItems,
  ($metadata, $transactionsItems) => {
    const metadataOrders = get($metadata, 'orders')

    const shipmentItemMapper = (shipmentItem: TransactionOrderItemMetadata): TransactionOrderItemMetadata => {
      const shipmentItemIsAssetRecoveryEligible = !!shipmentItem.isAssetRecoveryEligible
      const shipmentItemIsRentToKeepEligible = !!shipmentItem.isRentToKeepEligible

      const isItemAddedToTransactionTable = $transactionsItems?.some(
        (item: TransactionItem) =>
          (item.reason === TransactionReason.AssetRecovery || item.reason === TransactionReason.RentToKeep) &&
          item.shipmentItemId === shipmentItem.id,
      )

      // If the original isAssetRecoveryEligible value from the API endpoint is true, then ensure
      // that the item has not been added to the transaction table because we don't want to double
      // charge for the same shipment item; setting isAssetRecoveryEligible to false will mark it as
      // disabled in the AR charge modal. We also want to disable it in the RTK modal, so
      // that agents cannot add the item to the transaction table. Same logic applies to items that
      // have been added as rent to keep - they cannot be added to the transaction table for
      // asset recovery reasons.
      shipmentItem.isAssetRecoveryEligible = shipmentItemIsAssetRecoveryEligible && !isItemAddedToTransactionTable

      shipmentItem.isRentToKeepEligible = shipmentItemIsRentToKeepEligible && !isItemAddedToTransactionTable

      return shipmentItem
    }

    const orderMapper = (order: TransactionOrderMetadata): TransactionOrderMetadata => {
      const updatedShipmentItems = order.shipmentItems?.map(shipmentItemMapper)
      return {...order, shipmentItems: updatedShipmentItems}
    }

    const updatedOrders = metadataOrders?.map(orderMapper)

    return updatedOrders
  },
)

export const rentalExtensionPrice: Selector<PaymentPortalReducer, number> = (state) =>
  get(transactionMetadata(state), 'rentalExtensionPrice')

export const rentalExtensionDuration: Selector<PaymentPortalReducer, number> = (state) =>
  get(transactionMetadata(state), 'rentalExtensionDuration')

export const rushShippingPrice: Selector<PaymentPortalReducer, number> = (state) =>
  get(transactionMetadata(state), 'rushShippingPrice')

export const expeditedShippingPrice: Selector<PaymentPortalReducer, number> = (state) =>
  get(transactionMetadata(state), 'expeditedShippingPrice')

export const isGetDataForReasonPending: Selector<PaymentPortalReducer, boolean> = (state) =>
  get(store(state), 'requestState') === ApiRequestStates.PENDING

export const isGetDataForReasonFailure: Selector<PaymentPortalReducer, boolean> = (state) =>
  get(store(state), 'requestState') === ApiRequestStates.ERROR

export const isGetDataForReasonSuccess: Selector<PaymentPortalReducer, boolean> = (state) =>
  get(store(state), 'requestState') === ApiRequestStates.SUCCESS

export const hasOrdersWithShipmentItems: Selector<PaymentPortalReducer, boolean> = createSelector(
  transactionOrders,
  (orders: TransactionOrderMetadata[] | undefined): boolean => {
    if (!orders || orders.length <= 0) return false
    const ordersWithShipmentItems = orders.filter((order: TransactionOrderMetadata) => order.shipmentItems?.length > 0)

    return ordersWithShipmentItems.length > 0
  },
)
