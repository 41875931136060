import {fsa, fsaError, fsaPayload, fsaPayloadByKeys} from '../helpers'
import {TransactionPayload} from '../../../types'

export enum PPOrderAction {
  GET_CURRENT_ORDER_DETAILS = 'paymentPortal/get current order details',
  GET_CURRENT_ORDER_DETAILS_SUCCESS = 'paymentPortal/get current order details succeeded',
  GET_CURRENT_ORDER_DETAILS_FAILURE = 'paymentPortal/get current order details failed',
  GET_CURRENT_ORDER_DETAILS_PENDING = 'paymentPortal/get current order details pending',
}

export interface GetCurrentOrderDetails {
  type: PPOrderAction.GET_CURRENT_ORDER_DETAILS
}

export interface GetCurrentOrderDetailsSuccess {
  type: PPOrderAction.GET_CURRENT_ORDER_DETAILS_SUCCESS
  payload: TransactionPayload
}

export interface GetCurrentOrderDetailsFailure {
  type: PPOrderAction.GET_CURRENT_ORDER_DETAILS_FAILURE
}

export interface GetCurrentOrderDetailsPending {
  type: PPOrderAction.GET_CURRENT_ORDER_DETAILS_PENDING
}

export type GetCurrentOrderDetailsFn = (orderId: string) => GetCurrentOrderDetails
type GetCurrentOrderDetailsSuccessFn = (payload: TransactionPayload) => GetCurrentOrderDetailsSuccess
type GetCurrentOrderDetailsFailureFn = (e: Error) => GetCurrentOrderDetailsFailure
type GetCurrentOrderDetailsPendingFn = () => GetCurrentOrderDetailsPending

export const getCurrentOrderDetails: GetCurrentOrderDetailsFn = fsaPayloadByKeys(
  PPOrderAction.GET_CURRENT_ORDER_DETAILS,
  ['orderId'],
)

export const getCurrentOrderDetailsSuccess: GetCurrentOrderDetailsSuccessFn = fsaPayload(
  PPOrderAction.GET_CURRENT_ORDER_DETAILS_SUCCESS,
)

export const getCurrentOrderDetailsFailure: GetCurrentOrderDetailsFailureFn = fsaError(
  PPOrderAction.GET_CURRENT_ORDER_DETAILS_FAILURE,
)

export const getCurrentOrderDetailsPending: GetCurrentOrderDetailsPendingFn = fsa(
  PPOrderAction.GET_CURRENT_ORDER_DETAILS_PENDING,
)
