import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'

import * as modalSelectors from '../redux/selectors/modal'

import ModalConductor from '../components/Modal/ModalConductor'

const ModalConductorContainer = ({isActive}) => (isActive ? <ModalConductor /> : null)

ModalConductorContainer.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

const mapStateToProps = createStructuredSelector({
  isActive: modalSelectors.isModalActive,
})

export default connect(mapStateToProps)(ModalConductorContainer)
