const priceValidation = (value) => /^\d+(\.\d{1,2})?$/.test(value)

export const editValidation = (values) => {
  const errors = {}
  if (values.buyPrice && !priceValidation(values.buyPrice)) {
    errors.buyPrice = ['Buy Price is not valid.']
  }
  if (values.rentPrice && !priceValidation(values.rentPrice)) {
    errors.rentPrice = ['Rent Price is not valid.']
  }
  if (!values.name) {
    errors.name = ['Name is required.']
  }
  if (!values.buyPrice) {
    errors.buyPrice = ['Buy Price is required.']
  }
  if (!values.rentPrice) {
    errors.rentPrice = ['Rent Price is required.']
  }
  return errors
}
