import React from 'react'
import verge from 'verge'
import ToolTimeLoading from './ToolTimeLoading'

function ToolTimePageLoading(props) {
  const height = verge.viewportH()
  const style = {
    height: `${height}px`,
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  }
  return (
    <div className="svg-wrapper" style={style}>
      <ToolTimeLoading {...props} top="50%" translateY="-60%" />
    </div>
  )
}

export default ToolTimePageLoading
