import ProcessOrderPaymentError from '../../../exceptions'
import {ApiRequestStates} from '../../../api/const'
import {reducer} from '../helpers'
import * as mtiOrderActions from '../../actions/MTI/order'
import * as mtiOrderSummaryActions from '../../actions/MTI/orderSummary'

const initialState = {
  subtotal: 0,
  tax: 0,
  total: 0,
  processOrderPaymentErrorCode: null,
  processOrderPaymentErrorMessage: null,
  getProductsPricingTotalsStatus: ApiRequestStates.IDLE,
  getProcessOrderPaymentStatus: ApiRequestStates.IDLE,
}

export default reducer(initialState, (state, action) => {
  switch (action.type) {
    case mtiOrderActions.GET_CURRENT_ORDER_DETAILS:
      return {
        subtotal: 0,
        tax: 0,
        total: 0,
      }
    case mtiOrderSummaryActions.GET_PRODUCTS_PRICING_TOTALS_PENDING:
      return {
        getProductsPricingTotalsStatus: ApiRequestStates.PENDING,
      }
    case mtiOrderSummaryActions.GET_PRODUCTS_PRICING_TOTALS_FAILURE:
      return {
        getProductsPricingTotalsStatus: ApiRequestStates.ERROR,
      }
    case mtiOrderSummaryActions.GET_PRODUCTS_PRICING_TOTALS_SUCCESS:
      return {
        subtotal: action.payload.subtotal,
        tax: action.payload.tax,
        total: action.payload.total,
        getProductsPricingTotalsStatus: ApiRequestStates.SUCCESS,
      }
    case mtiOrderSummaryActions.PROCESS_ORDER_PAYMENT:
      return {
        processOrderPaymentErrorCode: null,
        processOrderPaymentErrorMessage: null,
        getProcessOrderPaymentStatus: ApiRequestStates.IDLE,
      }
    case mtiOrderSummaryActions.PROCESS_ORDER_PAYMENT_FAILURE:
      if (action.payload instanceof ProcessOrderPaymentError) {
        return {
          processOrderPaymentErrorCode: action.payload.statusCode,
          processOrderPaymentErrorMessage: action.payload.message,
          getProcessOrderPaymentStatus: ApiRequestStates.ERROR,
        }
      }
      return {
        processOrderPaymentErrorMessage: action.payload.message,
        getProcessOrderPaymentStatus: ApiRequestStates.ERROR,
      }
    case mtiOrderSummaryActions.PROCESS_ORDER_PAYMENT_PENDING:
      return {
        getProcessOrderPaymentStatus: ApiRequestStates.PENDING,
      }
    case mtiOrderSummaryActions.PROCESS_ORDER_PAYMENT_SUCCESS:
      return {
        getProcessOrderPaymentStatus: ApiRequestStates.SUCCESS,
      }
    default:
      return null
  }
})
