import {all, call, fork, put, takeLatest} from 'redux-saga/effects'

import * as paymentPortalApiClient from '../../../api/PaymentPortal/index'
import * as userApiClient from '../../../api/UsersApiClient'
import {
  getChargesSummaryTotalsFailure,
  getChargesSummaryTotalsPending,
  getChargesSummaryTotalsSuccess,
  getRefundsSummaryTotalsFailure,
  getRefundsSummaryTotalsPending,
  getRefundsSummaryTotalsSuccess,
  getUserAccountBalancePending,
  getUserAccountBalanceSuccess,
  getUserAccountBalanceFailure,
  PPSummaryAction,
} from '../../actions/PaymentPortal/summary'
import {isBadResponse} from '../../../api/helpers'
import {camelCaseObjectKeys} from '../../../utilities/formatting'

export function* getUserAccountBalanceWorker({payload: {customerId}}) {
  try {
    yield put(getUserAccountBalancePending())
    if (!customerId) {
      throw new Error('Unable to fetch user account balance. Invalid user ID provided')
    }
    const {data, status} = yield call(userApiClient.getUserAccountBalance, customerId)
    if (isBadResponse(status)) {
      throw new Error('Unable to fetch user account balance.')
    }
    yield put(getUserAccountBalanceSuccess(data))
  } catch (err) {
    yield put(getUserAccountBalanceFailure(err))
  }
}

export function* getUserAccountBalanceSaga() {
  yield takeLatest(PPSummaryAction.GET_USER_ACCOUNT_BALANCE, getUserAccountBalanceWorker)
}

export function* getChargesSummaryTotalsWorker({payload}) {
  yield put(getChargesSummaryTotalsPending())
  try {
    const {data, status} = yield call(paymentPortalApiClient.getChargesSummaryTotals, payload)
    if (isBadResponse(status)) {
      throw new Error('Unable to fetch charges summary totals.')
    }
    yield put(getChargesSummaryTotalsSuccess(camelCaseObjectKeys(data)))
  } catch (e) {
    yield put(getChargesSummaryTotalsFailure(e))
  }
}

export function* getChargesSummaryTotalsSaga() {
  yield takeLatest(PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS, getChargesSummaryTotalsWorker)
}

export function* getRefundsSummaryTotalsWorker({payload}) {
  yield put(getRefundsSummaryTotalsPending())
  try {
    const {data, status} = yield call(paymentPortalApiClient.getRefundsSummaryTotals, payload)
    if (isBadResponse(status)) {
      throw new Error('Unable to fetch refunds summary totals.')
    }
    yield put(getRefundsSummaryTotalsSuccess(camelCaseObjectKeys(data)))
  } catch (e) {
    yield put(getRefundsSummaryTotalsFailure(e))
  }
}

export function* getRefundsSummaryTotalsSaga() {
  yield takeLatest(PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS, getRefundsSummaryTotalsWorker)
}

export default function* paymentPortalSummarySagas() {
  yield all([fork(getChargesSummaryTotalsSaga), fork(getRefundsSummaryTotalsSaga), fork(getUserAccountBalanceSaga)])
}
