import {createSelector, Selector} from 'reselect'

import {RootState} from '../../../store'
import {RefundType, TransactionItem, TransactionReason, TransactionType} from '../../../types'
import {transactionType} from './transactionType'
import {transactionItems} from './transactions'

export const activeRefundType: Selector<RootState, RefundType | undefined> = createSelector(
  transactionType,
  transactionItems,
  ($transactionType: TransactionType, $transactionItems: TransactionItem[]) => {
    if ($transactionType === TransactionType.Charges) {
      return undefined
    }

    if ($transactionItems.length === 0) {
      return undefined
    }

    // We are making the assumption here that all the values selected are of one type
    // when dealing with refunds. If and when we support processing mixed refund types,
    // we'll need to revisit this logic
    const isTransactionRefundType = $transactionItems.every(({reason}) =>
      [
        TransactionReason.Product,
        TransactionReason.AssetRecovery,
        TransactionReason.RentToKeep,
        TransactionReason.AccountBalanceCredit,
      ].includes(reason),
    )

    return isTransactionRefundType ? RefundType.Transaction : RefundType.Reimbursement
  },
)
