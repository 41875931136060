import {
  PPTransactionTypeAction,
  SetTransactionTypeToCharges,
  SetTransactionTypeToRefunds,
} from '../../actions/PaymentPortal/transactionType'
import {TransactionType} from '../../../types'

type State = TransactionType | null

type TransactionTypeAction = SetTransactionTypeToCharges | SetTransactionTypeToRefunds

const transactionType = (state: State = null, action: TransactionTypeAction): State | null => {
  switch (action.type) {
    case PPTransactionTypeAction.SetTransactionTypeCharges:
      return TransactionType.Charges

    case PPTransactionTypeAction.SetTransactionTypeRefunds:
      return TransactionType.Refunds

    default:
      return state
  }
}

export default transactionType
