import * as reasonActions from '../../actions/PaymentPortal/reasons'
import {reducer} from '../helpers'

import {ApiRequestStates} from '../../../api/const'
import {TransactionMetadata} from '../../../types'
export interface TransactionMetadataState {
  metadata: TransactionMetadata | null
  requestState: ApiRequestStates
}

const initialState: TransactionMetadataState = {
  metadata: null,
  requestState: ApiRequestStates.IDLE,
}

type TransactionMetadataAction =
  | reasonActions.GetDataForReasonPendingAction
  | reasonActions.GetDataForReasonSuccessAction
  | reasonActions.GetDataForReasonFailureAction

export default reducer(
  initialState,
  (state: TransactionMetadataState, action: TransactionMetadataAction): Partial<TransactionMetadataState> | null => {
    switch (action.type) {
      case reasonActions.PaymentPortalReasonsActionName.GetDataForReasonPending:
        return {
          requestState: ApiRequestStates.PENDING,
        }
      case reasonActions.PaymentPortalReasonsActionName.GetDataForReasonFailure:
        return {
          requestState: ApiRequestStates.ERROR,
        }
      case reasonActions.PaymentPortalReasonsActionName.GetDataForReasonSuccess:
        const {metadata} = action.payload
        return {requestState: ApiRequestStates.SUCCESS, metadata}
      default:
        return null
    }
  },
)
