import get from 'lodash/get'
import {createSelector, Selector} from 'reselect'

import {ApiRequestStates} from '../../../api/const'
import {RootState} from '../../../store'
import {SummaryState} from '../../reducers/PaymentPortal/summary'

export const summary: Selector<RootState, SummaryState> = (state) => get(state, 'paymentPortal.summary')
export const accountBalance: Selector<RootState, number> = (state) => get(summary(state), 'accountBalance')
export const accountBalanceToApply: Selector<RootState, number> = (state) =>
  get(summary(state), 'accountBalanceToApply')
export const hasAppliedAccountBalance: Selector<RootState, boolean> = (state) =>
  get(summary(state), 'hasAppliedAccountBalance') || false
export const balanceDue: Selector<RootState, number> = (state) => get(summary(state), 'balanceDue')
export const subtotal: Selector<RootState, number> = (state) => get(summary(state), 'subtotal')
export const tax: Selector<RootState, number> = (state) => get(summary(state), 'tax')
export const total: Selector<RootState, number> = (state) => get(summary(state), 'total')
export const getSummaryTotalsStatus: Selector<RootState, string> = (state) =>
  get(summary(state), 'getSummaryTotalsStatus')

export const isGetSummaryTotalsPending: Selector<RootState, boolean> = createSelector(
  getSummaryTotalsStatus,
  (status) => status === ApiRequestStates.PENDING,
)
export const isGetSummaryTotalsFailure: Selector<RootState, boolean> = createSelector(
  getSummaryTotalsStatus,
  (status) => status === ApiRequestStates.ERROR,
)
