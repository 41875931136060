import {adminSaga} from 'react-admin'
import {all, fork} from 'redux-saga/effects'
import defaultMessages from 'ra-language-english'

import formsSagas from './forms'
import mtiSagas from './MTI/index'
import ordersSagas from './orders'
import paymentPortalSagas from './PaymentPortal/index'
import worldpaySagas from './worldpay'

export default function rootSaga(dataProvider) {
  return function* generatorRootSaga() {
    yield all([
      fork(
        adminSaga(
          dataProvider,
          () => Promise.resolve(),
          () => defaultMessages,
        ),
      ),
      fork(formsSagas),
      fork(mtiSagas),
      fork(ordersSagas),
      fork(paymentPortalSagas),
      fork(worldpaySagas),
    ])
  }
}
