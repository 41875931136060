import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function ToolTimeFloatLeftRight({className, floatContentLeft, floatContentRight, ...props}) {
  const classes = classNames(className, ['d-flex', 'justify-content-between'])

  return (
    <div {...props} className={classes}>
      {floatContentLeft}
      {floatContentRight && <div className="ml-auto">{floatContentRight}</div>}
    </div>
  )
}

ToolTimeFloatLeftRight.defaultProps = {
  className: undefined,
  floatContentLeft: undefined,
  floatContentRight: undefined,
}

ToolTimeFloatLeftRight.propTypes = {
  className: PropTypes.string,
  floatContentLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  floatContentRight: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
}

export default ToolTimeFloatLeftRight
