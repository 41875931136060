import * as actions from '../constants/ActionTypes'

export function sizeQueryLoading() {
  return {
    type: actions.SIZE_QUERY_LOADING,
  }
}

export function sizeQueryError(error) {
  return {
    type: actions.SIZE_QUERY_ERROR,
    error,
  }
}

export function sizeQuerySuccess(data) {
  return {
    type: actions.SIZE_QUERY_SUCCESS,
    data: data.data,
  }
}

export function editSurvey() {
  return {
    type: actions.EDIT_SURVEY,
  }
}

export function resetEditableState() {
  return {
    type: actions.RESET_EDITABLE_STATE,
  }
}

export function sizeMutationSuccess(data) {
  return {
    type: actions.ASSIGNED_SIZES_MUTATION_SUCCESS,
    data,
  }
}

export function surveyMutationSuccess(data) {
  return {
    type: actions.SURVEY_MUTATION_SUCCESS,
    data,
  }
}

export function emailError() {
  return {
    type: actions.EMAIL_ERROR,
    error: 'There was a problem sending the size changed email',
  }
}
