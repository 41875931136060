import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import * as en from '../../constants/enums'
import * as store from '../../constants/store'
import {createFieldOptions} from '../../constants/lib'
import SelectNumberField from './SelectNumberField'

function surveySelectField({validators, options, ...props}) {
  const apiName = en.survey.convertToApiField(props.formFieldName)
  const isRequired = validators && validators.required.includes(apiName)
  const fieldOptions = createFieldOptions(options[apiName], isRequired)
  return <SelectNumberField {...props} options={fieldOptions} readOnly={props.formIsReadOnly} />
}

function mapStateToPropsSurvey(state) {
  return {
    options: store.surveyFormFields(state),
    formIsReadOnly: store.surveyIsDisabled(state),
    validators: store.surveyValidators(state),
  }
}

surveySelectField.propTypes = {
  options: PropTypes.object.isRequired,
  formIsReadOnly: PropTypes.bool.isRequired,
  formFieldName: PropTypes.string.isRequired,
  validators: PropTypes.object.isRequired,
}

export const SurveySelectField = connect(mapStateToPropsSurvey)(surveySelectField)

function assignedSizesSelectField({validators, options, ...props}) {
  const apiName = en.assignedSizes.convertToApiField(props.formFieldName)
  const isRequired = validators && validators.required.includes(apiName)
  const fieldOptions = createFieldOptions(options[apiName], isRequired)
  return <SelectNumberField {...props} options={fieldOptions} disabled={props.formIsDisabled} />
}

function mapStateToPropsAssigned(state) {
  return {
    options: store.sizeFormFields(state),
    formIsDisabled: store.assignedSizesIsDisabled(state),
    validators: store.assignedSizeValidators(state),
  }
}

assignedSizesSelectField.propTypes = {
  options: PropTypes.object.isRequired,
  formIsDisabled: PropTypes.bool.isRequired,
  formFieldName: PropTypes.string.isRequired,
  validators: PropTypes.object.isRequired,
}

export const AssignedSizesSelectField = connect(mapStateToPropsAssigned)(assignedSizesSelectField)
