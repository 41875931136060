import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'

import {submitSurvey, submitAssignedSizes} from '../actions/ajax'
import * as store from '../constants/store'

import Sizes from './SizesForm'
import UserProfileNav from './UserProfileNav'

class SizesPage extends Component {
  constructor(props) {
    super(props)

    this.mutation = this.mutation.bind(this)
  }

  getMutationVariables() {
    const {session, user} = this.props
    const variables = {
      authToken: session.get('token', false),
      userId: user && user.userId,
    }
    return variables
  }

  mutation(mutationFunction) {
    return (input) => {
      const variables = this.getMutationVariables()
      const mutationInput = {...input, ...variables}
      return mutationFunction({input: mutationInput, authToken: variables.authToken})
    }
  }

  render() {
    return (
      <section>
        <UserProfileNav user={this.props.user || {}} />
        {this.props.user ? (
          <Sizes
            submitSurvey={this.mutation(this.props.submitSurvey)}
            submitAssignedSizes={this.mutation(this.props.submitAssignedSizes)}
          />
        ) : null}
      </section>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submitSurvey: (input) => submitSurvey(dispatch)(input),
    submitAssignedSizes: (input) => submitAssignedSizes(dispatch)(input),
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
    user: store.user(state),
  }
}

SizesPage.propTypes = {
  user: PropTypes.object.isRequired,
  submitSurvey: PropTypes.func.isRequired,
  submitAssignedSizes: PropTypes.func.isRequired,
  session: PropTypes.shape({
    session: PropTypes.string,
  }).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SizesPage)
