import {reducer} from '../helpers'
import {
  PPCustomerAction,
  GetCustomerInfo,
  GetCustomerInfoFailure,
  GetCustomerInfoPending,
  GetCustomerInfoSuccess,
} from '../../actions/PaymentPortal/customer'
import {ApiRequestStates} from '../../../api/const'
import {Customer} from '../../../types'
import {WorldpayAction, GetWorldpayInfoSuccess} from '../../actions/worldpay'

interface RequestStatus {
  getCustomerInfoStatus: ApiRequestStates
}

type State = Customer & RequestStatus

const initialState: State = {
  email: '',
  firstName: null,
  lastName: null,
  id: null,
  last4CreditCardDigits: '',
  getCustomerInfoStatus: ApiRequestStates.IDLE,
}

type CustomerAction =
  | GetCustomerInfo
  | GetCustomerInfoFailure
  | GetCustomerInfoPending
  | GetCustomerInfoSuccess
  | GetWorldpayInfoSuccess

export default reducer(initialState, (state: State, action: CustomerAction): Partial<State> | null => {
  switch (action.type) {
    case PPCustomerAction.GET_CUSTOMER_INFO:
      return {
        ...initialState,
      }
    case PPCustomerAction.GET_CUSTOMER_INFO_FAILURE:
      return {
        getCustomerInfoStatus: ApiRequestStates.ERROR,
      }
    case PPCustomerAction.GET_CUSTOMER_INFO_PENDING:
      return {
        getCustomerInfoStatus: ApiRequestStates.PENDING,
      }
    case PPCustomerAction.GET_CUSTOMER_INFO_SUCCESS:
      const {email, firstName, lastName, id, last4CreditCardDigits} = action.payload

      return {
        email,
        firstName,
        lastName,
        id,
        last4CreditCardDigits,
        getCustomerInfoStatus: ApiRequestStates.SUCCESS,
      }
    case WorldpayAction.GET_WORLDPAY_INFO_SUCCESS:
      return {
        ...state,
        last4CreditCardDigits: action.payload.lastFour,
      }

    default:
      return null
  }
})
