import axios from 'axios'
import qs from 'qs'

import {camelCaseObjectKeys} from '../utilities/formatting'
import {constructOptions, constructOptionsAndValidateStatus} from './utils'

export const ordersMonolithRestApi = axios.create({
  baseURL: `${window.__RUNTIME_CONFIG__.REACT_APP_MONOLITH_REST_API_URL}/orders`,
})

export const ordersMonolithApi = axios.create({
  baseURL: `${window.__RUNTIME_CONFIG__.REACT_APP_MONOLITH_URL}`,
})

export const sendOrderConfirmationEmail = (orderId) =>
  ordersMonolithRestApi.post(`/${orderId}/send-order-confirmation-email/`, {}, constructOptions())

export const getOrder = (orderId) => ordersMonolithRestApi.get(`/${orderId}/`, constructOptions())

export const getOrderInfo = (orderId) =>
  ordersMonolithApi.get(`/transactioncomposite/orders/${orderId}`, constructOptions()).then(camelCaseObjectKeys)

export const getProductsByOrderId = (orderId) =>
  ordersMonolithApi
    .get(
      `/v1/orders/${orderId}/items${qs.stringify({'single-products': 'True'}, {addQueryPrefix: true})}`,
      constructOptions(),
    )
    .then(camelCaseObjectKeys)

export const processOrderPayment = (orderId, payload) =>
  ordersMonolithApi
    .post(`/v1/orders/${orderId}/add-items`, payload, constructOptionsAndValidateStatus())
    .then(camelCaseObjectKeys)
