import {fromJS} from 'immutable'
import {FETCH_AUTH_INF_TOKEN, FETCH_AUTH_JWT_TOKEN, RECEIVE_AUTH_JWT_TOKEN, DELETE_AUTH_JWT_TOKEN} from '../constants/ActionTypes'

const INITIAL_STATE = fromJS({
  fetchAuthInfToken: false,
  fetchAuthJwtToken: false,
  receiveAuthJwtToken: false,
  token: null,
})

function sessionReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case FETCH_AUTH_INF_TOKEN:
      return state.merge(
        fromJS({
          fetchAuthInfToken: true,
          fetchAuthJwtToken: false,
          receiveAuthJwtToken: false,
          token: null,
        }),
      )

    case FETCH_AUTH_JWT_TOKEN:
      return state.merge(
        fromJS({
          fetchAuthInfToken: false,
          fetchAuthJwtToken: true,
          receiveAuthJwtToken: false,
          token: null,
        }),
      )

    case RECEIVE_AUTH_JWT_TOKEN:
      return state.merge(
        fromJS({
          fetchAuthInfToken: false,
          fetchAuthJwtToken: false,
          receiveAuthJwtToken: true,
          token: action.data.key,
        }),
      )

    case DELETE_AUTH_JWT_TOKEN:
      return state.merge(
        fromJS({
          fetchAuthInfToken: false,
          fetchAuthJwtToken: false,
          receiveAuthJwtToken: false,
          token: null,
        }),
      )

    default:
      return state
  }
}

export default sessionReducer
