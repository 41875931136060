import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import * as store from '../constants/store'
import {formatDateTime} from '../../../utils/dateTime'
import {FourColumnRow} from './ReusableComponents/FormattedRows'
import {valueToLabel} from '../constants/lib'
import {FITPROFILE_MEASURED_AT_MAP} from '../constants/enums'

function AuditFields({assignedSizes, survey, reasonMap}) {
  const sizes = assignedSizes
  const sizeUpdatedViaLabel = sizes.reason && valueToLabel(reasonMap)(sizes.reason)
  const surveyEmail = survey.createdBy && survey.createdBy.email
  const assignedSizesEmail = assignedSizes.createdBy && assignedSizes.createdBy.email
  const updatedVia = sizeUpdatedViaLabel || 'N/A'
  const measuredAt = FITPROFILE_MEASURED_AT_MAP[survey.measuredAt]

  return (
    <section className="sp-audit-fields">
      <FourColumnRow
        col2={`Updated By: ${surveyEmail || 'N/A'} on ${formatDateTime(survey.createdOn)}`}
        col4={
          <div>
            <div>{`Updated By: ${assignedSizesEmail || 'N/A'} on ${formatDateTime(sizes.createdOn)}`}</div>
            <div>{`Via: ${updatedVia}`}</div>
            {measuredAt && <div>In-store fitting partner: {measuredAt}</div>}
          </div>
        }
      />
    </section>
  )
}

function mapStateToProps(state) {
  return {
    assignedSizes: store.assignedSizes(state) || undefined,
    survey: store.survey(state) || undefined,
    reasonMap: store.sizeFormFields(state) && store.sizeFormFields(state).reason,
  }
}

AuditFields.defaultProps = {
  assignedSizes: {
    createdBy: {email: 'N/A'},
    createdOn: null,
    reason: null,
  },
  survey: {
    createdBy: {email: 'N/A'},
    createdOn: null,
  },
}

AuditFields.propTypes = {
  assignedSizes: PropTypes.shape({
    createdBy: PropTypes.shape({
      email: PropTypes.string,
    }),
    createdOn: PropTypes.string,
    reason: PropTypes.number,
  }),
  survey: PropTypes.shape({
    createdBy: PropTypes.shape({
      email: PropTypes.string,
    }),
    createdOn: PropTypes.string,
  }),
  reasonMap: PropTypes.array.isRequired,
}

export default connect(mapStateToProps)(AuditFields)
