import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import size from 'lodash/size'

export const FIT_TYPE_LABEL_MAP = {
  C: 'Classic',
  S: 'Slim',
  XS: 'Extra Slim',
}

/**
 * Helper function to create formatted jacket size string label.
 *
 * @param {Object} sizeConfig Config containing values of jacket dimensions
 * @param {Number} sizeConfig.chest Value of jacket's body size
 * @param {Number} sizeConfig.jacketLength Value of jacket length
 * @returns {String} Formatted jacket size label (eg: "42 R")
 */
export const formatJacketSizeLabel = ({chest, jacketLength}) => chest && jacketLength && `${chest} ${jacketLength}`

/**
 * Helper function to create formatted pants size string label.
 *
 * @param {Object} sizeConfig Config containing values of pants dimensions
 * @param {Number} sizeConfig.inseam Value of pants' inseam length
 * @param {String} sizeConfig.pantFit Value of pants' fit type ('C', 'S' or 'XS')
 * @param {Number} sizeConfig.waist Value of pants' waist circumference
 * @returns {String} Formatted pants size label (eg: "34x32 Classic")
 */
export const formatPantsSizeLabel = ({inseam, pantFit, waist}) =>
  waist && inseam && pantFit && `${waist}x${inseam} ${FIT_TYPE_LABEL_MAP[pantFit]}`

/**
 * Helper function to create formatted shirt size string label.
 *
 * @param {Object} sizeConfig Config containing values of shirt dimensions
 * @param {Number} sizeConfig.neck Value of shirt's neck circumference
 * @param {String} sizeConfig.shirtFit Value of shirt's fit type ('C', 'S' or 'XS')
 * @param {Number} sizeConfig.shirtSleeve Value of shirt's sleeve length
 * @returns {String} Formatted shirt size label (eg: "16/32 Slim")
 */
export const formatShirtsSizeLabel = ({neck, shirtFit, shirtSleeve}) =>
  neck && shirtSleeve && shirtFit && `${neck}/${shirtSleeve} ${FIT_TYPE_LABEL_MAP[shirtFit]}`

/**
 * Helper function to create formatted vest size string label.
 *
 * @param {Object} sizeConfig Config containing values of vest dimensions
 * @param {String} sizeConfig.vest Value of vest's body size (eg: 'XS', 'S', 'M', 'XXL')
 * @param {String} sizeConfig.vestLength Value of vest's length (eg: 'R', 'L')
 * @returns {String} Formatted vest size label (eg: "XSR")
 */
export const formatVestSizeLabel = ({vest, vestLength = ''}) => vest && `${vest}${vestLength}`

/**
 * Helper function that formats an item's size config into the conventional sizing display format based on the item's
 * product category type.
 *
 * @param {Object} sizeConfig Config object containing values of the item's dimensions
 * @param {String} productCategory Item's garment category (eg: 'jackets', 'pants')
 * @returns {String} Combined string representation of an item's sizing dimensions
 */
export const formatProductSizeLabelByCategory = (sizeConfig, productCategory) => {
  if (isNil(sizeConfig) || isEmpty(sizeConfig)) {
    return 'N/A'
  }

  let productSizeLabel

  switch (productCategory) {
    case 'jackets':
      productSizeLabel = formatJacketSizeLabel(sizeConfig)
      break

    case 'pants':
      productSizeLabel = formatPantsSizeLabel(sizeConfig)
      break

    case 'shirts':
      productSizeLabel = formatShirtsSizeLabel(sizeConfig)
      break

    case 'vests':
      productSizeLabel = formatVestSizeLabel(sizeConfig)
      break

    case 'shoes':
      productSizeLabel = sizeConfig.shoe && `${sizeConfig.shoe}`
      break

    case 'belts':
      productSizeLabel = sizeConfig.belt && `${sizeConfig.belt}`
      break

    default:
      // check if product has only one size
      if (sizeConfig.oneSize) {
        return 'One Size'
      }

      break
  }

  return productSizeLabel || 'TBD'
}

/**
 * Helper function to return the user size object by category slug.
 *
 * @param {Object} relatedProduct Object containing a product's details (eg: categorySlug, skus)
 * @param {Array} userSizes Array of objects containing the category slug and size string
 * @returns {Object} The user size object of the corresponding category slug
 */
export const getUserSizeByProductCategorySlug = (relatedProduct, userSizes) =>
  find(userSizes, {categorySlug: relatedProduct.categorySlug})

/**
 * Helper function to return the user's size by category slug if they have one.
 *
 * @param {Object} relatedProduct Object containing a product's details (eg: categorySlug, skus)
 * @param {Array} userSizes Array of objects containing the category slug and size string
 * @returns {String} The size of the corresponding category slug
 */
export const getUserSizeByCategorySlug = (relatedProduct, userSizes) => {
  const userSizeByCategorySlug = getUserSizeByProductCategorySlug(relatedProduct, userSizes)
  // category slugs with "One Size", such as "accessory", do not have a corresponding user size object
  if (!userSizeByCategorySlug) {
    return 'One Size'
  }
  // user has no size associated with the category slug
  if (userSizeByCategorySlug.size === null) {
    return 'TBD'
  }
  return userSizeByCategorySlug.size
}

/**
 * Helper function to check if a product is a rent item and is "One Size".
 *
 * @param {Array} skus Array of objects containing the itemType and skusizeSlug
 * @returns {Boolean} Returns true if the product has a rent item type and is "One Size"
 */
export const isProductRentItemAndOneSize = (skus) => !!size(filter(skus, {itemType: 'rent', skusizeSlug: 'OS'}))

/**
 * Helper function to check if the user's size exists in the product skus array by category slug.
 *
 * @param {String} categorySlug Product category slug
 * @param {Array} skus Array of objects containing the itemType and skusizeSlug
 * @param {Array} userSizes Array of objects containing the category slug and size string
 * @returns {Boolean} Returns true if the product carries the user's size
 */
export const isProductRentItemAndHasUserSize = (categorySlug, skus, userSizes) =>
  userSizes.some(
    (sizeObj) =>
      isEqual(sizeObj.categorySlug, categorySlug) && find(skus, {itemType: 'rent', skusizeSlug: sizeObj.size}),
  )

/**
 * Helper function to return the sku code associated with the user's size.
 *
 * @param {Object} relatedProduct Object containing a product's details (eg: categorySlug, skus)
 * @param {Array} userSizes Array of objects containing the category slug and size string
 * @returns {String} Returns the sku code of a product
 */
export const getSkuCode = (relatedProduct, userSizes) => {
  const userSizeByCategorySlug = getUserSizeByProductCategorySlug(relatedProduct, userSizes)
  // category slugs with "One Size", such as "accessory", do not have a corresponding user size object
  const productSku = find(relatedProduct.skus, {
    itemType: 'rent',
    skusizeSlug: !userSizeByCategorySlug ? 'OS' : userSizeByCategorySlug.size,
  })
  return productSku.code
}

export const isValidZip = (value) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
