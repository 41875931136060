import React, {Component} from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import Dragula from 'react-dragula'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import AddProductImageButton from '../../buttons/AddProductImageButton'
import DeleteProductImageButton from '../../buttons/DeleteProductImageButton'
import SaveImagePositionsButton from '../../buttons/SaveImagePositionsButton'

import '../../product-sets.css'

const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'row',
  },
  addProductImageButton: {
    marginLeft: 16,
  },
  imagePosition: {
    display: 'inline-block',
  },
  noImagesActions: {
    display: 'flex',
    flexDirection: 'column',
  },
  deleteButton: {
    marginLeft: 'auto',
    marginRight: 40,
  },
  noImages: {
    padding: '24px 0',
  },
}

class ProductImagesTab extends Component {
  static propTypes = {
    record: PropTypes.shape({
      name: PropTypes.string.isRequired,
      productSetId: PropTypes.string.isRequired,
      setImages: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    setImages: PropTypes.arrayOf(PropTypes.shape({})),
  }

  constructor(props) {
    super(props)
    this.state = {setImages: []}
    this.handleDragDrop = this.handleDragDrop.bind(this)
    this.dragulaDecorator = this.dragulaDecorator.bind(this)
  }

  componentDidMount() {
    this.setState({setImages: this.props.record.setImages})
  }

  handleDragDrop() {
    const imageWrappers = document.getElementsByClassName('product-image-wrapper')
    const updatedPositions = map(imageWrappers, (wrapperDOM, index) => ({
      id: wrapperDOM.id,
      position: index + 1,
      url: wrapperDOM.getElementsByTagName('meta')[0].getAttribute('content'),
    }))
    updatedPositions.pop()
    this.setState({setImages: updatedPositions})
  }

  dragulaDecorator(componentBackingInstance) {
    if (componentBackingInstance) {
      const options = {direction: 'vertical'}
      const dragula = Dragula([componentBackingInstance], options)
      dragula.on('drop', this.handleDragDrop)
    }
  }

  render() {
    const {classes, record} = this.props
    const {setImages} = this.state
    const {name, productSetId} = record
    const hasImages = !isEmpty(this.state.setImages)

    return (
      <div className="container" ref={this.dragulaDecorator}>
        {map(setImages, (image) => (
          <div className="product-image-wrapper" key={image.id} id={image.id}>
            <meta className="image-url" property="imageUrl" content={image.url} />
            <img className="product-image" src={`${image.url}?width=200`} alt="" />
            <Typography className={classes.imagePosition} component="p">
              {name} - {image.position}
            </Typography>
            <DeleteProductImageButton className={classes.deleteButton} productId={productSetId} imageId={image.id} />
          </div>
        ))}
        <div className={hasImages ? classes.actions : classes.noImagesActions}>
          {hasImages ? (
            <SaveImagePositionsButton productId={productSetId} setImages={this.state.setImages} />
          ) : (
            <Typography className={classes.noImages} component="p">
              This product set has no images.
            </Typography>
          )}
          <AddProductImageButton className={hasImages ? classes.addProductImageButton : ''} record={record} />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ProductImagesTab)
