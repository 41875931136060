// Flux Standard Actions
// https://github.com/acdlite/flux-standard-action
// ----------------------------------------------------------------------------

import zipObject from 'lodash/zipObject'

// Nullary actions that only have a "type" property
export const fsa = (type) => () => ({type})

// Unary actions that carry a payload as well
export const fsaPayload = (type) => (payload) => ({
  payload,
  type,
})

// Plenary actions that have defined property names within the payload
// For example: fsaPayloadByKeys(UPDATE, ['email', 'name']) becomes
// (email, name) => ({payload: {email, name}, type: UPDATE})
export const fsaPayloadByKeys =
  (type, keys) =>
  (...args) => ({
    payload: zipObject(keys, args),
    type,
  })

// Unary actions classified as errors
// `payload` should be type Error
export const fsaError = (type) => (payload) => ({
  error: true,
  payload,
  type,
})
