import {get} from 'lodash'
import {createSelector} from 'reselect'

import loadUserProfile from '../../legacy/utils/loadUserProfile'

export const session = (state) => get(state, 'session')

export const isLoggedIn = createSelector(session, (sessionState) => !!loadUserProfile(sessionState.get('token', false)))

export const adminEmail = createSelector(session, (sessionState) => {
  const {username} = loadUserProfile(sessionState.get('token', false))
  return username
})
