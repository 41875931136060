import {all, fork} from 'redux-saga/effects'

import paymentPortalCustomerSagas from './customer'
import paymentPortalOrderSagas from './order'
import paymentPortalProcessPaymentSagas from './processPayment'
import paymentPortalReasonsSagas from './reasons'
import paymentPortalSummarySagas from './summary'
import paymentPortalTransactionItemsSagas from './transactionItems'

export default function* paymentPortalSagas() {
  yield all([
    fork(paymentPortalCustomerSagas),
    fork(paymentPortalOrderSagas),
    fork(paymentPortalProcessPaymentSagas),
    fork(paymentPortalReasonsSagas),
    fork(paymentPortalSummarySagas),
    fork(paymentPortalTransactionItemsSagas),
  ])
}
