import {fsa, fsaError, fsaPayload} from '../helpers'

export const GET_PRODUCTS_PRICING_TOTALS_PENDING = 'mti/get products pricing totals pending'
export const getProductsPricingTotalsPending = fsa(GET_PRODUCTS_PRICING_TOTALS_PENDING)

export const GET_PRODUCTS_PRICING_TOTALS_FAILURE = 'mti/get products pricing totals failed'
export const getProductsPricingTotalsFailure = fsaError(GET_PRODUCTS_PRICING_TOTALS_FAILURE)

/**
 * @param {String[]}  payload           Object containing pricing data for fetched products.
 * @param {Number}    payload.subtotal  Subottal cost of fetched products.
 * @param {Number}    payload.tax       Tax cost of fetched products.
 * @param {Number}    payload.total     Total cost of fetched products.
 */
export const GET_PRODUCTS_PRICING_TOTALS_SUCCESS = 'mti/get products pricing totals succeeded'
export const getProductsPricingTotalsSuccess = fsaPayload(GET_PRODUCTS_PRICING_TOTALS_SUCCESS)

export const PROCESS_ORDER_PAYMENT_FAILURE = 'mti/process order payment failed'
export const processOrderPaymentFailure = fsaError(PROCESS_ORDER_PAYMENT_FAILURE)

export const PROCESS_ORDER_PAYMENT = 'mti/process order payment'
export const processOrderPayment = fsa(PROCESS_ORDER_PAYMENT)

export const PROCESS_ORDER_PAYMENT_PENDING = 'mti/process order payment pending'
export const processOrderPaymentPending = fsa(PROCESS_ORDER_PAYMENT_PENDING)

export const PROCESS_ORDER_PAYMENT_SUCCESS = 'mti/process order payment succeeded'
export const processOrderPaymentSuccess = fsa(PROCESS_ORDER_PAYMENT_SUCCESS)
