import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {flowRight, isEmpty} from 'lodash'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import ActionPublishButton from '@material-ui/icons/Done'
import Typography from '@material-ui/core/Typography'

import {publishProduct as publishProductAction} from '../../../actions/product'

// This is the same exact validation that lives in the product-service in order to publish a product-set.
// Publishing means that it will be visible on the collections page
const isPublishValidation = ({
  setChildren,
  setImages,
  setSpecs: {additionalBullet1, additionalBullet2, additionalBullet3, buttons, material, seoTag, sizing},
  setType,
}) => {
  const setChildrenCheck = setType === 'single' || !isEmpty(setChildren)
  return (
    seoTag &&
    sizing &&
    !isEmpty(setImages) &&
    setChildrenCheck &&
    (buttons || material || additionalBullet1 || additionalBullet2 || additionalBullet3)
  )
}

class PublishProductButton extends Component {
  static propTypes = {
    publishProduct: PropTypes.func.isRequired,
    record: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isPublished: PropTypes.bool,
    }),
  }

  handleClick = () => {
    const {
      record: {id},
      publishProduct,
    } = this.props
    publishProduct(id)
  }

  render() {
    const canPublish = !isPublishValidation(this.props.record)
    const {classes} = this.props

    return this.props.record.isPublished ? (
      <Paper className={classes.paper} zDepth={1} rounded={false}>
        <Typography className={classes.published} component="p">
          This product is published and live
        </Typography>
      </Paper>
    ) : (
      <Button color="primary" disabled={canPublish} onClick={this.handleClick} variant="contained">
        <ActionPublishButton className={classes.publishIcon} />
        Publish Product
      </Button>
    )
  }
}

export default flowRight(
  connect(null, {publishProduct: publishProductAction}),
  withStyles((theme) => ({
    paper: {
      display: 'inline-block',
      padding: 10,
      textAlign: 'center',
      width: 300,
    },
    published: {
      color: 'green',
      fontSize: 16,
      fontWeight: 'bold',
    },
    publishIcon: {
      marginRight: theme.spacing.unit,
    },
  })),
)(PublishProductButton)
