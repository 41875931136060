import React from 'react'
import {Create, TabbedForm} from 'react-admin'

import {createValidation} from './tabs/create/createValidation'
import {ProductDetailsTab, ProductSpecsTab} from './tabs/create'

const ProductSetCreate = (props) => (
  <Create {...props} title="Create Product Set">
    <TabbedForm validate={createValidation}>
      {ProductDetailsTab()}
      {ProductSpecsTab()}
    </TabbedForm>
  </Create>
)

export default ProductSetCreate
