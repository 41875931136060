import moment from 'moment'

/**
 * Takes a date in `YYYY-MM-DD` format and returns it as: `January 1, 2017` format
 *
 * @param {string} date - Date to be reformatted
 * @returns {string}
 */
function formatDate(date) {
  return moment(date).format('MMMM Do, YYYY')
}

/**
 * Returns the current date as: `Sep 5th at 12:47 PM`
 *
 * @returns {string}
 */
function formatDateAtTime() {
  return moment().format('MMM Do [at] h:mm A')
}

/**
 * Takes a date in ISO/unix format and returns it as: `June 29, 2017 at 11:22 AM`
 *
 * @param {string} ISOstring - ISOstring to be reformatted
 * @param {string} [invalidDateMessage] - Provides a default message `N/A` or override
 * @returns {string}
 */
function formatDateTime(ISOstring, invalidDateMessage = 'N/A') {
  const formattedDateTime = moment(ISOstring).format('MMMM D, YYYY [at] h:mm A')
  return formattedDateTime === 'Invalid date' ? invalidDateMessage : formattedDateTime
}

export {formatDate, formatDateAtTime, formatDateTime}
