import {useEffect} from 'react'

/**
 * A hook that will only be called once (one render)
 * @param {Function} func The function to invoke
 * @return {void}
 */
export default function useOnce(func) {
  if (typeof func !== 'function') {
    throw new Error('useOnce must be passed a function')
  }
  useEffect(func, [])
}
