import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'

import logErrorException from '../../legacy/utils/logErrorException'
import * as modalActions from '../actions/modal'
import * as mtiOrderSelectors from '../selectors/MTI/order'
import * as worldpayActions from '../actions/worldpay'
import * as worldpayResponseCodes from '../responseCodes'

/**
 * Fetches the paypageRegistrationId from worldpay
 *
 * @generator
 */
export function* getWorldpayInfoWorker() {
  yield put(worldpayActions.getWorldpayInfoPending())
  //TODO: Uncouple worldpay logic from MTI
  const customerId = yield select(mtiOrderSelectors.customerId)
  const orderId = yield select(mtiOrderSelectors.orderId)
  try {
    yield call(window.eProtectiframeClient.getPaypageRegistrationId, {
      id: `${customerId}-${orderId}`,
      orderId: orderId,
    })
  } catch (e) {
    yield put(worldpayActions.getWorldpayInfoFailure(e))
  }
}

export function* getWorldpayInfoSaga() {
  yield takeLatest(worldpayActions.WorldpayAction.GET_WORLDPAY_INFO, getWorldpayInfoWorker)
}

/**
 *  Example worldpay response object:
 *  {
 *    bin: '410020'
 *    expMonth: '05'
 *    expYear: '21'
 *    firstSix: '410020'
 *    id: '6984188-5317477941236246'
 *    lastFour: '1001'
 *    message: 'Success'
 *    orderId: '5317477941236246'
 *    paypageRegistrationId: '1777180285120938691'
 *    reportGroup: '1331863'
 *    response: '870'
 *    responseTime: '2018-01-05T01:26:47'
 *    targetServer: 'primary'
 *    type: 'VI'
 *    vantivTxnId: '82922240560907003'
 *   }
 */

/**
 * Handles the response from worldpay
 *
 * @generator
 */
export function* handleWorldPayAuthorizationWorker({payload}) {
  const {message: responseMessage, response: responseCode, timeout} = payload
  if (responseCode === worldpayResponseCodes.SUCCESS) {
    yield put(worldpayActions.getWorldpayInfoSuccess(payload))
    return
  }
  yield put(worldpayActions.getWorldpayInfoFailure({responseCode, responseMessage, timeout}))
}

export function* handleWorldPayAuthorizationSaga() {
  yield takeLatest(worldpayActions.WorldpayAction.HANDLE_WORLDPAY_AUTHORIZATION, handleWorldPayAuthorizationWorker)
}

/**
 * Handles the success response from worldpay
 *
 * @generator
 */
export function* getWorldpayInfoSuccessWorker() {
  yield put(modalActions.closeModal())
}

export function* getWorldpayInfoSuccessSaga() {
  yield takeLatest(worldpayActions.WorldpayAction.GET_WORLDPAY_INFO_SUCCESS, getWorldpayInfoSuccessWorker)
}

/**
 * Handles the error response from worldpay
 *
 * @generator
 */
export function* handleWorldPayErrorResponseWorker({payload: {responseCode, responseMessage, timeout}}) {
  if (timeout) {
    yield call(alert, 'We are experiencing technical difficulties. Please try again.')
    return
  }

  switch (responseCode) {
    case worldpayResponseCodes.ACCOUNT_NUMBER_NOT_MOD_10:
    case worldpayResponseCodes.ACCOUNT_NUMBER_TOO_SHORT:
    case worldpayResponseCodes.ACCOUNT_NUMBER_TOO_LONG:
    case worldpayResponseCodes.ACCOUNT_NUMBER_NOT_NUMERIC:
    case worldpayResponseCodes.ACCOUNT_NUMBER_INVALID:
      yield call(alert, 'Please check and re-enter the credit card number and try again.')
      break
    case worldpayResponseCodes.CARD_VALIDATION_NUM_NOT_NUMERIC:
    case worldpayResponseCodes.CARD_VALIDATION_NUM_TOO_SHORT:
    case worldpayResponseCodes.CARD_VALIDATION_NUM_TOO_LONG:
      // Recoverable error caused by user mis-typing their credit card
      yield call(alert, 'Please check and re-enter the card validation number and try again.')
      break
    case worldpayResponseCodes.IFRAME_HTML_FAILED_TO_LOAD:
      yield call(logErrorException, 'getWorldpayInfo', responseCode, responseMessage)
      break
    case worldpayResponseCodes.IFRAME_CSS_FAILED_TO_LOAD:
      yield call(logErrorException, 'getWorldpayInfo', responseCode, responseMessage)
      break
    default:
      yield call(alert, 'We are experiencing technical difficulties. Please try again.')
  }
}

export function* handleWorldPayErrorResponseSaga() {
  yield takeLatest(worldpayActions.WorldpayAction.GET_WORLDPAY_INFO_FAILURE, handleWorldPayErrorResponseWorker)
}

export default function* worldpaySagas() {
  yield all([
    fork(getWorldpayInfoSaga),
    fork(handleWorldPayAuthorizationSaga),
    fork(getWorldpayInfoSuccessSaga),
    fork(handleWorldPayErrorResponseSaga),
  ])
}
