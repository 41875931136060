import React from 'react'
import PropTypes from 'prop-types'
import {Nav, NavItem} from 'reactstrap'

import ToolTimeFloatLeftRight from './ToolTimeFloatLeftRight'
import SiteMenuOpen from './SiteMenu/SiteMenuOpen'

function ToolTimeNav({subTitle, navItems, tabsList, title, ...props}) {
  return (
    <ToolTimeFloatLeftRight
      {...props}
      floatContentLeft={
        <Nav navbar className="flex-sm-row">
          <NavItem>
            <SiteMenuOpen />
          </NavItem>
          <NavItem>
            <div className="title">{title || 'User Not Found'}</div>
          </NavItem>
          {subTitle && (
            <NavItem>
              <div className="sub-title">{subTitle}</div>
            </NavItem>
          )}
          {navItems}
        </Nav>
      }
      floatContentRight={tabsList}
    />
  )
}

ToolTimeNav.defaultProps = {
  navItems: undefined,
  subTitle: undefined,
  tabsList: undefined,
}

ToolTimeNav.propTypes = {
  navItems: PropTypes.shape({}),
  subTitle: PropTypes.string,
  tabsList: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
}

export default ToolTimeNav
