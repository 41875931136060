import {reducer} from './helpers'
import {ModalAction} from '../actions/modal'

const initialState = {
  currentId: '',
  isActive: false,
}

export default reducer(initialState, (state, {payload, type}) => {
  switch (type) {
    case ModalAction.OPEN_MODAL:
      return {
        currentId: payload.id,
        isActive: true,
      }
    case ModalAction.CLOSE_MODAL:
      return {
        currentId: '',
        isActive: false,
      }
    default:
      return null
  }
})
