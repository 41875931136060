import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'

import * as customerSelectors from '../../selectors/PaymentPortal/customer'
import * as modalActions from '../../actions/modal'
import * as ordersSelectors from '../../selectors/PaymentPortal/order'
import * as paymentPortalApiClient from '../../../api/PaymentPortal/index'
import * as reasonsActions from '../../actions/PaymentPortal/reasons'
import * as transactionTypeSelectors from '../../selectors/PaymentPortal/transactionType'
import {isGoodResponse} from '../../../api/helpers'
import {TransactionType} from '../../../types'

export function* setActiveReasonSagaWorker({payload: {reason}}) {
  yield put(modalActions.openPaymentPortalReasonModal())
  yield put(reasonsActions.getDataForReasonPending())

  try {
    const eventId = yield select(ordersSelectors.eventId)
    const customerId = yield select(customerSelectors.customerId)
    const transactionType = yield select(transactionTypeSelectors.transactionType)
    const {data, status} =
      transactionType === TransactionType.Charges
        ? yield call(paymentPortalApiClient.getOrdersRelatedToReason, customerId, eventId, reason)
        : yield call(paymentPortalApiClient.getAllOrders, eventId, reason)

    if (isGoodResponse(status)) {
      const payload = {metadata: data}
      yield put(reasonsActions.getDataForReasonSuccess(payload))
    } else {
      throw new Error(`Unable to fetch orders related to ${reason} for event ${eventId}.`)
    }
  } catch (e) {
    yield put(reasonsActions.getDataForReasonFailure(e))
  }
}

export function* setActiveReasonSaga() {
  yield takeLatest(reasonsActions.PaymentPortalReasonsActionName.SetActiveReason, setActiveReasonSagaWorker)
}

export function* getEligibleTransactionReasonsWorker({payload: {orderId}}) {
  yield put(reasonsActions.getEligibleTransactionReasonsPending())
  try {
    const {data, status} = yield call(paymentPortalApiClient.getEligibleTransactionReasons, orderId)
    if (isGoodResponse(status)) {
      yield put(reasonsActions.getEligibleTransactionReasonsSuccess(data.reasons))
    } else {
      throw new Error('Unable to fetch eligible transaction reasons.')
    }
  } catch (e) {
    yield put(reasonsActions.getEligibleTransactionReasonsFailure(e))
  }
}

export function* getEligibleTransactionReasonsSaga() {
  yield takeLatest(
    reasonsActions.PaymentPortalReasonsActionName.GetEligibleTransactionReasons,
    getEligibleTransactionReasonsWorker,
  )
}

export default function* paymentPortalReasonsSagas() {
  yield all([fork(setActiveReasonSaga), fork(getEligibleTransactionReasonsSaga)])
}
