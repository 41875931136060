import React from 'react'
import {FormTab} from 'react-admin'

import ProductImage from './ProductImage'

const ProductImagesTab = () => (
  <FormTab label="Product Images">
    <ProductImage />
  </FormTab>
)

export default ProductImagesTab
