/**
 * @see {@link https://developer.vantiv.com/docs/DOC-1203}
 *
 */
export const SUCCESS = '870'
export const ACCOUNT_NUMBER_NOT_MOD_10 = '871'
export const ACCOUNT_NUMBER_TOO_SHORT = '872'
export const ACCOUNT_NUMBER_TOO_LONG = '873'
export const ACCOUNT_NUMBER_NOT_NUMERIC = '874'
export const ACCOUNT_NUMBER_INVALID = '876'
export const CARD_VALIDATION_NUM_NOT_NUMERIC = '881'
export const CARD_VALIDATION_NUM_TOO_SHORT = '882'
export const CARD_VALIDATION_NUM_TOO_LONG = '883'
export const IFRAME_HTML_FAILED_TO_LOAD = '884'
export const IFRAME_CSS_FAILED_TO_LOAD = '885'
