export const getGraphQLHost = () => window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_SERVICE_URL

export const getHeaders = () => {
  const graphqlLocalHost = 'http://localhost:3030'
  let headers = {'content-type': 'application/json'}

  if (window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_SERVICE_URL !== graphqlLocalHost) {
    headers = {
      'content-type': 'application/json',
      'x-api-key': window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_SERVICE_KEY,
    }
  }
  return headers
}
