import find from 'lodash/find'
import flow from 'lodash/flow'
import isFunction from 'lodash/isFunction'
import mapKeys from 'lodash/mapKeys'

export const OK = 200
export const BAD_REQUEST = 400
export const NOT_FOUND = 404
export const CONFLICT = 409
export const UNAUTHORIZED = 403
export const SERVER_ERROR = 500
export const BAD_GATEWAY = 502

// -- API Status Code Helpers

export const isGoodResponse = (status) => status >= 200 && status < 400
export const isBadResponse = (status) => status >= 400

// -- onStatus()

// convert 'OK' to '2\d\d'
const okToRegexString = (obj) => mapKeys(obj, (value, key) => (key === 'OK' ? '2\\d\\d' : key))

// convert 'REDIRECT' to '3\d\d'
const redirectToRegexString = (obj) => mapKeys(obj, (value, key) => (key === 'REDIRECT' ? '3\\d\\d' : key))

// convert 'CLIENT_ERROR' to '4\d\d'
const clientErrorToRegexString = (obj) => mapKeys(obj, (value, key) => (key === 'CLIENT_ERROR' ? '4\\d\\d' : key))

// convert 'SERVER_ERROR' to '5\d\d'
const serverErrorToRegexString = (obj) => mapKeys(obj, (value, key) => (key === 'SERVER_ERROR' ? '5\\d\\d' : key))

// convert 'DEFAULT' to '\d*'
const defaultToRegexString = (obj) => mapKeys(obj, (value, key) => (key === 'DEFAULT' ? '\\d*' : key))

/*
 * `onStatus` accepts an object like:
 *   {
 *     OK: response => console.log(response.json),
 *     302: () => console.log('Cached response returned.'),
 *     DEFAULT: () => console.log(`An unexpected error occured: ${statusText}.`),
 *   }
 *
 * The response.status is compared against the keys of the object, which are treated as regular expressions
 *  or keywords that are translated to regular expressions. Upon the first match, the corresponing function will
 *  be executed with the response passed in. I REPEAT, ONLY THE FIRST MATCH IS EXECUTED.
 */

export const onStatus = (obj) => (response) => {
  const fn = flow([
    okToRegexString,
    redirectToRegexString,
    clientErrorToRegexString,
    serverErrorToRegexString,
    defaultToRegexString,
    (x) => find(x, (value, key) => new RegExp(key).exec(response.status)), // potentially returns a function
  ])(obj)
  return isFunction(fn) ? fn(response) : response // which `response` is passed into
}
