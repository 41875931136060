import {getFormInitialValues, getFormValues, formValueSelector} from 'redux-form'
import {isEmpty} from 'lodash'
import {sendEmail} from '../../../api/iterable/index'
import * as en from '../constants/enums'
import * as store from '../constants/store'
import {emailError} from './sizeData'

// SIZE CHANGED EMAIL

// Fields to compare were decided based on conversations with Jacob 7.7.2017
const fieldsToCompare = [en.assignedFormChest, en.assignedFormInseam, en.assignedFormJacketLength, en.assignedFormWaist]

export function shouldNotifyUser(state) {
  const selector = formValueSelector(en.formName)
  return selector(state, en.assignedFormSizeChangedEmail)
}

export function changedSizes(currentSizes, initialSizes) {
  return fieldsToCompare.reduce((obj, formField) => {
    const newSize = currentSizes[formField]
    const oldSize = initialSizes[formField]
    if (newSize !== oldSize) {
      obj[en.assignedSizes.convertToApiField(formField)] = {updated: newSize, original: oldSize}
    }
    return obj
  }, {})
}

export function sendSizeChangedEmail() {
  return (dispatch, getState) => {
    const state = getState()
    if (shouldNotifyUser(state)) {
      const currentFormValues = getFormValues(en.formName)(state)
      const initialFormValues = getFormInitialValues(en.formName)(state)
      const updatedSizes = changedSizes(currentFormValues, initialFormValues)
      if (!isEmpty(updatedSizes)) {
        const {firstName, email} = store.user(state)
        const payload = {...updatedSizes, firstName}
        return sendEmail('sizeChangedEmail', email, payload).catch(() => dispatch(emailError()))
      }
    }
    return false
  }
}
