import jwtDecode from 'jwt-decode'
import {fetchAuthInfToken} from '../actions/session'

/**
 * @param {string} token - Parses a user's JWT to determine if the token is valid or void
 * @returns {null}
 */
export function loadUserProfile(token) {
  try {
    const userProfile = jwtDecode(token)
    const now = new Date().getTime() / 1000
    if (!userProfile.exp || now > userProfile.exp) {
      fetchAuthInfToken()
      return null
    }
    return userProfile
  } catch (err) {
    return null
  }
}

export default loadUserProfile
