import React from 'react'
import PropTypes from 'prop-types'
import ToolTimePageLoading from './ToolTimePageLoading'

export default function QueryDisplay({queryResults, error, loading, success}) {
  let displayComponent = loading || (
    <section>
      <ToolTimePageLoading />
    </section>
  )

  if (queryResults.error) {
    displayComponent = error || <section>{queryResults.error.message}</section>
  } else if (queryResults.props) {
    displayComponent = success
  }
  return displayComponent
}

QueryDisplay.defaultProps = {
  error: null,
  loading: null,
}

QueryDisplay.propTypes = {
  error: PropTypes.node,
  loading: PropTypes.node,
  queryResults: PropTypes.object.isRequired,
  success: PropTypes.node.isRequired,
}
