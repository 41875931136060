import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import * as store from '../constants/store'
import {editSurvey, resetEditableState} from '../actions/sizeData'
import CategoryContainer from './CategoryContainer'
import ToolTimeButton from '../../../components/ToolTimeButton'
import ToolTimeLoading from '../../../components/ToolTimeLoading'
import {FourColumnRow} from './ReusableComponents/FormattedRows'

function SubmitLoading() {
  return <ToolTimeLoading translateX="-20%" translateY="-55%" height="70%" type="bars" />
}

function FormSubmitRow(props) {
  const surveyIsDisabledButtons = {
    col1: (
      <ToolTimeButton
        buttonTextTitle="Update Survey Responses"
        color="link"
        size="sm"
        textAlign="left"
        block
        onClick={props.makeSurveyEditable}
      />
    ),
  }

  const surveyIsEditableButtons = {
    col1: (
      <ToolTimeButton
        buttonTextTitle="Cancel"
        color="link-gray"
        size="sm"
        textAlign="center"
        onClick={props.cancelAndReset}
      />
    ),
    col2: (
      <ToolTimeButton
        buttonTextTitle={props.submitting ? <SubmitLoading /> : 'Save Survey'}
        color="success"
        size="sm"
        block
        disabled={props.submitting}
        textAlign="center"
        onClick={props.saveSurvey}
      />
    ),
    col3: (
      <div className="sp-submit-survey-info">
        Save the survey and <br />
        update assigned sizes
      </div>
    ),
  }

  const surveyButtons = props.surveyIsDisabled ? surveyIsDisabledButtons : surveyIsEditableButtons
  return (
    <CategoryContainer>
      <FourColumnRow
        {...surveyButtons}
        col4={
          <ToolTimeButton
            buttonTextTitle={props.submitting ? <SubmitLoading /> : 'Save Assigned Sizes'}
            color={props.assignedSizesIsDisabled ? 'disabled' : 'success'}
            size="sm"
            textAlign="center"
            block
            disabled={props.assignedSizesIsDisabled || props.submitting}
            onClick={props.saveAssignedSizes}
          />
        }
      />
    </CategoryContainer>
  )
}

function mapStateToProps(state) {
  return {
    surveyIsDisabled: store.surveyIsDisabled(state),
    assignedSizesIsDisabled: store.assignedSizesIsDisabled(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    makeSurveyEditable: () => dispatch(editSurvey()),
    cancelAndReset: () => {
      ownProps.reset() // removes any form changes
      dispatch(resetEditableState()) // resets form editability to initial
    },
  }
}

FormSubmitRow.propTypes = {
  saveSurvey: PropTypes.func.isRequired,
  saveAssignedSizes: PropTypes.func.isRequired,
  surveyIsDisabled: PropTypes.bool.isRequired,
  assignedSizesIsDisabled: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  makeSurveyEditable: PropTypes.func.isRequired,
  cancelAndReset: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormSubmitRow)
