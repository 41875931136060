import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {optionsShape, ToolTimeInput} from './ToolTimeInput'

export function FeedbackMessage({message}) {
  return <div className="form-control-feedback">{message}</div>
}

FeedbackMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export function ReduxFormInput(props) {
  const hasError = props.meta.touched && props.meta.error

  const wrapperClasses = classNames({
    'has-danger': hasError,
    'has-warning': props.meta.warning,
  })
  const inputClasses = classNames(props.className, {
    'read-only': props.readOnly,
  })
  const labelClasses = classNames('infield-label', {
    'is--focused': props.meta.active,
  })

  const error = hasError ? <FeedbackMessage message={props.meta.error} /> : null
  const warning = props.meta.warning ? <FeedbackMessage message={props.meta.warning} /> : null
  const label = props.label ? (
    <label htmlFor={props.id} className={labelClasses}>
      {props.label}
    </label>
  ) : null

  return (
    <div className={wrapperClasses}>
      {label}
      <ToolTimeInput
        input={props.input}
        disabled={props.readOnly || props.disabled}
        className={inputClasses}
        type={props.type}
        id={props.id}
        options={props.options}
      />
      {error}
      {warning}
    </div>
  )
}

ReduxFormInput.defaultProps = {
  label: null,
  readOnly: false,
  disabled: false,
  className: null,
  options: null,
  id: null,
}

ReduxFormInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(optionsShape)),
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
