// Survey API Fields
export const surveyApiBirthdate = 'birthdate'
export const surveyApiChest = 'chest'
export const surveyApiHeightFeet = 'heightFeet'
export const surveyApiHeightInches = 'heightInches'
export const surveyApiInseam = 'inseam'
export const surveyApiJacketLength = 'jacketLength'
export const surveyApiMeasuredAt = 'measuredAt'
export const surveyApiNeck = 'neck'
export const surveyApiPreferredFit = 'preferredFit'
export const surveyApiShoe = 'shoe'
export const surveyApiShoulderShape = 'shoulderShape'
export const surveyApiSleeve = 'sleeve'
export const surveyApiStomachShape = 'stomachShape'
export const surveyApiWaist = 'waist'
export const surveyApiWeight = 'weight'

// Survey Form Fields
export const surveyFormBirthdate = 'survey_birthdate'
export const surveyFormChest = 'survey_chest'
export const surveyFormHeightFeet = 'survey_heightFeet'
export const surveyFormHeightInches = 'survey_heightInches'
export const surveyFormInseam = 'survey_inseam'
export const surveyFormJacketLength = 'survey_jacketLength'
export const surveyFormNeck = 'survey_neck'
export const surveyFormPreferredFit = 'survey_preferredFit'
export const surveyFormShoe = 'survey_shoe'
export const surveyFormShoulderShape = 'survey_shoulderShape'
export const surveyFormSleeve = 'survey_sleeve'
export const surveyFormStomachShape = 'survey_stomachShape'
export const surveyFormWaist = 'survey_waist'
export const surveyFormWeight = 'survey_weight'

// Maps API and Form fields
const surveyFieldMap = [
  [surveyApiBirthdate, surveyFormBirthdate],
  [surveyApiChest, surveyFormChest],
  [surveyApiHeightFeet, surveyFormHeightFeet],
  [surveyApiHeightInches, surveyFormHeightInches],
  [surveyApiInseam, surveyFormInseam],
  [surveyApiJacketLength, surveyFormJacketLength],
  [surveyApiNeck, surveyFormNeck],
  [surveyApiPreferredFit, surveyFormPreferredFit],
  [surveyApiShoe, surveyFormShoe],
  [surveyApiShoulderShape, surveyFormShoulderShape],
  [surveyApiSleeve, surveyFormSleeve],
  [surveyApiStomachShape, surveyFormStomachShape],
  [surveyApiWaist, surveyFormWaist],
  [surveyApiWeight, surveyFormWeight],
]

// Assigned Size API Fields
export const assignedApiBelt = 'belt'
export const assignedApiChest = 'chest'
export const assignedApiInseam = 'inseam'
export const assignedApiInseamSizeAdjust = 'inseamSizeAdjust'
export const assignedApiJacketLength = 'jacketLength'
export const assignedApiJacketSleeveAdjust = 'jacketSleeveAdjust'
export const assignedApiNeck = 'neck'
export const assignedApiPantFit = 'pantFit'
export const assignedApiReason = 'reason'
export const assignedApiShirtFit = 'shirtFit'
export const assignedApiShoe = 'shoe'
export const assignedApiSizeChecked = 'sizeChecked'
export const assignedApiSleeve = 'sleeve'
export const assignedApiVest = 'vest'
export const assignedApiVestLength = 'vestLength'
export const assignedApiWaist = 'waist'

// Assigned Size Form Fields
export const assignedFormBelt = 'assigned_belt'
export const assignedFormChest = 'assigned_chest'
export const assignedFormInseam = 'assigned_inseam'
export const assignedFormInseamSizeAdjust = 'assigned_inseamSizeAdjust'
export const assignedFormJacketLength = 'assigned_jacketLength'
export const assignedFormJacketSleeveAdjust = 'assigned_jacketSleeveAdjust'
export const assignedFormNeck = 'assigned_neck'
export const assignedFormPantFit = 'assigned_pantFit'
export const assignedFormReason = 'assigned_reason'
export const assignedFormShirtFit = 'assigned_shirtfit'
export const assignedFormShoe = 'assigned_shoe'
export const assignedFormSizeChangedEmail = 'assigned_sizesChangedEmail'
export const assignedFormSleeve = 'assigned_sleeve'
export const assignedFormVest = 'assigned_vest'
export const assignedFormVestLength = 'assigned_vestLength'
export const assignedFormWaist = 'assigned_waist'

// Maps Api and Form Fields
const assignedSizesFieldMap = [
  [assignedApiBelt, assignedFormBelt],
  [assignedApiChest, assignedFormChest],
  [assignedApiInseam, assignedFormInseam],
  [assignedApiInseamSizeAdjust, assignedFormInseamSizeAdjust],
  [assignedApiJacketLength, assignedFormJacketLength],
  [assignedApiJacketSleeveAdjust, assignedFormJacketSleeveAdjust],
  [assignedApiNeck, assignedFormNeck],
  [assignedApiPantFit, assignedFormPantFit],
  [assignedApiReason, assignedFormReason],
  [assignedApiShirtFit, assignedFormShirtFit],
  [assignedApiShoe, assignedFormShoe],
  [assignedApiSleeve, assignedFormSleeve],
  [assignedApiVest, assignedFormVest],
  [assignedApiVestLength, assignedFormVestLength],
  [assignedApiWaist, assignedFormWaist],
]

// Predictions Api Fields
export const predictionChest = 'coatSize'
export const predictionJacketLength = 'coatLength'
export const predictionWaist = 'pantSize'
export const predictionInseam = 'pantLength'
export const predictionNeck = 'shirtSize'
export const predictionSleeve = 'shirtLength'

// Misc
export const noneValue = 'none'
export const noneLabel = 'None'
export const formName = 'SizesForm'

// Field conversion functions
function getFormField(entry) {
  return entry[1]
}

function getApiField(entry) {
  return entry[0]
}

function nullToNone(value) {
  return value === null ? noneValue : value
}

function noneToNull(value) {
  return value === noneValue ? null : value
}

class Fields {
  constructor(fieldMap) {
    this.fieldMap = fieldMap
  }

  // converts a single api field to form field
  convertToFormField(apiField) {
    return getFormField(this.fieldMap.find((entry) => entry.includes(apiField)))
  }

  // converts a single form field to api field
  convertToApiField(formField) {
    return getApiField(this.fieldMap.find((entry) => entry.includes(formField)))
  }

  // returns an object of form fields set to api data values
  convertToFormFields(apiData) {
    return this.fieldMap.reduce((obj, entry) => {
      const formField = getFormField(entry)
      const apiField = getApiField(entry)
      obj[formField] = nullToNone(apiData[apiField])
      return obj
    }, {})
  }

  // returns an object of api fields set to form field values
  convertToApiFields(formData) {
    return this.fieldMap.reduce((obj, entry) => {
      const formField = getFormField(entry)
      const apiField = getApiField(entry)
      obj[apiField] = noneToNull(formData[formField])
      return obj
    }, {})
  }

  // sets all api fields to null if fitProfile or Sizes do not exist
  defaultApiFields() {
    return this.fieldMap.reduce((obj, entry) => {
      const apiField = getApiField(entry)
      obj[apiField] = null
      return obj
    }, {})
  }

  // converts the required api field names to form field names
  requiredFormFields(requiredApiFields) {
    return this.fieldMap
      .filter((entry) => requiredApiFields.includes(getApiField(entry)))
      .map((entry) => getFormField(entry))
  }
}

export const survey = new Fields(surveyFieldMap)
export const assignedSizes = new Fields(assignedSizesFieldMap)

/**
 * Map measuredAt fitprofile value to user-intelligible label.
 * Mapping values can be determined from the mapping in TBT in app.models.user.FitProfile.MEASURED_AT_CHOICES.
 */
export const FITPROFILE_MEASURED_AT_MAP = {
  1: "David's Bridal",
}
