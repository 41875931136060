export interface Address {
  address1: string
  address2: string | null
  city: string
  state: string
  zip: string
}

export enum EventType {
  Wedding = 0,
  PromOrFormal = 1,
  SpecialEvent = 3,
}

export enum OrderType {
  HTO = 'HTO',
  Rental = 'RENTAL',
  Buy = 'BUY',
  Replacement = 'RENTAL REPLACEMENT',
}

export interface Event {
  id: number | null
  name: string
}

export interface Order {
  id: number | null
  event: Event
  orderType: OrderType | null
  shipping: Address
}

export interface OrderUser {
  email: string
  firstName: string | null
  lastName: string | null
  id: number | null
}

export type Customer = OrderUser & {
  last4CreditCardDigits: string
}

export enum ShippingMethod {
  Rush = 'Rush',
  Expedited = 'Expedited',
  Partial = 'Partial',
}

export type ShippingType = 'rush' | 'expedited' | 'partial'

export * from './PaymentPortal/index'
export * from './Worldpay/index'
