import {
  SOLD_OUT_DATES_OK,
  SOLD_OUT_DATES_NOT_OK,
  SEARCH_SOLD_OUT,
  ADD_SOLD_OUT_OK,
  ADD_SOLD_OUT_NOT_OK,
  DELETE_SOLD_OUT_OK,
  DELETE_SOLD_OUT_NOT_OK,
  PRODUCT_SOLD_OUT_PERIODS_OK,
  PRODUCT_SOLD_OUT_PERIODS_NOT_OK,
  RESET_SOLD_OUT_STATE,
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  productsWithSoldOutDates: {},
  fixed: {},
  mostRecentError: null,
  value: '',
  soldOutPeriods: [],
  addStatus: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SOLD_OUT_DATES_OK: {
      const productsWithDates = action.payload.results.filter((product) => product.sold_out_periods.length > 0)
      return {
        ...state,
        productsWithSoldOutDates: productsWithDates,
        fixed: productsWithDates,
      }
    }
    case SOLD_OUT_DATES_NOT_OK:
      return {
        ...state,
        mostRecentError: action.payload,
      }
    case SEARCH_SOLD_OUT: {
      const filteredDates = state.fixed.filter((val) => val.name.toUpperCase().includes(action.value.toUpperCase()))
      return {
        ...state,
        productsWithSoldOutDates: filteredDates,
        soldOutSearchValue: action.value,
      }
    }
    case ADD_SOLD_OUT_OK:
      return {
        ...state,
        addStatus: action.payload,
        soldOutPeriods: [...state.soldOutPeriods, action.payload],
      }
    case ADD_SOLD_OUT_NOT_OK:
      return {
        ...state,
        mostRecentError: action.payload,
      }
    case DELETE_SOLD_OUT_OK: {
      const updatedList = state.soldOutPeriods.filter((date) => date.id !== action.payload)
      return {
        ...state,
        soldOutPeriods: updatedList,
      }
    }
    case DELETE_SOLD_OUT_NOT_OK:
      return {
        ...state,
        mostRecentError: action.payload,
      }
    case PRODUCT_SOLD_OUT_PERIODS_OK:
      return {
        ...state,
        soldOutPeriods: action.payload,
      }
    case PRODUCT_SOLD_OUT_PERIODS_NOT_OK:
      return {
        ...state,
        mostRecentError: action.payload,
      }
    case RESET_SOLD_OUT_STATE:
      return {
        ...state,
        soldOutPeriods: [],
        impactedProducts: [],
      }
    default:
      return state
  }
}
