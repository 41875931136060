import {ApiRequestStates} from '../../api/const'
import {reducer} from './helpers'
import {WorldpayAction} from '../actions/worldpay'

const initialState = {
  expMonth: '',
  expYear: '',
  isCardOnFileCharge: true,
  last4: '',
  formZipCode: null,
  paypageRegistrationId: null,
  getWorldpayInfoStatus: ApiRequestStates.IDLE,
}

export default reducer(initialState, (state, action) => {
  switch (action.type) {
    case WorldpayAction.GET_WORLDPAY_INFO:
      return {
        ...initialState,
        formZipCode: action.payload ?? null,
      }
    case WorldpayAction.GET_WORLDPAY_INFO_FAILURE:
      return {
        getWorldpayInfoStatus: ApiRequestStates.ERROR,
      }
    case WorldpayAction.GET_WORLDPAY_INFO_PENDING:
      return {
        getWorldpayInfoStatus: ApiRequestStates.PENDING,
      }
    case WorldpayAction.GET_WORLDPAY_INFO_SUCCESS:
      return {
        expMonth: action.payload.expMonth,
        expYear: action.payload.expYear,
        isCardOnFileCharge: false,
        last4: action.payload.lastFour,
        paypageRegistrationId: action.payload.paypageRegistrationId,
        getWorldpayInfoStatus: ApiRequestStates.SUCCESS,
      }
    default:
      return null
  }
})
