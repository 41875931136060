export const constructOptions = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `jwt ${JSON.parse(localStorage.getItem('the-black-tux-admin')).token}`,
  },
})

export const constructOptionsAndValidateStatus = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `jwt ${JSON.parse(localStorage.getItem('the-black-tux-admin')).token}`,
  },

  /**
   * `validateStatus` defines whether to resolve or reject the promise for a given
   * HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
   * or `undefined`), the promise will be resolved; otherwise, the promise will be rejected
   *
   * @see {@link https://github.com/axios/axios#request-config}
   *
   */
  validateStatus: () => true,
})
