import {all, call, select} from 'redux-saga/effects'

import * as paymentPortalSelectors from '../../selectors/PaymentPortal/index'
import * as sessionSelectors from '../../selectors/session'
import {formatTransactionPayloadByReason, snakeCaseObjectKeys} from '../../../utilities/formatting'
import {RefundType, TransactionReason, TransactionType} from '../../../types'

export function* createChargesSummaryPayload(transactionItems) {
  const applyAccountBalance = yield select(paymentPortalSelectors.hasAppliedAccountBalance)
  const charges = yield all(
    transactionItems.map((item) => call(formatTransactionPayloadByReason, item, TransactionType.Charges)),
  )

  return snakeCaseObjectKeys({
    applyAccountBalance,
    address1: yield select(paymentPortalSelectors.address1),
    address2: yield select(paymentPortalSelectors.address2),
    city: yield select(paymentPortalSelectors.city),
    state: yield select(paymentPortalSelectors.state),
    zipCode: yield select(paymentPortalSelectors.zip),
    userId: yield select(paymentPortalSelectors.customerId),
    charges,
  })
}

export function* createRefundsSummaryPayload(transactionItems) {
  const createdByUser = yield select(sessionSelectors.adminEmail)
  const payerId = yield select(paymentPortalSelectors.customerId)
  const refunds = yield all(
    transactionItems.map((item) => call(formatTransactionPayloadByReason, item, TransactionType.Refunds)),
  )
  const payload = {
    createdByUser,
    payerId,
    // TODO: will wire this up when we want to toggle ability to allow refunds to customer for events <= 45 days away
    overrideLateRefund: false,
    refunds,
  }

  const transactionReason = yield select(paymentPortalSelectors.transactionReason)
  return snakeCaseObjectKeys({
    ...payload,
    refundType:
      transactionReason === TransactionReason.Miscellaneous ? RefundType.Reimbursement : RefundType.Transaction,
  })
}
