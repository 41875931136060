import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import moment from 'moment'
import {createStructuredSelector} from 'reselect'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Col, Row, Input} from 'reactstrap'
import {getSoldOutDates, searchSoldOut} from '../../actions/soldOutDates'

import ToolTimeButton from '../../../../components/ToolTimeButton'
import ToolTimeContainer from '../../../../components/ToolTimeContainer'
import ToolTimeFloatLeftRight from '../../../../components/ToolTimeFloatLeftRight'
import ToolTimeTable from '../../../../components/ToolTimeTable'
import ToolTimeLoader from '../../../../components/ToolTimePageLoading'

import {ADD_SOLD_OUT_DATES} from '../../routes/routes'

import * as soldOutSelectors from '../../store/soldout'

import {compareAlphabeticallyBy} from '../../helpers'

const renderRows = (soldOutPeriod) => {
  const datesArray = soldOutPeriod.sold_out_periods
  const routeUrl = `/product-management/sold-out-dates/edit/${soldOutPeriod.id}`

  const renderDates = (transactionType) =>
    datesArray
      .filter((date) => date.transaction_type === transactionType)
      .map((date) => {
        const startDate = moment(date.start_date).format('MM/DD/YY')
        const endDate = moment(date.end_date).format('MM/DD/YY')
        return (
          <div key={date.id}>
            {startDate} - {endDate}
          </div>
        )
      })
  return (
    <tr key={soldOutPeriod.id}>
      <td>
        <Link to={routeUrl}>{soldOutPeriod.name}</Link>
        <span className="label">Product Name</span>
      </td>
      <td>
        {renderDates('rent')}
        <span className="label">Rental Sold Out Dates</span>
      </td>
      <td>
        {renderDates('buy')}
        <span className="label">Buy Sold Out Dates</span>
      </td>
    </tr>
  )
}

const SoldOutDates = ({
  fetchSoldOutDates,
  hasError,
  mostRecentError,
  productsWithSoldOutDates,
  searchProduct,
  soldOutsHasLoaded,
  soldOutSearchValue,
}) => {
  useEffect(() => {
    fetchSoldOutDates()
  }, [fetchSoldOutDates])

  let result = <ToolTimeLoader />
  if (hasError) {
    result = <span>{mostRecentError}</span>
  }
  if (soldOutsHasLoaded) {
    const sortedProducts = productsWithSoldOutDates.sort(compareAlphabeticallyBy('name'))
    result = sortedProducts.map((product) => <tbody key={product.id}>{renderRows(product)}</tbody>)
  }
  return (
    <ToolTimeContainer className="manage-products">
      <Row>
        <Col xs={12}>
          <ToolTimeFloatLeftRight
            floatContentLeft={<h1>Sold Out Dates</h1>}
            floatContentRight={
              <ToolTimeButton buttonTextTitle="Add Sold Out Periods" size="md" tag={Link} to={ADD_SOLD_OUT_DATES} />
            }
          />
        </Col>
      </Row>
      <Input
        className="mb-2"
        placeholder="Search"
        onChange={(e) => searchProduct(e.target.value)}
        value={soldOutSearchValue}
      />
      <ToolTimeTable responsive simpleTable>
        {result}
      </ToolTimeTable>
    </ToolTimeContainer>
  )
}

SoldOutDates.defaultProps = {
  mostRecentError: null,
  soldOutSearchValue: undefined,
}

SoldOutDates.propTypes = {
  fetchSoldOutDates: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  mostRecentError: PropTypes.string,
  productsWithSoldOutDates: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]).isRequired,
  searchProduct: PropTypes.func.isRequired,
  soldOutsHasLoaded: PropTypes.bool.isRequired,
  soldOutSearchValue: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  hasError: soldOutSelectors.hasError,
  soldOutsHasLoaded: soldOutSelectors.soldOutsHasLoaded,
  mostRecentError: soldOutSelectors.mostRecentError,
  productsWithSoldOutDates: soldOutSelectors.productsWithSoldOutDates,
  soldOutSearchValue: soldOutSelectors.soldOutSearchValue,
})

const mapDispatchToProps = (dispatch) => ({
  fetchSoldOutDates: () => dispatch(getSoldOutDates()),
  searchProduct: (value) => dispatch(searchSoldOut(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SoldOutDates)
