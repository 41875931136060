import React from 'react'
import PropTypes from 'prop-types'
import {assignedFormBelt} from '../constants/enums'
import * as data from '../constants/FormFieldData'
import {calculateBeltSize} from '../constants/lib'
import FormRow from './ReusableComponents/FormRow'
import CategoryContainer from './CategoryContainer'
import {PreferredFitRow} from './PreferredFitRow'

export default function PantBeltSizes(props) {
  // Waist assignedSizes onChange => calc belt size
  const waistRow = Object.assign({}, data.waistRow)
  data.waistRow.assignedSizes.onChange = props.computeField(assignedFormBelt, calculateBeltSize)

  return (
    <CategoryContainer header="Pant & Belt Sizes">
      <FormRow {...waistRow} />
      <FormRow {...data.inseamRow} />
      <PreferredFitRow {...data.pantFitRow} />
      <FormRow {...data.inseamAdjustRow} />
      <FormRow {...data.beltRow} />
    </CategoryContainer>
  )
}

PantBeltSizes.propTypes = {
  computeField: PropTypes.func.isRequired,
}
