import React, {useContext} from 'react'
import {connect} from 'react-redux'
import {MenuItemLink, getResources} from 'react-admin'
import {withRouter} from 'react-router-dom'
import {capitalize, flowRight} from 'lodash'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PageviewIcon from '@material-ui/icons/Pageview'
import ViewListIcon from '@material-ui/icons/ViewList'
import {deleteAuthJwtToken} from '../../legacy/actions/session'

import EnvironmentContext from '../../contexts/envContext'

import NewTabLink from './NewTabLink'

const AppMenu = ({resources, onMenuClick, deleteAuthJwtToken}) => {
  /*
    TODO: (2019-12-09): we can remove this check once we complete and release the inventory availability tool.
    TODO: (2022-03-26): This isn't working. Inventory Availability shows in production, and based on the history of this code it always has.
      For context (🥁):
      - isProduction is always `undefined`, which means `!isProduction` is always `true` and will render the link.
      - `defaultValue` provided to `createContext(defaultValue)` only works when a `Provider` is NOT used.
      - If a `Provider` is used, then it must be given a `value={}` prop.

      Need to verify, but if this has always been in production and is expected to remain in production, then we can remove this check.
      Otherwise, fix it or just convert it to a util (or even just inline it) since it's not really necessary as a Context.
  */
  const isProduction = useContext(EnvironmentContext)

  return (
    <div>
      <MenuItemLink key="dashboard" leftIcon={<DashboardIcon />} to="/" primaryText="Dashboard" onClick={onMenuClick} />
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          leftIcon={<ViewListIcon />}
          to={`/${resource.name}`}
          primaryText={(resource.options && capitalize(resource.options.label)) || capitalize(resource.name)}
          onClick={onMenuClick}
        />
      ))}
      <MenuItemLink
        component={NewTabLink}
        leftIcon={<AccessTimeIcon />}
        primaryText="Sold Out Dates"
        onClick={onMenuClick}
        to="/product-management/sold-out-dates"
      />
      {!isProduction && (
        <MenuItemLink
          leftIcon={<PageviewIcon />}
          onClick={onMenuClick}
          primaryText="Inventory Availability"
          to="/inventory-availability"
        />
      )}
      <MenuItemLink leftIcon={<ExitToAppIcon />} primaryText="Log Out" to="/logout" />
    </div>
  )
}

const mapStateToProps = (state) => ({
  resources: getResources(state),
})

const mapDispatchToProps = {
  deleteJwtToken: deleteAuthJwtToken,
}

export default flowRight(withRouter, connect(mapStateToProps, mapDispatchToProps))(AppMenu)
