import {ApolloClient, HttpLink, InMemoryCache} from '@apollo/client'

export default new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_SERVICE_URL,
    headers: {
      'content-type': 'application/json',
      'x-api-key': window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_SERVICE_KEY,
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})
