import {ApiRequestStates} from '../../../api/const'

import {reducer} from '../helpers'
import {
  PPOrderAction,
  GetCurrentOrderDetails,
  GetCurrentOrderDetailsFailure,
  GetCurrentOrderDetailsPending,
  GetCurrentOrderDetailsSuccess,
} from '../../actions/PaymentPortal/order'
import {Order} from '../../../types'

interface RequestStatus {
  getCurrentOrderDetailsStatus: ApiRequestStates
}

export type State = Order & RequestStatus

const initialState: State = {
  id: null,
  event: {
    id: null,
    name: '',
  },
  getCurrentOrderDetailsStatus: ApiRequestStates.IDLE,
  orderType: null,
  shipping: {
    address1: '',
    address2: null,
    city: '',
    state: '',
    zip: '',
  },
}

type OrderAction =
  | GetCurrentOrderDetails
  | GetCurrentOrderDetailsFailure
  | GetCurrentOrderDetailsPending
  | GetCurrentOrderDetailsSuccess

export default reducer(initialState, (state: State, action: OrderAction): Partial<State> | null => {
  switch (action.type) {
    case PPOrderAction.GET_CURRENT_ORDER_DETAILS:
      return {
        ...initialState,
      }
    case PPOrderAction.GET_CURRENT_ORDER_DETAILS_FAILURE:
      return {
        getCurrentOrderDetailsStatus: ApiRequestStates.ERROR,
      }
    case PPOrderAction.GET_CURRENT_ORDER_DETAILS_PENDING:
      return {
        getCurrentOrderDetailsStatus: ApiRequestStates.PENDING,
      }
    case PPOrderAction.GET_CURRENT_ORDER_DETAILS_SUCCESS:
      const {order} = action.payload

      return {
        id: order.id,
        event: {...order.event},
        getCurrentOrderDetailsStatus: ApiRequestStates.SUCCESS,
        orderType: order.orderType,
        shipping: {...order.shipping},
      }
    default:
      return null
  }
})
