import {showNotification} from 'react-admin'
import get from 'lodash/get'
import {all, call, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'

import {isGoodResponse} from '../../api/helpers'
import * as ordersApi from '../../api/OrdersApiClient'
import * as ordersActions from '../actions/orders'

export function* sendOrderConfirmationEmailSaga({orderId}) {
  try {
    yield call(ordersApi.sendOrderConfirmationEmail, orderId)
    yield put(showNotification('Order confirmation sent'))
  } catch (err) {
    yield put(showNotification(get(err, 'response.data.detail', 'Order confirmation failed to send')))
  }
}

function* watchSendOrderConfirmationEmail() {
  yield takeEvery('SEND_CONFIRMATION_EMAIL', sendOrderConfirmationEmailSaga)
}

export function* getOrderInfoWorker({payload: {orderId}}) {
  yield put(ordersActions.getOrderInfoPending())
  try {
    const {data, status} = yield call(ordersApi.getOrderInfo, orderId)
    if (isGoodResponse(status)) {
      yield put(ordersActions.getOrderInfoSuccess(data))
    } else {
      throw new Error('Unable to fetch order info.')
    }
  } catch (e) {
    yield put(ordersActions.getOrderInfoFailure(e))
  }
}

export function* getOrderInfoSaga() {
  yield takeLatest(ordersActions.GET_ORDER_INFO, getOrderInfoWorker)
}

export function* getProductsByOrderIdWorker({payload: {orderId}}) {
  yield put(ordersActions.getProductsByOrderIdPending())
  try {
    const {
      data: {results},
      status,
    } = yield call(ordersApi.getProductsByOrderId, orderId)
    if (isGoodResponse(status)) {
      yield put(ordersActions.getProductsByOrderIdSuccess(results))
    } else {
      throw new Error('Unable to fetch products in order.')
    }
  } catch (e) {
    yield put(ordersActions.getProductsByOrderIdFailure(e))
  }
}

export function* getProductsByOrderIdSaga() {
  yield takeLatest(ordersActions.GET_PRODUCTS_BY_ORDER_ID, getProductsByOrderIdWorker)
}

export default function* ordersSaga() {
  yield all([fork(watchSendOrderConfirmationEmail), fork(getOrderInfoSaga), fork(getProductsByOrderIdSaga)])
}
