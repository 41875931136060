import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Field} from 'redux-form'
import {ReduxFormInput} from '../../../../components/ReduxFormInput'
import {stringToNumber} from '../../constants/lib'

/* This component is essentially a passthrough to the Field component with the addition of some
hardcoded props that would be common to all the numeric select dropdown components: type,
component, parse, id, and className */

export default function SelectNumberField(props) {
  return (
    <Field
      name={props.formFieldName}
      component={ReduxFormInput}
      type="select"
      options={props.options}
      parse={stringToNumber}
      id={props.id || props.formFieldName}
      warn={props.warning}
      onChange={props.onChange}
      readOnly={props.readOnly}
      disabled={props.disabled}
      className={classNames('sp-form-field', props.className)}
    />
  )
}

SelectNumberField.defaultProps = {
  id: undefined,
  warning: undefined,
  onChange: undefined,
  readOnly: false,
  disabled: false,
  className: undefined,
}

SelectNumberField.propTypes = {
  formFieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  id: PropTypes.string,
  warning: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
