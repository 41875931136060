import React from 'react'
import PropTypes from 'prop-types'
import {NavLink as RouterNavLink} from 'react-router-dom'
import {Icon} from 'react-fa'
import classNames from 'classnames'
import {Nav, NavItem, NavLink} from 'reactstrap'

const ToolTimeTabs = (props) => {
  const {className, tabsList, dark, light, ...attributes} = props

  const classes = classNames(
    className,
    ['tooltime-tabs', 'nav-fill'],
    dark ? 'dark-theme' : false,
    light ? 'light-theme' : false,
  )
  const tabs = tabsList.map((tab) => (
    <NavItem key={tab.id}>
      <NavLink tag={RouterNavLink} to={tab.url} activeClassName="active" className={tab.icon ? 'btn-icon' : ''}>
        {tab.name || <Icon name={tab.icon} />}
      </NavLink>
    </NavItem>
  ))

  return (
    <Nav {...attributes} className={classes} tabs>
      {tabs}
    </Nav>
  )
}

ToolTimeTabs.defaultProps = {
  className: undefined,
  dark: undefined,
  light: undefined,
}

ToolTimeTabs.propTypes = {
  className: PropTypes.any,
  dark: PropTypes.bool,
  light: PropTypes.bool,
  tabsList: PropTypes.array.isRequired,
}

export default ToolTimeTabs
