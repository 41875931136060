import {combineReducers} from 'redux'

import activePaymentType, {ActivePaymentTypeState} from './activePaymentType'
import customer from './customer'
import order from './order'
import processPayment, {ProcessPaymentState} from './processPayment'
import summary, {SummaryState} from './summary'
import transactions, {TransactionsState} from './transactions'
import transactionMetadata, {TransactionMetadataState} from './transactionMetadata'
import transactionType from './transactionType'
import worldpay, {WorldpayState} from './worldpay'
import {Customer, TransactionPayload, TransactionType} from '../../../types'

export default combineReducers({
  activePaymentType,
  customer,
  order,
  processPayment,
  summary,
  transactions,
  transactionMetadata,
  transactionType,
  worldpay,
})

export interface PaymentPortalReducer {
  activePaymentType: ActivePaymentTypeState
  customer: Customer
  order: TransactionPayload
  processPayment: ProcessPaymentState
  summary: SummaryState
  transactions: TransactionsState
  transactionMetadata: TransactionMetadataState
  transactionType: TransactionType
  worldpay: WorldpayState
}
