import React from 'react'
import * as data from '../constants/FormFieldData'
import FormRow from './ReusableComponents/FormRow'
import CategoryContainer from './CategoryContainer'

export default function ShoeSizes() {
  return (
    <CategoryContainer>
      <FormRow {...data.shoeRow} />
    </CategoryContainer>
  )
}
