import React, {Suspense} from 'react'
import PropTypes from 'prop-types'
import {Admin, Resource} from 'react-admin'
import {connect} from 'react-redux'
import {ConnectedRouter} from 'react-router-redux'
import {Route, Redirect, Switch} from 'react-router-dom'
import {createStructuredSelector} from 'reselect'

import customRoutes from './routes'
import history from './history'
import {isLoggedIn} from './redux/selectors/session'
import theme from './theme'
import EnvironmentContext from './contexts/envContext'

import {ProductSetList, ProductSetEdit, ProductSetCreate} from './components/ProductSets'
import AppLayout from './components/shared/AppLayout'
import Dashboard from './components/shared/Dashboard'

import LogInPage from './pages/AuthPages/LogIn'

const App = ({isLoggedIn}) => {
  return !isLoggedIn ? (
    <ConnectedRouter history={history}>
      <Switch>
        <Redirect from="/logout" to="/" />
        <Route path="/" component={LogInPage} />
      </Switch>
    </ConnectedRouter>
  ) : (
    <Suspense fallback={<div>Loading...</div>}>
      <EnvironmentContext.Provider>
        <Admin
          appLayout={AppLayout}
          customRoutes={customRoutes}
          dashboard={Dashboard}
          history={history}
          locale="en"
          theme={theme}
        >
          <Resource
            name="product-set"
            list={ProductSetList}
            edit={ProductSetEdit}
            create={ProductSetCreate}
            options={{label: 'Product Sets'}}
          />
        </Admin>
      </EnvironmentContext.Provider>
    </Suspense>
  )
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn,
})

export default connect(mapStateToProps)(App)
