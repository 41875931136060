import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import {Title} from 'react-admin'

const Dashboard = () => (
  <Card>
    <Title title="The Black Tux Admin" />
    <CardHeader title="Welcome to The Black Tux Admin Page" />
  </Card>
)

export default Dashboard
