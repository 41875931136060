import {TransactionMetadata, TransactionReason} from '../../../types'
import {fsa, fsaError, fsaPayload, fsaPayloadByKeys} from '../../../redux/actions/helpers'

export enum PaymentPortalReasonsActionName {
  GetEligibleTransactionReasonsFailure = 'paymentPortal/get eligible transaction reasons failed',
  GetEligibleTransactionReasons = 'paymentPortal/get eligible transaction reasons',
  GetEligibleTransactionReasonsPending = 'paymentPortal/get eligible transaction reasons pending',
  GetEligibleTransactionReasonsSuccess = 'paymentPortal/get eligible transaction reasons succeeded',
  SetActiveReason = 'paymentPortal/setActiveReason',
  GetDataForReasonPending = 'paymentPortal/getDataForReasonPending',
  GetDataForReasonSuccess = 'paymentPortal/getDataForReasonSuccess',
  GetDataForReasonFailure = 'paymentPortal/getDataForReasonFailure',
}

interface GetEligibleTransactionReasonsFailureAction {
  type: PaymentPortalReasonsActionName.GetEligibleTransactionReasonsFailure
}
interface GetEligibleTransactionReasonsAction {
  type: PaymentPortalReasonsActionName.GetEligibleTransactionReasons
}
interface GetEligibleTransactionReasonsPendingAction {
  type: PaymentPortalReasonsActionName.GetEligibleTransactionReasonsPending
}
interface GetEligibleTransactionReasonsSuccessAction {
  type: PaymentPortalReasonsActionName.GetEligibleTransactionReasonsSuccess
  payload: TransactionReason[]
}
export interface SetActiveReasonAction {
  type: PaymentPortalReasonsActionName.SetActiveReason
  payload: {
    reason: TransactionReason
  }
}
export interface GetDataForReasonFailureAction {
  type: PaymentPortalReasonsActionName.GetDataForReasonFailure
}
export interface GetDataForReasonPendingAction {
  type: PaymentPortalReasonsActionName.GetDataForReasonPending
}
export interface GetDataForReasonSuccessAction {
  type: PaymentPortalReasonsActionName.GetDataForReasonSuccess
  payload: GetDataForReasonSuccessActionPayload
}
interface GetDataForReasonSuccessActionPayload {
  metadata: TransactionMetadata
}

export type ActivePaymentTypeActions =
  | GetEligibleTransactionReasonsFailureAction
  | GetEligibleTransactionReasonsAction
  | GetEligibleTransactionReasonsPendingAction
  | GetEligibleTransactionReasonsSuccessAction
  | SetActiveReasonAction

type GetEligibleTransactionReasonsFailureFn = (error: string) => GetEligibleTransactionReasonsFailureAction
export type GetEligibleTransactionReasonsFn = (orderId: number) => GetEligibleTransactionReasonsAction
type GetEligibleTransactionReasonsPendingFn = () => GetEligibleTransactionReasonsPendingAction
type GetEligibleTransactionReasonsSuccessFn = (
  payload: TransactionReason[],
) => GetEligibleTransactionReasonsSuccessAction
export type SetActiveReasonFn = (reason: TransactionReason) => SetActiveReasonAction
export type GetDataForReasonFailureFn = () => GetDataForReasonFailureAction
export type GetDataForReasonPendingFn = () => GetDataForReasonPendingAction
export type GetDataForReasonSuccessFn = (payload: GetDataForReasonSuccessActionPayload) => GetDataForReasonSuccessAction

export const getEligibleTransactionReasonsFailure: GetEligibleTransactionReasonsFailureFn = fsaError(
  PaymentPortalReasonsActionName.GetEligibleTransactionReasonsFailure,
)
export const getEligibleTransactionReasons: GetEligibleTransactionReasonsFn = fsaPayloadByKeys(
  PaymentPortalReasonsActionName.GetEligibleTransactionReasons,
  ['orderId'],
)
export const getEligibleTransactionReasonsPending: GetEligibleTransactionReasonsPendingFn = fsa(
  PaymentPortalReasonsActionName.GetEligibleTransactionReasonsPending,
)
export const getEligibleTransactionReasonsSuccess: GetEligibleTransactionReasonsSuccessFn = fsaPayload(
  PaymentPortalReasonsActionName.GetEligibleTransactionReasonsSuccess,
)
export const setActiveReason = fsaPayloadByKeys(PaymentPortalReasonsActionName.SetActiveReason, [
  'reason',
]) as SetActiveReasonFn
export const getDataForReasonPending = fsa(
  PaymentPortalReasonsActionName.GetDataForReasonPending,
) as GetDataForReasonPendingFn
export const getDataForReasonSuccess = fsaPayload(
  PaymentPortalReasonsActionName.GetDataForReasonSuccess,
) as GetDataForReasonSuccessFn
export const getDataForReasonFailure = fsa(
  PaymentPortalReasonsActionName.GetDataForReasonFailure,
) as GetDataForReasonFailureFn
