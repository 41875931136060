import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col} from 'reactstrap'

function CategoryContainer({header, children}) {
  return (
    <Row className="sp-category-container">
      <Col>
        {header ? <h5 className="sp-category-header">{header}</h5> : null}
        <section>{children}</section>
      </Col>
    </Row>
  )
}

CategoryContainer.defaultProps = {
  header: null,
}

CategoryContainer.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default CategoryContainer
