import React from 'react'
import PropTypes from 'prop-types'

const SubPageTitle = ({action, record}) => (
  <span>
    {action} - {record.name}
  </span>
)

SubPageTitle.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
  }),
  action: PropTypes.string.isRequired,
}

export default SubPageTitle
