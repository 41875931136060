import React from 'react'
import capitalize from 'lodash/capitalize'
import {BooleanInput, FormDataConsumer, FormTab, FunctionField, LongTextInput, TextField, TextInput} from 'react-admin'

import PublishProductButton from '../../buttons/PublishProductButton'

import {isNotGroup, isSingle} from '../../utils'

// TODO: Static for now until we have the Display-Categories GraphQL queries ready in another ticket
const displayCategories = {
  accessory: 'Accessory',
  belts: 'Belts',
  'cufflinks-studs': 'Cufflinks & Studs',
  jackets: 'Jackets',
  misc: 'Misc',
  'neckwear-2': 'Neckwear',
  outfits: 'Outfits',
  pants: 'Pants',
  shirts: 'Shirts',
  suits: 'Suits',
  shoes: 'Shoes',
  tuxedos: 'Tuxedos',
  vests: 'Vests',
  'vest-pant': 'Vest & Pant',
}

const ProductDetailsTab = ({record}) =>
  record && (
    <FormTab label="Product Details">
      <FunctionField label="Type" source="setType" render={({setType}) => `${capitalize(setType)}`} />
      <TextInput label="Name" source="name" />
      {isNotGroup(record.setType) ? (
        <FormDataConsumer>{(props) => <TextInput {...props} label="Rent Price" source="rentPrice" />}</FormDataConsumer>
      ) : (
        <TextField label="Rent Price" source="rentPrice" />
      )}
      <TextInput label="Buy Price" source="buyPrice" />
      <BooleanInput label="Is Purchasable" source="isPurchasable" />
      {isSingle(record.setType) && <TextField label="Style Code" source="style" />}
      <TextField label="Product Set ID" source="productSetId" />
      <LongTextInput label="Description" source="description" />
      <FunctionField label="Category" render={({setSpecs}) => `${displayCategories[setSpecs.displayCategorySlug]}`} />
      <TextField label="URL Slug" source="urlSlug" />
      <TextField label="Slug" source="slug" />
      <FunctionField
        label="Product URL"
        render={(record) => `/${record.setSpecs.displayCategorySlug}/${record.urlSlug}`}
      />
      <br />
      <PublishProductButton />
    </FormTab>
  )

export default ProductDetailsTab
