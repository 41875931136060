import React from 'react'
import PropTypes from 'prop-types'
import {Icon} from 'react-fa'
import {connect} from 'react-redux'
import {openSiteMenu} from '../../actions/siteMenu'
import ToolTimeButton from '../ToolTimeButton'

export const SiteMenuOpen = (props) => (
  <ToolTimeButton
    buttonTextTitle={<Icon name="bars" className="fa-lg" />}
    color="dark"
    icon
    onClick={props.openSiteMenu}
    size="md"
    textAlign="center"
    type="button"
  />
)

SiteMenuOpen.propTypes = {
  openSiteMenu: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  openSiteMenu: () => dispatch(openSiteMenu()),
})

export default connect(null, mapDispatchToProps)(SiteMenuOpen)
