import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Icon} from 'react-fa'
import {UncontrolledTooltip} from 'reactstrap'
import Prediction from './Prediction'
import * as store from '../constants/store'
import * as en from '../constants/enums'
import {parseProbabilities, valueToLabel} from '../constants/lib'

// To convert jacket length predictions to full words
function letterToWord(prediction, jacketLengthMap) {
  const convertToWord = valueToLabel(jacketLengthMap)
  return {...prediction, value: convertToWord(prediction.value)}
}

function Predictions({probabilities, size, jacketLengthMap}) {
  const sizeProbabilities =
    size === en.predictionJacketLength
      ? probabilities.map((prob) => letterToWord(prob, jacketLengthMap))
      : probabilities
  const [highestPrediction, ...otherPredictions] = parseProbabilities(sizeProbabilities)
  const id = `prediction-${size}`
  return (
    <div className="sp-display-field">
      {highestPrediction ? (
        <div className="sp-prediction">
          <span id={id}>
            <Prediction prediction={highestPrediction} />
            <Icon name="chevron-right" className="sp-chevron-right-sm" />
          </span>
        </div>
      ) : (
        <div>N/A</div>
      )}
      {otherPredictions.length > 0 && (
        <UncontrolledTooltip placement="right" target={id}>
          {otherPredictions.map((prediction) => (
            <div key={`${prediction.value}-${prediction.probability}`}>
              <Prediction prediction={prediction} />
            </div>
          ))}
        </UncontrolledTooltip>
      )}
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    probabilities: store.predictions(state, ownProps.size) || undefined,
    jacketLengthMap: store.sizeFormFields(state)[en.assignedApiJacketLength],
  }
}

Predictions.defaultProps = {
  probabilities: [{value: 'none', probability: -1}],
}

Predictions.propTypes = {
  probabilities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      probability: PropTypes.number.isRequired,
    }),
  ),
  size: PropTypes.string.isRequired,
  jacketLengthMap: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default connect(mapStateToProps)(Predictions)
