/* eslint-disable */
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import {connect} from 'react-redux'
import qs from 'qs'

import {fetchAuthInfToken, fetchAuthJwtToken} from '../../legacy/actions/session'
import useOnce from '../../hooks/useOnce';
import Throbber from '../../components/shared/Throbber';
import { Container, ContainerWithActions } from './Container'

interface Props extends RouteComponentProps {
  fetchInfToken: () => void
  fetchJwtToken: (inf: string) => void
}

const LogInPage: React.FC<Props> = ({fetchInfToken, fetchJwtToken, location}) => {
  const {inf} = qs.parse(location.search, {ignoreQueryPrefix: true})

  useOnce(() => {
    if (inf) {
      fetchJwtToken(inf as string)
    }
  })

  const handleLogin = () => {
    fetchInfToken()
  }

  return inf ? (
    <Container>
      <Throbber />
    </Container>
  ) : (
    <ContainerWithActions title='Sign In' iconColor="iconRed" buttonText="Sign In With One Login" onClick={handleLogin}  />
  )
}

export default connect(null, {
  fetchInfToken: fetchAuthInfToken,
  fetchJwtToken: fetchAuthJwtToken,
})(LogInPage)
