import {initialize, getFormValues} from 'redux-form'
import * as en from '../constants/enums'
import {getFromObj} from '../constants/lib'

// FORM INITIALIZATION FUNCTION

/* Initializing the sizes form requires checking a few things
   First, if survey or assigned sizes were passed in, use those values
   Else, do we have values for the survey or assigned sizes in the form already? If so, use those.
   If we have neither of the above, use the default survey/assigned sizes form values
*/
export default function initializeForm({survey, assignedSizes}) {
  return (dispatch, getState) => {
    const state = getState()
    const currentValues = getFormValues(en.formName)(state)
    const currentSurvey = currentValues ? getFromObj('survey', currentValues) : null
    const currentAssignedSizes = currentValues ? getFromObj('assigned', currentValues) : null

    const initSurvey =
      (survey && en.survey.convertToFormFields(survey)) ||
      currentSurvey ||
      en.survey.convertToFormFields(en.survey.defaultApiFields())

    const initAssignedSizes =
      (assignedSizes && en.assignedSizes.convertToFormFields(assignedSizes)) ||
      currentAssignedSizes ||
      en.assignedSizes.convertToFormFields(en.assignedSizes.defaultApiFields())

    const initialValues = {...initSurvey, ...initAssignedSizes}
    return dispatch(initialize(en.formName, initialValues))
  }
}
