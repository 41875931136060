import * as paymentPortalCustomerActions from '../../actions/PaymentPortal/customer'
import {all, call, fork, put, takeLatest} from 'redux-saga/effects'

import {isGoodResponse} from '../../../api/helpers'
import * as usersApi from '../../../api/UsersApiClient'

export function* getCustomerInfoWorker({payload: {customerId}}) {
  yield put(paymentPortalCustomerActions.getCustomerInfoPending())

  try {
    const {data, status} = yield call(usersApi.getUserInfo, customerId)

    if (isGoodResponse(status)) {
      yield put(paymentPortalCustomerActions.getCustomerInfoSuccess(data))
    } else {
      throw new Error('Unable to fetch customer info.')
    }
  } catch (e) {
    yield put(paymentPortalCustomerActions.getCustomerInfoFailure(e))
  }
}

export function* getCustomerInfoSaga() {
  yield takeLatest(paymentPortalCustomerActions.PPCustomerAction.GET_CUSTOMER_INFO, getCustomerInfoWorker)
}

export default function* paymentPortalCustomerSagas() {
  yield all([fork(getCustomerInfoSaga)])
}
