import React, {lazy, Suspense, useEffect, useState} from 'react'

const DefaultLoading = () => <div />

/**
 * A higher-order component that wraps React's lazy-loading functionality and adds the ability
 * to set a delay before showing the loading indicator (idea stolen from react-loadable).
 * @param {function} loader - The function that calls import() to load the lazy-loaded code.
 * @param {number} [delay=200] - Milliseconds to delay before showing the loading indicator.
 * @param {function} [loading] - Component that renders the loading indicator.
 * @returns {function} The lazy-loaded component.
 */
const asyncComponent = (loader, {delay = 200, loading = DefaultLoading} = {}) => {
  const LazyComponent = lazy(loader)
  const LoadingComponent = loading

  const AsyncComponent = (props) => {
    const [isDelayPassed, setDelayPassed] = useState(false)

    useEffect(() => {
      const timeout = setTimeout(() => setDelayPassed(true), delay)
      return () => clearTimeout(timeout)
    }, [])

    return (
      <Suspense fallback={isDelayPassed ? <LoadingComponent /> : null}>
        <LazyComponent {...props} />
      </Suspense>
    )
  }

  AsyncComponent.preload = loader

  return AsyncComponent
}

export default asyncComponent
