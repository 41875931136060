import {reducer} from '../helpers'
import {getSkuCode} from '../../../components/ManualTransactions/utils'

import {
  ADD_ITEM_TO_PRODUCT_LIST,
  DISPLAY_SNACKBAR,
  HIDE_SNACKBAR,
  REMOVE_ITEM_FROM_PRODUCT_LIST,
} from '../../actions/MTI/productsToAdd'

const initialState = {
  allProductOrderIds: [],
  isSnackbarActive: false,
  productsByOrderId: {},
  snackbarMessage: '',
}

export default reducer(initialState, (state, {payload, type}) => {
  switch (type) {
    case ADD_ITEM_TO_PRODUCT_LIST:
      const productOrderId = `${payload.id}:${Date.now()}`
      return {
        productsByOrderId: {
          ...state.productsByOrderId,
          [productOrderId]: {
            ...payload.product,
            productOrderId,
            skuCode: getSkuCode(payload.product.relatedProduct, payload.userSizes),
          },
        },
        allProductOrderIds: [...state.allProductOrderIds, productOrderId],
      }
    case REMOVE_ITEM_FROM_PRODUCT_LIST:
      const updatedProductsByOrderId = {...state.productsByOrderId}
      delete updatedProductsByOrderId[payload.id]
      return {
        productsByOrderId: updatedProductsByOrderId,
        allProductOrderIds: [...state.allProductOrderIds.filter((id) => id !== payload.id)],
      }
    case DISPLAY_SNACKBAR:
      return {
        isSnackbarActive: true,
        snackbarMessage: payload.message,
      }
    case HIDE_SNACKBAR:
      return {
        isSnackbarActive: false,
        snackbarMessage: '',
      }
    default:
      return null
  }
})
