import 'isomorphic-fetch'
import Raven from 'raven-js'
import logErrorException from '../../utils/logErrorException'

export function iterablePayload(eventName, email, dataFields) {
  return {
    eventName,
    email,
    dataFields,
  }
}

export function sendEmail(eventName, email, data) {
  let statusCode

  return fetch(window.__RUNTIME_CONFIG__.REACT_APP_ITERABLE_WORKFLOW_URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      api_key: window.__RUNTIME_CONFIG__.REACT_APP_ITERABLE_API_KEY,
    },
    body: JSON.stringify(iterablePayload(eventName, email, data)),
  })
    .then((res) => {
      statusCode = res.status
      return res.json()
    })
    .then((responseBody) => {
      if (statusCode < 400) {
        return responseBody
      }
      throw responseBody
    })
    .catch((error) => {
      Raven.setExtraContext({iterableError: error})
      logErrorException('sendEmail', statusCode, error)
    })
}
