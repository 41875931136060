import {
  PRODUCT_OK,
  PRODUCTS_OK,
  PRODUCTS_NOT_OK,
  IMPACTED_PRODUCTS_OK,
  IMPACTED_PRODUCTS_NOT_OK,
  SINGLE_PRODUCT_OK,
  SINGLE_PRODUCT_NOT_OK,
  RESET_SOLD_OUT_STATE,
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  products: [],
  rawProducts: [],
  displayedProduct: null,
  mostRecentError: null,
  impactedProducts: [],
}

function productsShim(products) {
  const array = []
  products.forEach((product) => {
    const obj = {}
    obj.value = product.slug
    obj.label = product.name
    array.push(obj)
  })
  return array
}

function findProduct(products, slug) {
  return products.find((product) => product.slug === slug)
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRODUCTS_OK:
      return {
        ...state,
        products: productsShim(action.payload.results),
        rawProducts: action.payload.results,
      }
    case PRODUCTS_NOT_OK:
      return {
        ...state,
        mostRecentError: action.payload,
      }
    case PRODUCT_OK:
      return {
        ...state,
        displayedProduct: findProduct(state.rawProducts, action.slug),
      }
    case IMPACTED_PRODUCTS_OK:
      return {
        ...state,
        impactedProducts: action.payload,
      }
    case IMPACTED_PRODUCTS_NOT_OK:
      return {
        ...state,
        mostRecentError: action.payload,
      }
    case SINGLE_PRODUCT_OK:
      return {
        ...state,
        displayedProduct: action.payload,
      }
    case SINGLE_PRODUCT_NOT_OK:
      return {
        ...state,
        mostRecentError: action.payload,
      }
    case RESET_SOLD_OUT_STATE:
      return {
        ...state,
        displayedProduct: null,
        impactedProducts: [],
      }
    default:
      return state
  }
}
