import {all, call, fork, put, select, take, takeLatest} from 'redux-saga/effects'

import * as modalActions from '../../actions/modal'
import * as paymentPortalSelectors from '../../selectors/PaymentPortal/index'
import {addTransactionItems, removeTransactionItem} from '../../actions/PaymentPortal/transactionItems'
import {createChargesSummaryPayload, createRefundsSummaryPayload} from './helpers'
import {getChargesSummaryTotals, getRefundsSummaryTotals, PPSummaryAction} from '../../actions/PaymentPortal/summary'
import {PaymentPortalTransactionItemsActionName} from '../../actions/PaymentPortal/transactionItems'
import {TransactionType} from '../../../types'

export function* updateTransactionItemWorker({payload}) {
  const transactionType = yield select(paymentPortalSelectors.transactionType)
  if (transactionType === TransactionType.Charges) {
    yield call(updateChargeTransactionItemWorker, payload)
  } else {
    yield call(updateRefundTransactionItemWorker, payload)
  }
}

// TODO Let revisit this once charges is complete
export function* updateChargeTransactionItemWorker(payload) {
  const requestBody = yield call(createChargesSummaryPayload, payload.items)
  yield put(getChargesSummaryTotals(requestBody))

  const getChargesSummaryTotalsAction = yield take([
    PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_FAILURE,
    PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_SUCCESS,
  ])

  if (getChargesSummaryTotalsAction.type !== PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_SUCCESS) {
    return new Error(getChargesSummaryTotalsAction.payload)
  }

  if (payload.updateAction === PaymentPortalTransactionItemsActionName.AddTransactionItems) {
    yield put(addTransactionItems(payload.items))

    const modifiedItems = yield select(paymentPortalSelectors.transactionItems)
    const requestBody = yield call(createChargesSummaryPayload, modifiedItems)
    yield put(getChargesSummaryTotals(requestBody))

    yield put(modalActions.closeModal())
  }

  if (payload.updateAction === PaymentPortalTransactionItemsActionName.RemoveTransactionItem) {
    yield put(removeTransactionItem(payload.itemId))

    const modifiedItems = yield select(paymentPortalSelectors.transactionItems)
    const requestBody = yield call(createChargesSummaryPayload, modifiedItems)
    yield put(getChargesSummaryTotals(requestBody))
  }
}

export function* updateRefundTransactionItemWorker(payload) {
  const requestBody = yield call(createRefundsSummaryPayload, payload.items)
  yield put(getRefundsSummaryTotals(requestBody))

  const getRefundsSummaryTotalsAction = yield take([
    PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_FAILURE,
    PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_SUCCESS,
  ])

  if (getRefundsSummaryTotalsAction.type !== PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_SUCCESS) {
    return new Error(getRefundsSummaryTotalsAction.payload)
  }

  if (payload.updateAction === PaymentPortalTransactionItemsActionName.AddTransactionItems) {
    yield put(addTransactionItems(payload.items))

    const modifiedItems = yield select(paymentPortalSelectors.transactionItems)
    const requestBody = yield call(createRefundsSummaryPayload, modifiedItems)
    yield put(getRefundsSummaryTotals(requestBody))

    yield put(modalActions.closeModal())
  }

  if (payload.updateAction === PaymentPortalTransactionItemsActionName.RemoveTransactionItem) {
    yield put(removeTransactionItem(payload.itemId))

    const modifiedItems = yield select(paymentPortalSelectors.transactionItems)
    const requestBody = yield call(createRefundsSummaryPayload, modifiedItems)
    yield put(getRefundsSummaryTotals(requestBody))
  }
}

export function* updateTransactionItemSaga() {
  yield takeLatest(
    [
      PPSummaryAction.TOGGLE_HAS_APPLIED_ACCOUNT_BALANCE,
      PaymentPortalTransactionItemsActionName.UpdateTransactionItems,
    ],
    updateTransactionItemWorker,
  )
}

export default function* paymentPortalTransactionItemsSagas() {
  yield all([fork(updateTransactionItemSaga)])
}
