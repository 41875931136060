import noop from 'lodash/noop'
import head from 'lodash/head'
import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'

import * as modalActions from '../../actions/modal'
import * as processPaymentActions from '../../actions/PaymentPortal/processPayment'
import * as summaryActions from '../../actions/PaymentPortal/summary'

import * as customerSelectors from '../../selectors/PaymentPortal/customer'
import * as orderSelectors from '../../selectors/PaymentPortal/order'
import * as summarySelectors from '../../selectors/PaymentPortal/summary'
import * as transactionsSelectors from '../../selectors/PaymentPortal/transactions'
import * as transactionTypeSelectors from '../../selectors/PaymentPortal/transactionType'

import * as sessionSelectors from '../../selectors/session'
import * as worldpaySelectors from '../../selectors/worldpay'

import * as helperSagas from './helpers'

import * as ppApi from '../../../api/PaymentPortal'
import {onStatus} from '../../../api/helpers'
import {setProcessOrderPaymentError} from './utils'
import {snakeCaseObjectKeys} from '../../../utilities/formatting'
import {TransactionType} from '../../../types'

export function* createPostPaymentPayload() {
  const createdByUser = yield select(sessionSelectors.adminEmail)
  const charges = yield select(transactionsSelectors.transactionItems)
  const cardExpiration = yield select(worldpaySelectors.worldpayExpirationDate)
  const paypageRegistrationId = yield select(worldpaySelectors.paypageRegistrationId)
  const isCardOnFileCharge = yield select(worldpaySelectors.isCardOnFileCharge)
  const applyAccountBalance = yield select(summarySelectors.hasAppliedAccountBalance)
  const formZipCode = yield select(worldpaySelectors.formZipCode)

  const postData = {
    applyAccountBalance,
    createdByUser,
    charges,
    isCardOnFileCharge,
    cardExpiration: isCardOnFileCharge ? null : cardExpiration,
    paypageRegistrationId: isCardOnFileCharge ? null : paypageRegistrationId,
    zipCode: formZipCode,
  }

  return snakeCaseObjectKeys(postData)
}

export function* postPaymentSagaWorker() {
  const transactionType = yield select(transactionTypeSelectors.transactionType)

  if (transactionType === TransactionType.Charges) {
    yield call(postChargesWorkerSaga)
  } else {
    yield call(postRefundsWorkerSaga)
  }
}

export function* postChargesWorkerSaga() {
  yield put(processPaymentActions.postPaymentPending())
  yield put(modalActions.openPageOverlayThrobber())

  try {
    const orderId = yield select(orderSelectors.orderId)
    const formattedPostData = yield call(createPostPaymentPayload)

    const response = yield call(ppApi.postOrderCharges, orderId, formattedPostData)

    onStatus({
      OK: noop,
      DEFAULT: ({data: {errors}, status}) => setProcessOrderPaymentError(head(errors), status),
    })(response)

    yield put(processPaymentActions.postPaymentSuccess())
    yield put(modalActions.closeModal())

    // On success, we need to display the updated account balance remaining
    const userId = yield select(customerSelectors.customerId)
    yield put(summaryActions.getUserAccountBalance(userId))
  } catch (error) {
    yield put(processPaymentActions.postPaymentFailure(error))
    yield put(modalActions.openPaymentFailedModal())
  }
}

export function* postRefundsWorkerSaga() {
  yield put(processPaymentActions.postPaymentPending())
  yield put(modalActions.openPageOverlayThrobber())

  try {
    const transactionItems = yield select(transactionsSelectors.transactionItems)
    const payload = yield call(helperSagas.createRefundsSummaryPayload, transactionItems)
    const response = yield call(ppApi.postRefunds, payload)

    onStatus({
      OK: noop,
      DEFAULT: ({data: {errors}, status}) => setProcessOrderPaymentError(head(errors), status),
    })(response)

    yield put(processPaymentActions.postPaymentSuccess())
    yield put(modalActions.closeModal())
  } catch (error) {
    yield put(processPaymentActions.postPaymentFailure(error))
    yield put(modalActions.openPaymentFailedModal())
  }
}

export function* postPaymentSaga() {
  yield takeLatest(processPaymentActions.ProcessPaymentActionName.PostPayment, postPaymentSagaWorker)
}

export default function* paymentPortalProcessPaymentSagas() {
  yield all([fork(postPaymentSaga)])
}
