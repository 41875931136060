import {applyMiddleware, compose, createStore} from 'redux'
import persistState from 'redux-localstorage'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import {routerMiddleware} from 'react-router-redux'
import {fromJS} from 'immutable'

import rootReducer from './reducer'
import rootSaga from './sagas'
import ravenMiddleware from './ravenMiddleware'

export default function createAdminStore(browserHistory, dataProvider) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const getStorageConfig = () => ({
    key: 'the-black-tux-admin',
    serialize: (store) => (store && store.session ? JSON.stringify(store.session.toJS()) : store),
    deserialize: (state) => ({
      session: state ? fromJS(JSON.parse(state)) : fromJS({}),
    }),
  })

  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    rootReducer,
    composeEnhancer(
      applyMiddleware(thunkMiddleware, sagaMiddleware, ravenMiddleware, routerMiddleware(browserHistory)),
      persistState('session', getStorageConfig()),
    ),
  )

  sagaMiddleware.run(rootSaga(dataProvider))
  return store
}
