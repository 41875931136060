import * as apiHelpers from '../../../api/helpers'

type processPaymentErrorOptions = {
  [key: number]: string
}

export const defaultProcessPaymentError =
  'Unable to process payment. Please try again. If issue persists, please report issue to a customer care lead at TBT headquarters.'

export const processPaymentError: processPaymentErrorOptions = {
  [apiHelpers.BAD_REQUEST]:
    'Unable to process payment. Please review to make sure payment information is accurate and complete.',
  [apiHelpers.UNAUTHORIZED]:
    'Unable to process payment for one or more of the reason(s) selected. If you believe this is an error, please report issue to a customer care lead at TBT headquarters.',
}
