import get from 'lodash/get'
import {createSelector} from 'reselect'

// Standard

export const productsWithSoldOutDates = (state) => get(state, 'soldOutDates.productsWithSoldOutDates')
export const soldOutSearchValue = (state) => get(state, 'soldOutDates.soldOutSearchValue')
export const mostRecentError = (state) => get(state, 'soldOutDates.mostRecentError')

// Computed

export const soldOutsHasLoaded = createSelector(
  productsWithSoldOutDates,
  mostRecentError,
  ($soldOuts, $error) => $soldOuts.length > 0 && !$error,
)

export const hasError = createSelector(mostRecentError, ($error) => !!$error)
