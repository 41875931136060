import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {EditController, EditView, SaveButton, TabbedForm, Toolbar, refreshView as refreshViewAction} from 'react-admin'

import SubPageTitle from '../shared/SubPageTitle'
import {ProductDetailsTab, ProductSpecsTab, ProductImagesTab, ProductsInSetTab} from './tabs/edit'
import './product-sets.css'

import {editValidation} from './tabs/edit/editValidation'

const ProductSetEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

class ProductSetEdit extends Component {
  static propTypes = {
    refreshView: PropTypes.func.isRequired,
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.refreshView()
    }, 500)
  }

  render() {
    /**
     * The 'Edit' component was unpacked into its controller and view components in order to access the 'record'
     * object which is provided by the EditController via render props. We use the 'record' object's properties to
     * conditionally render fields in the ProductDetailsTab and ProductsInSetTab components.
     * https://github.com/marmelab/react-admin/blob/master/UPGRADE.md#aor-dependent-input-was-removed
     */
    return (
      <EditController {...this.props}>
        {(controllerProps) => (
          <EditView {...this.props} {...controllerProps} title={<SubPageTitle action="Edit" />}>
            <TabbedForm validate={editValidation} redirect={false} toolbar={<ProductSetEditToolbar />}>
              {ProductDetailsTab(controllerProps)}
              {ProductSpecsTab()}
              {ProductsInSetTab(controllerProps)}
              {ProductImagesTab()}
            </TabbedForm>
          </EditView>
        )}
      </EditController>
    )
  }
}

const mapStateToProps = (state) => ({
  location: state.router.location,
})

export default connect(mapStateToProps, {refreshView: refreshViewAction})(ProductSetEdit)
