import {TransactionReason} from '../../../types'
import {reducer} from '../helpers'

import {ApiRequestStates} from '../../../api/const'
import * as reasonActions from '../../actions/PaymentPortal/reasons'

// TODO: add charge/refund distinction
export interface ActivePaymentTypeState {
  eligibleTransactionReasons: TransactionReason[]
  requestState: ApiRequestStates
  transactionReason: TransactionReason | null
}

const initialState: ActivePaymentTypeState = {
  eligibleTransactionReasons: [],
  requestState: ApiRequestStates.IDLE,
  transactionReason: null,
}

export default reducer(
  initialState,
  (
    state: ActivePaymentTypeState,
    action: reasonActions.ActivePaymentTypeActions,
  ): Partial<ActivePaymentTypeState> | null => {
    switch (action.type) {
      case reasonActions.PaymentPortalReasonsActionName.GetEligibleTransactionReasonsFailure:
        return {
          requestState: ApiRequestStates.ERROR,
        }
      case reasonActions.PaymentPortalReasonsActionName.GetEligibleTransactionReasons:
        return {
          requestState: initialState.requestState,
        }
      case reasonActions.PaymentPortalReasonsActionName.GetEligibleTransactionReasonsPending:
        return {
          requestState: ApiRequestStates.PENDING,
        }
      case reasonActions.PaymentPortalReasonsActionName.GetEligibleTransactionReasonsSuccess:
        return {
          eligibleTransactionReasons: action.payload,
          requestState: ApiRequestStates.SUCCESS,
        }
      case reasonActions.PaymentPortalReasonsActionName.SetActiveReason:
        return {
          transactionReason: action.payload.reason,
        }
      default:
        return null
    }
  },
)
