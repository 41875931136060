import * as React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'

import Page from '../Page'
import useStyles from './useStyles'

interface Props {
  title: string
  buttonText: string
  onClick: () => void
  iconColor: 'iconRed' | 'iconGreen'
}

export const Container: React.FC = ({children}) => {
  const styles = useStyles()

  return (
    <Page title="The Black Tux Admin">
      <Box className={styles.container}>{children}</Box>
    </Page>
  )
}

export const ContainerWithActions: React.FC<Props> = ({title, iconColor, buttonText, onClick}) => {
  const styles = useStyles()

  return (
    <Container>
      <Box className={styles.box}>
        <Avatar className={styles.icon}>
          <LockOutlinedIcon className={styles[iconColor]} />
        </Avatar>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <Box className={styles.button}>
          <Button type="submit" fullWidth variant="contained" onClick={onClick}>
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
