// Easy to use loading animations for ToolTime pages. Uses SVG animations
import React from 'react'
import PropTypes from 'prop-types'
// Loads in SVGS. Current types available: bars, cylon, spin
import * as svgLoaders from '../static/svg'
// Enumerated Animation Types
const ANIMATION = {
  BARS: 'bars',
  CYLON: 'cylon',
  SPIN: 'spin',
}

function ToolTimeLoading(props) {
  const {type} = props
  const svgAnimation = svgLoaders[type]
  const svgStyle = {
    fill: props.color,
    height: props.height,
    width: props.width,
    top: props.top,
    position: props.position,
    transform: props.transform ? `translate(${props.translateX}, ${props.translateY})` : null,
  }
  return <img className="svg-loading" style={svgStyle} src={svgAnimation} alt="loading" />
}

ToolTimeLoading.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  top: PropTypes.string,
  position: PropTypes.string,
  transform: PropTypes.bool,
  translateY: PropTypes.string,
  translateX: PropTypes.string,
}

ToolTimeLoading.defaultProps = {
  color: '#ffffff',
  height: '50px',
  type: ANIMATION.SPIN,
  width: null,
  top: null,
  transform: true,
  position: 'absolute',
  translateY: '0%',
  translateX: '0%',
}

export default ToolTimeLoading
