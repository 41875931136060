import {reducer} from '../helpers'
import {
  PPSummaryAction,
  GetChargesSummaryTotalsFailure,
  GetChargesSummaryTotals,
  GetChargesSummaryTotalsPending,
  GetChargesSummaryTotalsSuccess,
  GetRefundsSummaryTotalsFailure,
  GetRefundsSummaryTotals,
  GetRefundsSummaryTotalsPending,
  GetRefundsSummaryTotalsSuccess,
  GetUserAccountBalance,
  GetUserAccountBalanceFailure,
  GetUserAccountBalancePending,
  GetUserAccountBalanceSuccess,
  ToggleHasAppliedAccountBalance,
} from '../../actions/PaymentPortal/summary'
import {ApiRequestStates} from '../../../api/const'

export interface SummaryState {
  accountBalance: number
  accountBalanceToApply: number
  hasAppliedAccountBalance?: boolean
  balanceDue: number
  subtotal: number
  tax: number
  total: number
  promoCodeAmount?: number
}

interface RequestStatuses {
  getSummaryTotalsStatus: ApiRequestStates
  getUserAccountBalanceStatus: ApiRequestStates
}

type State = SummaryState & RequestStatuses

const initialState: State = {
  accountBalance: 0,
  accountBalanceToApply: 0,
  balanceDue: 0,
  promoCodeAmount: 0,
  getSummaryTotalsStatus: ApiRequestStates.IDLE,
  getUserAccountBalanceStatus: ApiRequestStates.IDLE,
  hasAppliedAccountBalance: false,
  subtotal: 0,
  tax: 0,
  total: 0,
}

type SummaryAction =
  | GetChargesSummaryTotalsFailure
  | GetChargesSummaryTotals
  | GetChargesSummaryTotalsPending
  | GetChargesSummaryTotalsSuccess
  | GetRefundsSummaryTotalsFailure
  | GetRefundsSummaryTotals
  | GetRefundsSummaryTotalsPending
  | GetRefundsSummaryTotalsSuccess
  | GetUserAccountBalance
  | GetUserAccountBalanceFailure
  | GetUserAccountBalancePending
  | GetUserAccountBalanceSuccess
  | ToggleHasAppliedAccountBalance

export default reducer(initialState, (state: State, action: SummaryAction): Partial<State> | null => {
  switch (action.type) {
    case PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS:
    case PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS:
      return {
        getSummaryTotalsStatus: ApiRequestStates.IDLE,
      }
    case PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_FAILURE:
    case PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_FAILURE:
      return {
        getSummaryTotalsStatus: ApiRequestStates.ERROR,
      }
    case PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_PENDING:
    case PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_PENDING:
      return {
        getSummaryTotalsStatus: ApiRequestStates.PENDING,
      }
    case PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_SUCCESS: {
      const {accountBalanceToApply, subtotal, subtotalWithTax, tax, total} = action.payload
      return {
        accountBalanceToApply,
        balanceDue: total,
        subtotal,
        tax,
        total: subtotalWithTax,
        getSummaryTotalsStatus: ApiRequestStates.SUCCESS,
      }
    }
    case PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_SUCCESS: {
      const {accountBalanceToApply, subtotal, subtotalWithTax, tax, total} = action.payload

      return {
        accountBalanceToApply,
        balanceDue: total,
        subtotal,
        tax,
        total: subtotalWithTax,
        getSummaryTotalsStatus: ApiRequestStates.SUCCESS,
      }
    }
    case PPSummaryAction.GET_USER_ACCOUNT_BALANCE:
      return {
        getUserAccountBalanceStatus: ApiRequestStates.IDLE,
      }
    case PPSummaryAction.GET_USER_ACCOUNT_BALANCE_PENDING:
      return {
        getUserAccountBalanceStatus: ApiRequestStates.PENDING,
      }
    case PPSummaryAction.GET_USER_ACCOUNT_BALANCE_FAILURE:
      return {
        getUserAccountBalanceStatus: ApiRequestStates.ERROR,
      }
    case PPSummaryAction.GET_USER_ACCOUNT_BALANCE_SUCCESS:
      return {
        accountBalance: action.payload.accountBalance,
        getUserAccountBalanceStatus: ApiRequestStates.SUCCESS,
      }
    case PPSummaryAction.TOGGLE_HAS_APPLIED_ACCOUNT_BALANCE:
      return {
        hasAppliedAccountBalance: !state.hasAppliedAccountBalance,
      }
    default:
      return null
  }
})
