import {all, call, fork, put, take, takeLatest} from 'redux-saga/effects'
import get from 'lodash/get'

import * as mtiOrderActions from '../../actions/MTI/order'
import * as mtiUserActions from '../../actions/MTI/user'
import * as ordersActions from '../../actions/orders'

export function* getCurrentOrderDetailsWorker({payload: {orderId}}) {
  // Build payload shape to pass into getCurrentOrderDetailsSuccess()
  const payload = {}
  yield put(mtiOrderActions.getCurrentOrderDetailsPending())
  try {
    const [order, products] = yield all([call(getOrderInfo, orderId), call(getProductsByOrderId, orderId)])
    payload.order = order
    payload.products = products
    const userId = get(payload, 'order.user.id')
    const customer = yield call(getCustomerInfo, userId)
    payload.customer = customer
    yield call(getUserSizes, userId)
    yield put(mtiOrderActions.getCurrentOrderDetailsSuccess(payload))
  } catch (e) {
    yield put(mtiOrderActions.getCurrentOrderDetailsFailure(e))
  }
}

// Get order info
export function* getOrderInfo(orderId) {
  yield put(ordersActions.getOrderInfo(orderId))
  const getOrderInfoAction = yield take([ordersActions.GET_ORDER_INFO_FAILURE, ordersActions.GET_ORDER_INFO_SUCCESS])
  if (getOrderInfoAction.type === ordersActions.GET_ORDER_INFO_FAILURE) {
    return new Error(getOrderInfoAction.payload)
  }
  return getOrderInfoAction.payload
}

// Get products in order
export function* getProductsByOrderId(orderId) {
  yield put(ordersActions.getProductsByOrderId(orderId))
  const getProductsByOrderIdAction = yield take([
    ordersActions.GET_PRODUCTS_BY_ORDER_ID_FAILURE,
    ordersActions.GET_PRODUCTS_BY_ORDER_ID_SUCCESS,
  ])
  if (getProductsByOrderIdAction.type === ordersActions.GET_PRODUCTS_BY_ORDER_ID_FAILURE) {
    return new Error(getProductsByOrderIdAction.payload)
  }
  return getProductsByOrderIdAction.payload
}

// Get customer info
export function* getCustomerInfo(userId) {
  yield put(mtiUserActions.getUserInfo(userId))
  const getUserInfoAction = yield take([mtiUserActions.GET_USER_INFO_FAILURE, mtiUserActions.GET_USER_INFO_SUCCESS])
  if (getUserInfoAction.type === mtiUserActions.GET_USER_INFO_FAILURE) {
    return new Error(getUserInfoAction.payload)
  }
  return getUserInfoAction.payload
}

// Get user sizes
export function* getUserSizes(userId) {
  yield put(mtiUserActions.getUserSizes(userId))
  const getUserSizesAction = yield take([mtiUserActions.GET_USER_SIZES_FAILURE, mtiUserActions.GET_USER_SIZES_SUCCESS])
  if (getUserSizesAction.type === mtiUserActions.GET_USER_SIZES_FAILURE) {
    return new Error(getUserSizesAction.payload)
  }
  return
}

export function* getCurrentOrderDetailsSaga() {
  yield takeLatest(mtiOrderActions.GET_CURRENT_ORDER_DETAILS, getCurrentOrderDetailsWorker)
}

export function* getCurrentOrderDetailsFailureWorker({payload}) {
  yield call(alert, `${payload.message}, please try again.`)
}

export function* getCurrentOrderDetailsFailureSaga() {
  yield takeLatest(mtiOrderActions.GET_CURRENT_ORDER_DETAILS_FAILURE, getCurrentOrderDetailsFailureWorker)
}

export default function* mtiOrderSagas() {
  yield all([fork(getCurrentOrderDetailsSaga), fork(getCurrentOrderDetailsFailureSaga)])
}
