import {ApiRequestStates} from '../../../api/const'
import {reducer} from '../helpers'
import {
  GET_CURRENT_ORDER_DETAILS,
  GET_CURRENT_ORDER_DETAILS_FAILURE,
  GET_CURRENT_ORDER_DETAILS_PENDING,
  GET_CURRENT_ORDER_DETAILS_SUCCESS,
} from '../../actions/MTI/order'
import {WorldpayAction} from '../../actions/worldpay'

const initialState = {
  id: null,
  arrivalDate: '',
  transactionType: '',
  products: [],
  customer: {
    id: null,
    email: '',
    last4CreditCardDigits: '',
  },
  event: {
    date: '',
  },
  shipping: {
    address1: '',
    address2: null,
    city: '',
    state: '',
    zip: '',
  },
  getCurrentOrderDetailsStatus: ApiRequestStates.IDLE,
}

export default reducer(initialState, (state, action) => {
  switch (action.type) {
    case GET_CURRENT_ORDER_DETAILS:
      return {
        ...initialState,
      }
    case GET_CURRENT_ORDER_DETAILS_FAILURE:
      return {
        getCurrentOrderDetailsStatus: ApiRequestStates.ERROR,
      }
    case GET_CURRENT_ORDER_DETAILS_PENDING:
      return {
        getCurrentOrderDetailsStatus: ApiRequestStates.PENDING,
      }
    case GET_CURRENT_ORDER_DETAILS_SUCCESS:
      const {order = {}, products = [], customer = {}} = action.payload
      return {
        id: order.id,
        arrivalDate: order.arrivalDate,
        transactionType: order.transactionType,
        products,
        customer: {
          id: order.user.id,
          email: order.user.email,
          last4CreditCardDigits: customer.last4CreditCardDigits,
        },
        event: {
          date: order.event.date,
        },
        shipping: {
          address1: order.shippingAddress1,
          address2: order.shippingAddress2,
          city: order.shippingCity,
          state: order.shippingState,
          zip: order.shippingZip,
        },
        getCurrentOrderDetailsStatus: ApiRequestStates.SUCCESS,
      }
    case WorldpayAction.GET_WORLDPAY_INFO_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          last4CreditCardDigits: action.payload.lastFour,
        },
      }
    default:
      return null
  }
})
