import {fsa, fsaError, fsaPayload} from './helpers'

export enum WorldpayAction {
  GET_WORLDPAY_INFO = 'worldpay/get worldpay info',
  GET_WORLDPAY_INFO_FAILURE = 'worldpay/get worldpay info failed',
  GET_WORLDPAY_INFO_PENDING = 'worldpay/get worldpay info pending',
  GET_WORLDPAY_INFO_SUCCESS = 'worldpay/get worldpay info succeeded',
  HANDLE_WORLDPAY_AUTHORIZATION = 'worldpay/handle worldpay authorization',
}

export interface GetWorldpayInfo {
  type: WorldpayAction.GET_WORLDPAY_INFO
  payload: {
    formZipCode: string | undefined
  }
}

interface FailurePayload {
  responseCode: string
  timeout: string
}

export interface GetWorldpayInfoFailure {
  type: WorldpayAction.GET_WORLDPAY_INFO_FAILURE
  payload: FailurePayload
}

export interface GetWorldpayInfoPending {
  type: WorldpayAction.GET_WORLDPAY_INFO_PENDING
}

interface SuccessPayload {
  expMonth: string
  expYear: string
  lastFour: string
  paypageRegistrationId: string
}

export interface GetWorldpayInfoSuccess {
  type: WorldpayAction.GET_WORLDPAY_INFO_SUCCESS
  payload: SuccessPayload
}

interface AuthorizationPayload {
  message: string
  response: string
  timeout: number
}

export interface HandleWorldPayAuthorization {
  type: WorldpayAction.GET_WORLDPAY_INFO_SUCCESS
  payload: AuthorizationPayload
}

export type GetWorldpayInfoFn = (zipCode?: string | undefined) => GetWorldpayInfo
type GetWorldpayInfoFailureFn = () => GetWorldpayInfoFailure
type GetWorldpayInfoPendingFn = () => GetWorldpayInfoPending
type GetWorldpayInfoSuccessFn = () => GetWorldpayInfoSuccess
type HandleWorldPayAuthorizationFn = () => HandleWorldPayAuthorization

export const getWorldpayInfo: GetWorldpayInfoFn = fsaPayload(WorldpayAction.GET_WORLDPAY_INFO)
export const getWorldpayInfoFailure: GetWorldpayInfoFailureFn = fsaError(WorldpayAction.GET_WORLDPAY_INFO_FAILURE)
export const getWorldpayInfoPending: GetWorldpayInfoPendingFn = fsa(WorldpayAction.GET_WORLDPAY_INFO_PENDING)
export const getWorldpayInfoSuccess: GetWorldpayInfoSuccessFn = fsaPayload(WorldpayAction.GET_WORLDPAY_INFO_SUCCESS)
export const handleWorldPayAuthorization: HandleWorldPayAuthorizationFn = fsaPayload(
  WorldpayAction.HANDLE_WORLDPAY_AUTHORIZATION,
)
