import * as data from './DataTypes'

export const userQueryName = 'userPerId'

export const query = `
  query ($id:String!, $authToken:String) {
    ${userQueryName}(id:$id, authToken:$authToken) {
      ${data.user}
      sizes {
        ${data.size}
      }
      fitProfile {
        ${data.fitProfile}
        ${data.fitPredictions}
      }
    }
    sizeValidators(authToken:$authToken) {
      chestToVestMap {
        XS
        S
        M
        L
        XL
        XXL
        XXXL
        XXXXL
      }
      jacketLengthToVestLengthMap {
        L
      }
      required
    }
    fitProfileValidators(authToken:$authToken) {
      birthdate
      weight
      required
    }
    adminSizesFormFields(authToken:$authToken) {
      fitProfile {
        chest { ${data.formFields} }
        heightFeet { ${data.formFields} }
        heightInches { ${data.formFields} }
        inseam { ${data.formFields} }
        jacketLength { ${data.formFields} }
        neck { ${data.formFields} }
        preferredFit { ${data.formFields} }
        shoe { ${data.formFields} }
        shoulderShape { ${data.formFields} }
        sleeve { ${data.formFields} }
        stomachShape { ${data.formFields} }
        waist { ${data.formFields} }
      }
      sizes {
        belt { ${data.formFields} }
        chest { ${data.formFields} }
        inseam { ${data.formFields} }
        inseamSizeAdjust { ${data.formFields} }
        jacketLength { ${data.formFields} }
        jacketSleeveAdjust { ${data.formFields} }
        neck { ${data.formFields} }
        pantFit { ${data.formFields} }
        reason { ${data.formFields} }
        shirtFit { ${data.formFields} }
        shoe { ${data.formFields} }
        sleeve { ${data.formFields} }
        vest { ${data.formFields} }
        vestLength { ${data.formFields} }
        waist { ${data.formFields} }
      }
    }
  }
`
