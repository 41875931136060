import {fsa, fsaError, fsaPayload, fsaPayloadByKeys} from '../helpers'

export const GET_USER_ACCOUNT_BALANCE = 'mti/get user account balance'
export const getUserAccountBalance = fsaPayloadByKeys(GET_USER_ACCOUNT_BALANCE, ['userId'])

export const GET_USER_ACCOUNT_BALANCE_PENDING = 'mti/get user account balance pending'
export const getUserAccountBalancePending = fsa(GET_USER_ACCOUNT_BALANCE_PENDING)

export const GET_USER_ACCOUNT_BALANCE_FAILURE = 'mti/get user account balance failed'
export const getUserAccountBalanceFailure = fsaError(GET_USER_ACCOUNT_BALANCE_FAILURE)

/**
 * @param {Object} payload
 * @param {Number} payload.accountBalance           Total balance available on user's account
 * @param {Array}  payload.itemizedAccountBalances  Itemized list of account balance
 */
export const GET_USER_ACCOUNT_BALANCE_SUCCESS = 'mti/get user account balance succeeded'
export const getUserAccountBalanceSuccess = fsaPayload(GET_USER_ACCOUNT_BALANCE_SUCCESS)

export const APPLY_ACCOUNT_BALANCE = 'mti/apply account balance'
export const applyAccountBalance = fsa(APPLY_ACCOUNT_BALANCE)

export const REMOVE_ACCOUNT_BALANCE = 'mti/remove account balance'
export const removeAccountBalance = fsa(REMOVE_ACCOUNT_BALANCE)

export const GET_USER_INFO_FAILURE = 'user/get user info failed'
export const getUserInfoFailure = fsaError(GET_USER_INFO_FAILURE)

export const GET_USER_INFO = 'user/get user info'
export const getUserInfo = fsaPayloadByKeys(GET_USER_INFO, ['userId'])

export const GET_USER_INFO_PENDING = 'user/get user info pending'
export const getUserInfoPending = fsa(GET_USER_INFO_PENDING)

export const GET_USER_INFO_SUCCESS = 'user/get user info succeeded'
export const getUserInfoSuccess = fsaPayload(GET_USER_INFO_SUCCESS)

export const GET_USER_SIZES_FAILURE = 'user/get user sizes failed'
export const getUserSizesFailure = fsaError(GET_USER_SIZES_FAILURE)

export const GET_USER_SIZES = 'user/get user sizes'
export const getUserSizes = fsaPayloadByKeys(GET_USER_SIZES, ['userId'])

export const GET_USER_SIZES_PENDING = 'user/get user sizes pending'
export const getUserSizesPending = fsa(GET_USER_SIZES_PENDING)

export const GET_USER_SIZES_SUCCESS = 'user/get user sizes succeeded'
export const getUserSizesSuccess = fsaPayload(GET_USER_SIZES_SUCCESS)
