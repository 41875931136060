import ProcessOrderPaymentError from '../../../exceptions'
import {fsa, fsaError} from '../../../redux/actions/helpers'

export enum ProcessPaymentActionName {
  PostPayment = 'paymentPortal/post payment',
  PostPaymentFailure = 'paymentPortal/post payment failure',
  PostPaymentPending = 'paymentPortal/post payment pending',
  PostPaymentSuccess = 'paymentPortal/post payment success',
}

export interface PostPaymentAction {
  type: ProcessPaymentActionName.PostPayment
}

export type PostPaymentFn = () => PostPaymentAction

export const postPayment = fsa(ProcessPaymentActionName.PostPayment) as PostPaymentFn

export interface PostPaymentPendingAction {
  type: ProcessPaymentActionName.PostPaymentPending
}

export type PostPaymentPendingFn = () => PostPaymentPendingAction

export const postPaymentPending = fsa(ProcessPaymentActionName.PostPaymentPending) as PostPaymentPendingFn

export interface PostPaymentSuccessAction {
  type: ProcessPaymentActionName.PostPaymentSuccess
}

export type PostPaymentSuccessFn = () => PostPaymentSuccessAction

export const postPaymentSuccess = fsa(ProcessPaymentActionName.PostPaymentSuccess) as PostPaymentSuccessFn

export interface PostPaymentFailureAction {
  type: ProcessPaymentActionName.PostPaymentFailure
  payload: ProcessOrderPaymentError
}

export type PostPaymentFailureFn = () => PostPaymentFailureAction

export const postPaymentFailure = fsaError(ProcessPaymentActionName.PostPaymentFailure) as PostPaymentFailureFn
