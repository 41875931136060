import {all, fork, put, takeLatest} from 'redux-saga/effects'

import * as mtiProductsToAddActions from '../../actions/MTI/productsToAdd'

export function* addItemToProductListWorker({payload: {product}}) {
  const {name: productName} = product
  const message = `${productName} has been added!`
  yield put(mtiProductsToAddActions.displaySnackbar(message))
}

export function* addItemToProductListSaga() {
  yield takeLatest(mtiProductsToAddActions.ADD_ITEM_TO_PRODUCT_LIST, addItemToProductListWorker)
}

export default function* mtiProductsToAddSagas() {
  yield all([fork(addItemToProductListSaga)])
}
