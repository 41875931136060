import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import {ToolTimeInput} from './ToolTimeInput'

const ReduxFormCheckbox = ({id, name, disabled, label}) => (
  <label className="custom-control custom-checkbox" htmlFor={id}>
    <Field id={id} name={name} disabled={disabled} component={ToolTimeInput} type="checkbox" />
    <span className="custom-control-indicator" />
    <span className="custom-control-description">{label}</span>
  </label>
)

ReduxFormCheckbox.defaultProps = {
  disabled: false,
}

ReduxFormCheckbox.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default ReduxFormCheckbox
