import axios from 'axios'

import {camelCaseObjectKeys, transformOrdersItems} from '../../utilities/formatting'
import {
  ChargePaymentApiPayload,
  ChargesSummaryTotalsPayload,
  RefundsPaymentApiPayload,
  RefundsSummaryTotalsPayload,
  TransactionReason,
} from '../../types'
import {constructOptions, constructOptionsAndValidateStatus} from '../utils'

const {REACT_APP_MONOLITH_URL} = window.__RUNTIME_CONFIG__

export const getEligibleTransactionReasons = (orderId: number) =>
  axios
    .get(`${REACT_APP_MONOLITH_URL}/v1/orders/${orderId}/charge_reasons`, constructOptions())
    .then(camelCaseObjectKeys)

export const getOrdersRelatedToReason = (customerId: number, eventId: number, reason: TransactionReason) => {
  const params: string = [TransactionReason.AssetRecovery, TransactionReason.RentToKeep].includes(reason)
    ? `&user_id=${customerId}`
    : ''

  return axios
    .get(`${REACT_APP_MONOLITH_URL}/v1/events/${eventId}/orders?charge_reason=${reason}${params}`, constructOptions())
    .then(camelCaseObjectKeys)
    .then(transformOrdersItems(reason))
}

export const getAllOrders = (eventId: number, reason: TransactionReason) => {
  return axios
    .get(`${REACT_APP_MONOLITH_URL}/v1/events/${eventId}/orders?cancelled=True`, constructOptions())
    .then(camelCaseObjectKeys)
    .then(transformOrdersItems(reason))
}

export const getChargesSummaryTotals = (payload: ChargesSummaryTotalsPayload) =>
  axios.post(`${REACT_APP_MONOLITH_URL}/v1/charges/totals`, payload, constructOptions())

export const postOrderCharges = (orderId: number, payload: ChargePaymentApiPayload) =>
  axios
    .post(`${REACT_APP_MONOLITH_URL}/v1/orders/${orderId}/charges`, payload, constructOptionsAndValidateStatus())
    .then(camelCaseObjectKeys)

export const getRefundsSummaryTotals = (payload: RefundsSummaryTotalsPayload) =>
  axios.post(`${REACT_APP_MONOLITH_URL}/v1/refunds/totals`, payload, constructOptions())

export const postRefunds = (payload: RefundsPaymentApiPayload) =>
  axios
    .post(`${REACT_APP_MONOLITH_URL}/v1/refunds`, payload, constructOptionsAndValidateStatus())
    .then(camelCaseObjectKeys)
