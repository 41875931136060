import React, {Component} from 'react'
import PropTypes from 'prop-types'
import forEach from 'lodash/forEach'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import ActionSaveButton from '@material-ui/icons/Save'

import {saveImagePositions as saveImagePositionsAction} from '../../../actions/product'

class SaveImagePositionsButton extends Component {
  static propTypes = {
    productId: PropTypes.string.isRequired,
    saveImagePositions: PropTypes.func.isRequired,
    setImages: PropTypes.arrayOf(PropTypes.shape({})),
  }

  handleClick = () => {
    const {productId, saveImagePositions, setImages} = this.props
    forEach(setImages, (imageObj) => saveImagePositions(productId, imageObj))
  }

  render() {
    return (
      <Button color="primary" onClick={this.handleClick}>
        <ActionSaveButton />
        Save Positions
      </Button>
    )
  }
}

export default connect(null, {saveImagePositions: saveImagePositionsAction})(SaveImagePositionsButton)
