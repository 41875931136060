import {makeStyles} from '@material-ui/core/styles'

export default makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10vh',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    margin: '20px 0',
  },
  iconRed: {
    color: 'red',
  },
  iconGreen: {
    color: 'green',
  },
  button: {
    margin: '20px 0',
  },
})
