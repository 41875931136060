import * as en from './enums'

export const user = `
  lastLogin
  email
  fullName
  firstName
  userId
`

export const createdBy = `
  createdBy {
    fullName
    email
    userId
  }
`

export const size = `
  ${en.assignedApiBelt}
  ${en.assignedApiChest}
  createdOn
  ${en.assignedApiInseam}
  ${en.assignedApiInseamSizeAdjust}
  ${en.assignedApiJacketSleeveAdjust}
  ${en.assignedApiJacketLength}
  ${en.assignedApiNeck}
  ${en.assignedApiPantFit}
  ${en.assignedApiReason}
  ${en.assignedApiShirtFit}
  ${en.assignedApiShoe}
  ${en.assignedApiSizeChecked}
  ${en.assignedApiSleeve}
  ${en.assignedApiVest}
  ${en.assignedApiVestLength}
  ${en.assignedApiWaist}
  ${createdBy}
  `

export const fitProfile = `
  ${en.surveyApiBirthdate}
  ${en.surveyApiChest}
  createdOn
  ${en.surveyApiHeightFeet}
  ${en.surveyApiHeightInches}
  ${en.surveyApiInseam}
  ${en.surveyApiJacketLength}
  ${en.surveyApiNeck}
  ${en.surveyApiMeasuredAt}
  ${en.surveyApiPreferredFit}
  ${en.surveyApiShoe}
  ${en.surveyApiShoulderShape}
  ${en.surveyApiSleeve}
  ${en.surveyApiStomachShape}
  ${en.surveyApiWaist}
  ${en.surveyApiWeight}
  ${createdBy}
`

export const predictionProbabilities = `
  probabilities {
    value
    probability
  }
`

export const fitPredictions = `
  fitPredictions(authToken:$authToken) {
    predictionSetId
    ${en.predictionJacketLength} {
      ${predictionProbabilities}
    }
    ${en.predictionChest} {
      ${predictionProbabilities}
    }
    ${en.predictionInseam} {
      ${predictionProbabilities}
    }
    ${en.predictionWaist} {
      ${predictionProbabilities}
    }
    ${en.predictionSleeve} {
      ${predictionProbabilities}
    }
    ${en.predictionNeck} {
      ${predictionProbabilities}
    }
  }
`

export const formFields = `
  value
  label
`
