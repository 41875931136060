import {fsa} from '../helpers'

export enum PPTransactionTypeAction {
  SetTransactionTypeCharges = 'paymentPortal/set transaction type to charges',
  SetTransactionTypeRefunds = 'paymentPortal/set transaction type to refunds',
}

export interface SetTransactionTypeToCharges {
  type: PPTransactionTypeAction.SetTransactionTypeCharges
}

export interface SetTransactionTypeToRefunds {
  type: PPTransactionTypeAction.SetTransactionTypeRefunds
}

export type SetTransactionTypeToChargesFn = () => SetTransactionTypeToCharges
export type SetTransactionTypeToRefundsFn = () => SetTransactionTypeToRefunds

export const setTransactionTypeToCharges: SetTransactionTypeToChargesFn = fsa(
  PPTransactionTypeAction.SetTransactionTypeCharges,
)
export const setTransactionTypeToRefunds: SetTransactionTypeToRefundsFn = fsa(
  PPTransactionTypeAction.SetTransactionTypeRefunds,
)
