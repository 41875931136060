import {REDUX_FORM_NAME as RA_REDUX_FORM_NAME} from 'react-admin'
import {put, takeEvery} from 'redux-saga/effects'
import {reset} from 'redux-form'
import {get} from 'lodash'

/**
 * There's an issue where the redux-form state is not resetting if the user
 * successfully creates a record and proceeds to navigate to the create view again.
 * This prevents issues with submitting a new record containing data from a
 * previously created record.
 */
export default function* resetCreateViewForm() {
  yield takeEvery('@@redux-form/INITIALIZE', function* (action) {
    if (get(action, 'meta.form', '') === RA_REDUX_FORM_NAME) {
      yield put(reset(RA_REDUX_FORM_NAME))
    }
  })
}
