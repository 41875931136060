import React from 'react'
import * as data from '../constants/FormFieldData'
import CategoryContainer from './CategoryContainer'
import FormRow from './ReusableComponents/FormRow'
import {PreferredFitRow} from './PreferredFitRow'

export default function ShirtSizes() {
  return (
    <CategoryContainer header="Shirt Sizes">
      <FormRow {...data.neckRow} />
      <FormRow {...data.sleeveRow} />
      <PreferredFitRow {...data.shirtFitRow} />
    </CategoryContainer>
  )
}
