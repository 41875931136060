import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {flowRight} from 'lodash'
import {withStyles} from '@material-ui/core/styles'
import {BooleanField, Datagrid, EditButton, List, TextField} from 'react-admin'

import Throbber from '../shared/Throbber'

const styles = {
  headerStyle: {
    fontSize: 14,
  },
}

const ProductSetList = ({classes, isLoading, ...props}) => {
  function renderField({Field = TextField, ...props}) {
    return <Field headerClassName={classes.headerStyle} sortable={false} {...props} />
  }

  return (
    <List {...props} bulkActionButtons={false} exporter={false} perPage={20} title="Product Sets">
      {isLoading ? (
        <Throbber />
      ) : (
        <Datagrid>
          {renderField({source: 'name'})}
          {renderField({label: 'Category', source: 'setSpecs.displayCategoryName'})}
          {renderField({label: 'Slug', source: 'urlSlug'})}
          {renderField({Field: BooleanField, label: 'Purchasable', source: 'isPurchasable'})}
          {renderField({label: 'Rent Price', source: 'rentPrice', textAlign: 'right'})}
          {renderField({label: 'Buy Price', source: 'buyPrice', textAlign: 'right'})}
          {renderField({label: 'Type', source: 'setType'})}
          <EditButton />
        </Datagrid>
      )}
    </List>
  )
}

ProductSetList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({isLoading: state.admin.loading > 0})

export default flowRight(connect(mapStateToProps), withStyles(styles))(ProductSetList)
