import {all, fork} from 'redux-saga/effects'

import mtiOrderSagas from './order'
import mtiOrderSummarySagas from './orderSummary'
import mtiProductsToAddSagas from './productsToAdd'
import mtiUserSagas from './user'

export default function* mtiSagas() {
  yield all([fork(mtiOrderSagas), fork(mtiOrderSummarySagas), fork(mtiProductsToAddSagas), fork(mtiUserSagas)])
}
