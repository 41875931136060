import {fsa, fsaError, fsaPayload, fsaPayloadByKeys} from '../helpers'
import {
  ChargesSummaryTotalsPayload,
  ChargesSummaryTotalsResponse,
  RefundsSummaryTotalsPayload,
  RefundsSummaryTotalsResponse,
  UserAccountBalancePayload,
} from '../../../types'
import {UpdateTransactionItemPayload} from './transactionItems'

export enum PPSummaryAction {
  GET_CHARGES_SUMMARY_TOTALS_FAILURE = 'paymentPortal/get charges summary totals failed',
  GET_CHARGES_SUMMARY_TOTALS = 'paymentPortal/get charges summary totals',
  GET_CHARGES_SUMMARY_TOTALS_PENDING = 'paymentPortal/get charges summary totals pending',
  GET_CHARGES_SUMMARY_TOTALS_SUCCESS = 'paymentPortal/get charges summary totals succeeded',
  GET_REFUNDS_SUMMARY_TOTALS_FAILURE = 'paymentPortal/get refunds summary totals failed',
  GET_REFUNDS_SUMMARY_TOTALS = 'paymentPortal/get refunds summary totals',
  GET_REFUNDS_SUMMARY_TOTALS_PENDING = 'paymentPortal/get refunds summary totals pending',
  GET_REFUNDS_SUMMARY_TOTALS_SUCCESS = 'paymentPortal/get refunds summary totals succeeded',
  GET_USER_ACCOUNT_BALANCE = 'paymentPortal/get user account balance',
  GET_USER_ACCOUNT_BALANCE_SUCCESS = 'paymentPortal/get user account balance succeeded',
  GET_USER_ACCOUNT_BALANCE_FAILURE = 'paymentPortal/get user account balance failed',
  GET_USER_ACCOUNT_BALANCE_PENDING = 'paymentPortal/get user account balance pending',
  TOGGLE_HAS_APPLIED_ACCOUNT_BALANCE = 'paymentPortal/toggle has applied account balance',
}

export interface GetChargesSummaryTotalsFailure {
  type: PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_FAILURE
}
export interface GetChargesSummaryTotals {
  type: PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS
}
export interface GetChargesSummaryTotalsPending {
  type: PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_PENDING
}
export interface GetChargesSummaryTotalsSuccess {
  type: PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_SUCCESS
  payload: ChargesSummaryTotalsResponse
}

export interface GetRefundsSummaryTotalsFailure {
  type: PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_FAILURE
}
export interface GetRefundsSummaryTotals {
  type: PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS
}
export interface GetRefundsSummaryTotalsPending {
  type: PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_PENDING
}
export interface GetRefundsSummaryTotalsSuccess {
  type: PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_SUCCESS
  payload: RefundsSummaryTotalsResponse
}

export interface GetUserAccountBalance {
  type: PPSummaryAction.GET_USER_ACCOUNT_BALANCE
}

export interface GetUserAccountBalanceSuccess {
  type: PPSummaryAction.GET_USER_ACCOUNT_BALANCE_SUCCESS
  payload: UserAccountBalancePayload
}

export interface GetUserAccountBalanceFailure {
  type: PPSummaryAction.GET_USER_ACCOUNT_BALANCE_FAILURE
}

export interface GetUserAccountBalancePending {
  type: PPSummaryAction.GET_USER_ACCOUNT_BALANCE_PENDING
}

export interface ToggleHasAppliedAccountBalance {
  type: PPSummaryAction.TOGGLE_HAS_APPLIED_ACCOUNT_BALANCE
  payload: UpdateTransactionItemPayload
}

export type GetUserAccountBalanceFn = (customerId: number) => GetUserAccountBalance

type GetUserAccountBalanceSuccessFn = (payload: UserAccountBalancePayload) => GetUserAccountBalanceSuccess

type GetUserAccountBalanceFailureFn = (e: Error) => GetUserAccountBalanceFailure

type GetUserAccountBalancePendingFn = () => GetUserAccountBalancePending
export type ToggleHasAppliedAccountBalanceFn = (payload: UpdateTransactionItemPayload) => ToggleHasAppliedAccountBalance

type GetChargesSummaryTotalsFailureFn = (error: string) => GetChargesSummaryTotalsFailure

export type GetChargesSummaryTotalsFn = (payload: ChargesSummaryTotalsPayload) => GetChargesSummaryTotals

type GetChargesSummaryTotalsPendingFn = () => GetChargesSummaryTotalsPending

type GetChargesSummaryTotalsSuccessFn = (payload: ChargesSummaryTotalsResponse) => GetChargesSummaryTotalsSuccess

type GetRefundsSummaryTotalsFailureFn = (error: string) => GetRefundsSummaryTotalsFailure

export type GetRefundsSummaryTotalsFn = (payload: RefundsSummaryTotalsPayload) => GetRefundsSummaryTotals

type GetRefundsSummaryTotalsPendingFn = () => GetRefundsSummaryTotalsPending

type GetRefundsSummaryTotalsSuccessFn = (payload: RefundsSummaryTotalsResponse) => GetRefundsSummaryTotalsSuccess

export const getUserAccountBalance: GetUserAccountBalanceFn = fsaPayloadByKeys(
  PPSummaryAction.GET_USER_ACCOUNT_BALANCE,
  ['customerId'],
)

export const getUserAccountBalanceSuccess: GetUserAccountBalanceSuccessFn = fsaPayload(
  PPSummaryAction.GET_USER_ACCOUNT_BALANCE_SUCCESS,
)

export const getUserAccountBalanceFailure: GetUserAccountBalanceFailureFn = fsaError(
  PPSummaryAction.GET_USER_ACCOUNT_BALANCE_FAILURE,
)

export const getUserAccountBalancePending: GetUserAccountBalancePendingFn = fsa(
  PPSummaryAction.GET_USER_ACCOUNT_BALANCE_PENDING,
)

export const toggleHasAppliedAccountBalance: ToggleHasAppliedAccountBalanceFn = fsaPayload(
  PPSummaryAction.TOGGLE_HAS_APPLIED_ACCOUNT_BALANCE,
)

export const getChargesSummaryTotalsFailure: GetChargesSummaryTotalsFailureFn = fsaError(
  PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_FAILURE,
)

export const getChargesSummaryTotals: GetChargesSummaryTotalsFn = fsaPayload(PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS)

export const getChargesSummaryTotalsPending: GetChargesSummaryTotalsPendingFn = fsa(
  PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_PENDING,
)

export const getChargesSummaryTotalsSuccess: GetChargesSummaryTotalsSuccessFn = fsaPayload(
  PPSummaryAction.GET_CHARGES_SUMMARY_TOTALS_SUCCESS,
)

export const getRefundsSummaryTotalsFailure: GetRefundsSummaryTotalsFailureFn = fsaError(
  PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_FAILURE,
)

export const getRefundsSummaryTotals: GetRefundsSummaryTotalsFn = fsaPayload(PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS)

export const getRefundsSummaryTotalsPending: GetRefundsSummaryTotalsPendingFn = fsa(
  PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_PENDING,
)

export const getRefundsSummaryTotalsSuccess: GetRefundsSummaryTotalsSuccessFn = fsaPayload(
  PPSummaryAction.GET_REFUNDS_SUMMARY_TOTALS_SUCCESS,
)
