import * as mtiUserActions from '../../actions/MTI/user'
import {ApiRequestStates} from '../../../api/const'
import {reducer} from '../helpers'

const initialState = {
  accountBalance: 0,
  sizes: [],
  getAccountBalanceStatus: ApiRequestStates.IDLE,
  getUserSizesStatus: ApiRequestStates.IDLE,
  hasAppliedAccountBalance: true,
}

export default reducer(initialState, (state, action) => {
  switch (action.type) {
    case mtiUserActions.GET_USER_ACCOUNT_BALANCE:
      return {
        accountBalance: 0,
      }
    case mtiUserActions.GET_USER_ACCOUNT_BALANCE_PENDING:
      return {
        getAccountBalanceStatus: ApiRequestStates.PENDING,
      }
    case mtiUserActions.GET_USER_ACCOUNT_BALANCE_FAILURE:
      return {
        getAccountBalanceStatus: ApiRequestStates.ERROR,
      }
    case mtiUserActions.GET_USER_ACCOUNT_BALANCE_SUCCESS:
      return {
        accountBalance: action.payload.accountBalance,
        getAccountBalanceStatus: ApiRequestStates.SUCCESS,
      }
    case mtiUserActions.APPLY_ACCOUNT_BALANCE:
      return {
        hasAppliedAccountBalance: true,
      }
    case mtiUserActions.REMOVE_ACCOUNT_BALANCE:
      return {
        hasAppliedAccountBalance: false,
      }
    case mtiUserActions.GET_USER_SIZES:
      return {
        sizes: [],
      }
    case mtiUserActions.GET_USER_SIZES_FAILURE:
      return {
        getUserSizesStatus: ApiRequestStates.ERROR,
      }
    case mtiUserActions.GET_USER_SIZES_PENDING:
      return {
        getUserSizesStatus: ApiRequestStates.PENDING,
      }
    case mtiUserActions.GET_USER_SIZES_SUCCESS:
      return {
        sizes: action.payload,
        getUserSizesStatus: ApiRequestStates.SUCCESS,
      }
    default:
      return null
  }
})
