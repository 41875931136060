import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Table} from 'reactstrap'

const ToolTimeTable = (props) => {
  const {className, simpleTable, simpleTableImage, ...attributes} = props

  const classes = classNames(
    className,
    'tooltime-table',
    simpleTable ? 'simple-table' : false,
    simpleTableImage ? 'simple-table-image' : false,
  )
  return <Table {...attributes} className={classes} />
}

ToolTimeTable.defaultProps = {
  className: undefined,
  simpleTable: undefined,
  simpleTableImage: undefined,
}

ToolTimeTable.propTypes = {
  className: PropTypes.string,
  simpleTable: PropTypes.bool,
  simpleTableImage: PropTypes.bool,
}

export default ToolTimeTable
