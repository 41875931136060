import axios from 'axios'
import find from 'lodash/find'
import {showNotification, refreshView} from 'react-admin'
import {getAPI, getHeaders} from '../components/ProductSets/config'

// Product-Service Request
export const psRequest = ({data, dispatch, failMsg, method, successMsg, token, url}) => {
  axios({
    data,
    headers: getHeaders(token),
    method,
    url: `${getAPI()}${url}`,
  })
    .then(() => {
      dispatch(showNotification(successMsg))
      dispatch(refreshView())
      setTimeout(() => {
        dispatch(refreshView())
      }, 500)
    })
    .catch((e) => {
      console.error(e)
      dispatch(showNotification(`Error: ${failMsg}`, 'warning'))
    })
}

export const validateAddProductSet = (currentSetType, childSetType) => {
  let result = false
  if (currentSetType === 'kit') {
    result = childSetType === 'single'
  }
  if (currentSetType === 'group') {
    result = childSetType !== 'group'
  }
  return result
}

export const getProductSet = (productId) =>
  axios
    .get(`${getAPI()}/product-sets/${productId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })

export const getImageContentTypes = () =>
  axios
    .get(`${getAPI()}/product-set-images/content-types`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })

export const addProductImage = (productId, data, setImages) => (dispatch, getState) => {
  if (find(setImages, (image) => image.url === data.url)) {
    dispatch(showNotification('Error: Image already exists', 'warning'))
  } else {
    const token = getState().session.get('token')
    psRequest({
      data,
      dispatch,
      failMsg: 'Failed to add image',
      method: 'post',
      successMsg: 'Successfully added image',
      token,
      url: `/product-sets/${productId}/set-images`,
    })
  }
}

export const deleteProductImage = (productId, imageId) => (dispatch, getState) => {
  const token = getState().session.get('token')
  psRequest({
    dispatch,
    failMsg: 'Failed to deleted image',
    method: 'delete',
    successMsg: 'Successfully Deleted Image',
    token,
    url: `/product-sets/${productId}/set-images/${imageId}`,
  })
}

export const addProductToSet = (record, data) => (dispatch, getState) => {
  if (record.id === data.child_set) {
    dispatch(showNotification('Error: Cannot add a product set to itself', 'warning'))
  } else {
    getProductSet(data.child_set)
      .then((response) => {
        if (validateAddProductSet(record.setType, response.set_type)) {
          const token = getState().session.get('token')
          psRequest({
            data,
            dispatch,
            failMsg: 'Failed to add product',
            method: 'post',
            successMsg: 'Successfully added product',
            token,
            url: `/product-sets/${record.id}/set-children`,
          })
        } else {
          dispatch(showNotification('Cannot add this set type to product'))
        }
      })
      .catch((e) => {
        console.error(e)
        dispatch(showNotification('Failed to add product set: Invalid Product ID'))
      })
  }
}

export const deleteProductInSet = (productId, childId) => (dispatch, getState) => {
  const token = getState().session.get('token')
  psRequest({
    dispatch,
    failMsg: 'Failed to add child set',
    method: 'delete',
    successMsg: 'Successfully added child set',
    token,
    url: `/product-sets/${productId}/set-children/${childId}`,
  })
}

export const saveImagePositions = (productId, imageObj) => (dispatch, getState) => {
  const token = getState().session.get('token')
  psRequest({
    data: imageObj,
    dispatch,
    failMsg: 'Failed to save positions',
    method: 'put',
    successMsg: 'Successfully saved positions',
    token,
    url: `/product-sets/${productId}/set-images/${imageObj.id}`,
  })
}

export const publishProduct = (productId) => (dispatch, getState) => {
  const token = getState().session.get('token')
  psRequest({
    data: {is_published: true},
    dispatch,
    failMsg: 'Failed to publish product',
    method: 'put',
    successMsg: 'Successfully published product',
    token,
    url: `/product-sets/${productId}/publish`,
  })
}
