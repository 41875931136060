import {TransactionItem} from '../../../types'
import {fsaPayload, fsaPayloadByKeys} from '../../../redux/actions/helpers'

export enum PaymentPortalTransactionItemsActionName {
  AddTransactionItems = 'paymentPortal/add transaction items',
  RemoveTransactionItem = 'paymentPortal/remove transaction item',
  UpdateTransactionItems = 'paymentPortal/update transaction items',
}

export interface AddTransactionItemsAction {
  type: PaymentPortalTransactionItemsActionName.AddTransactionItems
  payload: {
    items: TransactionItem[]
  }
}

export type AddTransactionItemsFn = (items: TransactionItem[]) => AddTransactionItemsAction

export const addTransactionItems = fsaPayloadByKeys(PaymentPortalTransactionItemsActionName.AddTransactionItems, [
  'items',
]) as AddTransactionItemsFn

export interface RemoveTransactionItemAction {
  type: PaymentPortalTransactionItemsActionName.RemoveTransactionItem
  payload: string
}

export type RemoveTransactionItemFn = (itemId: string) => RemoveTransactionItemAction

export const removeTransactionItem = fsaPayload(
  PaymentPortalTransactionItemsActionName.RemoveTransactionItem,
) as RemoveTransactionItemFn

export interface UpdateTransactionItemPayload {
  items: TransactionItem[]
  updateAction?: PaymentPortalTransactionItemsActionName
  itemId?: string
}

export interface UpdateTransactionItemsAction {
  payload: UpdateTransactionItemPayload
}

export type UpdateTransactionItemsFn = (payload: UpdateTransactionItemPayload) => UpdateTransactionItemsAction

export const updateTransactionItems = fsaPayload(
  PaymentPortalTransactionItemsActionName.UpdateTransactionItems,
) as UpdateTransactionItemsFn
