import get from 'lodash/get'
import {Selector} from 'reselect'
import {TransactionItem} from '../../../types'
import {PaymentPortalReducer} from '../../reducers/PaymentPortal'

import {TransactionsState} from '../../reducers/PaymentPortal/transactions'

const transactionsState: Selector<PaymentPortalReducer, TransactionsState> = (state) =>
  get(state, 'paymentPortal.transactions')

export const transactionItems: Selector<PaymentPortalReducer, TransactionItem[]> = (state) =>
  get(transactionsState(state), 'items')
