import get from 'lodash/get'
import {Selector} from 'reselect'

import {RootState} from '../../../store'
import {TransactionReason} from '../../../types'

import {ActivePaymentTypeState} from '../../reducers/PaymentPortal/activePaymentType'

const activePaymentType: Selector<RootState, ActivePaymentTypeState> = (state) =>
  get(state, 'paymentPortal.activePaymentType')

export const transactionReason: Selector<RootState, TransactionReason | null> = (state) =>
  get(activePaymentType(state), 'transactionReason')

export const eligibleTransactionReasons: Selector<RootState, TransactionReason[]> = (state) =>
  get(activePaymentType(state), 'eligibleTransactionReasons')
