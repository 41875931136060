import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Alert} from 'reactstrap'

/**
 * ErrorAlert
 *
 * @param {object} children
 * @param {string} className
 * @param {object} props
 * @returns {*}
 * @constructor
 */
export function ErrorAlert({children, className, ...props}) {
  const alertClasses = classNames(className, ['tooltime-alerts'])
  return (
    <Alert className={alertClasses} {...props} color="danger">
      {children}
    </Alert>
  )
}

ErrorAlert.defaultProps = {
  className: undefined,
}

ErrorAlert.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

/**
 * SuccessAlert
 *
 * @param {object} children
 * @param {string} className
 * @param {object} props
 * @returns {*}
 * @constructor
 */
export function SuccessAlert({children, className, ...props}) {
  const alertClasses = classNames(className, ['tooltime-alerts'])
  return (
    <Alert className={alertClasses} {...props} color="success">
      {children}
    </Alert>
  )
}

SuccessAlert.defaultProps = {
  className: undefined,
}

SuccessAlert.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

/**
 * NotificationAlert
 *
 * @param {object} children
 * @param {string} className
 * @param {object} props
 * @returns {*}
 * @constructor
 */
export function NotificationAlert({children, className, color}) {
  const alertClasses = classNames(className, ['tooltime-alerts'])
  return (
    <Alert className={alertClasses} color={color}>
      {children}
    </Alert>
  )
}

NotificationAlert.defaultProps = {
  className: undefined,
}

NotificationAlert.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
}
