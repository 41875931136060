import * as data from './DataTypes'

export const assignedSizeMutationName = 'createSizes'

export const assignedSizeMutation = `
  mutation ($input:SizesInput!) {
    ${assignedSizeMutationName}(input:$input) {
      ${data.size}
    }
  }
`

export const surveyMutationName = 'createFitProfile'

export const surveyMutation = `
  mutation ($input:FitProfileInput!, $authToken:String) {
    ${surveyMutationName}(input:$input) {
      sizes {
        ${data.size}
      }
      fitProfile {
        ${data.fitProfile}
        ${data.fitPredictions}
      }
    }
  }
`
