import React from 'react'
import * as data from '../constants/FormFieldData'
import CategoryContainer from './CategoryContainer'
import SizeChangedEmailRow from './SizeChangedEmailRow'
import FormRow from './ReusableComponents/FormRow'

export default function SizeCheckDetails() {
  return (
    <CategoryContainer header="Size Check Details">
      <FormRow {...data.reasonRow} />
      <SizeChangedEmailRow />
    </CategoryContainer>
  )
}
