import {Customer, Order, OrderType, OrderUser, ShippingType} from '../index'

export enum TransactionType {
  Charges = 'charges',
  Refunds = 'refunds',
}

export enum TransactionReason {
  AccountBalanceCredit = 'account_balance_credit',
  AccountBalanceDebit = 'account_balance_debit',
  AssetRecovery = 'asset_recovery',
  Fee = 'rental_extension',
  Insurance = 'insurance',
  Miscellaneous = 'miscellaneous',
  Product = 'product',
  RentToKeep = 'rent_to_keep',
  Shipping = 'shipping',
}

export type TransactionReasonDisplayName =
  | 'Account Balance Credit'
  | 'Account Balance Debit'
  | 'Asset Recovery'
  | 'Fee'
  | 'Insurance'
  | 'Miscellaneous'
  | 'Order Items'
  | 'Rental Extension'
  | 'Rent-to-Keep'
  | 'Shipping'

/**
 * Informs finance what kind of charge or refund type an item is.
 */
export enum RevenueType {
  AccountBalanceCredit = 'account_balance_credit',
  AccountBalanceDebit = 'account_balance_debit',
  AssetRecovery = 'asset_recovery',
  BoxReimbursement = 'box_reimbursement',
  Fee = 'fee',
  Insurance = 'insurance',
  Miscellaneous = 'miscellaneous',
  Product = 'product',
  PromoCodeRedemption = 'promo_code_redemption',
  RentToKeep = 'rent_to_keep',
  Shipping = 'shipping',
  Tailoring = 'dry_cleaning_tailoring',
  TbtError = 'tbt_error',
}

/**
 * Generic interface for specifying what type of options each transaction reason type should map to.
 */
export interface TransactionReasonOptionsType<Type> {
  [TransactionReason.AccountBalanceCredit]: Type
  [TransactionReason.AccountBalanceDebit]: Type
  [TransactionReason.AssetRecovery]: Type
  [TransactionReason.Fee]: Type
  [TransactionReason.Insurance]: Type
  [TransactionReason.Miscellaneous]: Type
  [TransactionReason.Product]: Type
  [TransactionReason.RentToKeep]: Type
  [TransactionReason.Shipping]: Type
}

export interface RevenueTypeOptions<Type> {
  [RevenueType.AccountBalanceCredit]: Type
  [RevenueType.AccountBalanceDebit]: Type
  [RevenueType.AssetRecovery]: Type
  [RevenueType.BoxReimbursement]: Type
  [RevenueType.Fee]: Type
  [RevenueType.Insurance]: Type
  [RevenueType.Miscellaneous]: Type
  [RevenueType.Product]: Type
  [RevenueType.PromoCodeRedemption]: Type
  [RevenueType.RentToKeep]: Type
  [RevenueType.Shipping]: Type
  [RevenueType.Tailoring]: Type
  [RevenueType.TbtError]: Type
}

export interface EligibleTransactionReasonsMenuOption {
  label: TransactionReasonDisplayName
  value: TransactionReason
}

export interface TransactionViewItem {
  amount: number
  description: string
  id: string
  orderTitle: string
  reason: TransactionReason
  selectedTransactionId?: string
}

export interface AccountBalanceTransactionApiPayload {
  orderId: number
  reason: TransactionReason.AccountBalanceCredit
  userId: number | null
}

export interface AssetRecoveryTransactionApiPayload {
  orderId: number
  reason: TransactionReason.AssetRecovery
  shipmentItemId: string
}

export interface FeeTransactionApiPayload {
  orderId: number
  reason: TransactionReason.Fee
}

export interface MiscellaneousTransactionApiPayload {
  orderId: number
  reason: TransactionReason.Miscellaneous
}

export interface ProductTransactionApiPayload {
  orderId: number
  productSetId: string
  reason: TransactionReason.Product
}

export interface RentToKeepTransactionApiPayload {
  orderId: number
  reason: TransactionReason.RentToKeep
  shipmentItemId: string
}

export interface ShippingTransactionApiPayload {
  orderId: number
  reason: TransactionReason.Shipping
  partialShippingAmount?: number
  shippingType?: ShippingType
}

export type AccountBalanceTransactionItem = TransactionViewItem & AccountBalanceTransactionApiPayload
export type AssetRecoveryTransactionItem = TransactionViewItem & AssetRecoveryTransactionApiPayload
export type FeeTransactionItem = TransactionViewItem & FeeTransactionApiPayload
export type MiscellaneousTransactionItem = TransactionViewItem & MiscellaneousTransactionApiPayload
export type ProductTransactionItem = TransactionViewItem & ProductTransactionApiPayload
export type RentToKeepTransactionItem = TransactionViewItem & RentToKeepTransactionApiPayload
export type ShippingTransactionItem = TransactionViewItem & ShippingTransactionApiPayload

// TODO make union type as other reasons added
export type TransactionItem =
  | AccountBalanceTransactionItem
  | AssetRecoveryTransactionItem
  | FeeTransactionItem
  | MiscellaneousTransactionItem
  | RentToKeepTransactionItem
  | ShippingTransactionItem
  | ProductTransactionItem

// TODO make union type as other reasons added
export type ApiTransactionItem =
  | AccountBalanceTransactionApiPayload
  | AssetRecoveryTransactionApiPayload
  | FeeTransactionApiPayload
  | MiscellaneousTransactionApiPayload
  | RentToKeepTransactionApiPayload
  | ShippingTransactionApiPayload
  | ProductTransactionApiPayload

export interface TransactionOrderItemMetadata {
  id: string
  isAssetRecovered: boolean
  isAssetRecoveryEligible: boolean
  isRentToKeepPurchased: boolean
  isRentToKeepEligible: boolean
  productName: string
  photoUrl: string
  price: number | null
  skuCode: string
}

export interface TransactionOrderMetadataOrderInfo {
  id: number
  shipmentItems: TransactionOrderItemMetadata[]
  orderType: OrderType
}

export type TransactionOrderMetadata = TransactionOrderMetadataOrderInfo & {
  user: OrderUser
}

export interface TransactionMetadata {
  orders: TransactionOrderMetadata[]
}

export interface TransactionPayload {
  customer: Customer
  order: Order
}

// TODO: not sure if this is the most appropriate place to define this interface
// since a  user's account balance is not payment portal-specific
export interface UserAccountBalancePayload {
  accountBalance: number
}

export type TransactionOrderMetadataWithState = TransactionOrderMetadata & {
  checked: boolean
  disabled: boolean
}

export interface SoldOutPeriods {
  endDate: string
  startDate: string
}

export interface RelatedProductAttributes {
  styleCode: string
  buySoldOutPeriods?: SoldOutPeriods[]
  rentSoldOutPeriods?: SoldOutPeriods[]
}

export type ProductItemMetadata = {
  id: string
  name: string
  rentPrice: number
  buyPrice: number
  thumbnailUrl: string
  isPurchasable: boolean
  relatedProduct: RelatedProductAttributes
}

export * from './charges'
export * from './refunds'
