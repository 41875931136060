import React from 'react'
import PropTypes from 'prop-types'
import Predictions from '../Predictions'
import {FourColumnRow, Label} from './FormattedRows'
import {SurveySelectField, AssignedSizesSelectField} from './FormSelectFields'

/*
This forms the basic building block of the Size Form. A FourColumnRow where column 1 has the row
label, column 2 has a survey numeric select field, column 3 has a predicted size, and column 4 has
an assigned sizes numeric select field. If a row does not contain some of these things, you can
leave that column blank (ie. no predictions for the vest row) and pass in what you do want
displayed.
*/

export default function FormRow({label, survey, predictedSize, assignedSizes}) {
  return (
    <FourColumnRow
      col1={label && <Label label={label} />}
      col2={survey && <SurveySelectField {...survey} />}
      col3={predictedSize && <Predictions size={predictedSize} />}
      col4={assignedSizes && <AssignedSizesSelectField {...assignedSizes} />}
      rowClass="sp-form-row"
    />
  )
}

FormRow.defaultProps = {
  label: undefined,
  survey: undefined,
  predictedSize: undefined,
  assignedSizes: undefined,
}

FormRow.propTypes = {
  label: PropTypes.string,
  survey: PropTypes.object,
  predictedSize: PropTypes.string,
  assignedSizes: PropTypes.object,
}
