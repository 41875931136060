import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Button} from 'reactstrap'

const textAlignClasses = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
}

function ToolTimeButton({buttonTextTitle, buttonTextSubTitle, children, className, icon, textAlign, ...props}) {
  const buttonClasses = classNames(className, ['tooltime-button'], icon ? 'btn-icon' : false, {
    [textAlignClasses[textAlign]]: true,
  })

  return (
    <Button className={buttonClasses} {...props}>
      {children}
      <span className={icon ? '' : 'button-text-title'}>{buttonTextTitle}</span>
      {buttonTextSubTitle && <span className="button-text-subtitle">{buttonTextSubTitle}</span>}
    </Button>
  )
}

ToolTimeButton.defaultProps = {
  buttonTextSubTitle: undefined,
  children: undefined,
  className: undefined,
  icon: undefined,
  textAlign: 'center',
}

ToolTimeButton.propTypes = {
  buttonTextTitle: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  buttonTextSubTitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
}

export default ToolTimeButton
