export const SOLD_OUT_DATES_OK = 'Product-Mgmt/SOLD_OUT_DATES_OK'
export const SOLD_OUT_DATES_NOT_OK = 'Product-Mgmt/SOLD_OUT_DATES_NOT_OK'
export const SEARCH_SOLD_OUT = 'Product-Mgmt/SEARCH_SOLD_OUT'

export const PRODUCT_OK = 'Product-Mgmt/PRODUCT_OK'
export const PRODUCTS_OK = 'Product-Mgmt/PRODUCTS_OK'
export const PRODUCTS_NOT_OK = 'Product-Mgmt/PRODUCTS_NOT_OK'

export const SINGLE_PRODUCT_OK = 'Product-Mgmt/SINGLE_PRODUCT_OK'
export const SINGLE_PRODUCT_NOT_OK = 'Product-Mgmt/SINGLE_PRODUCT_NOT_OK'

export const IMPACTED_PRODUCTS_OK = 'Product-Mgmt/IMPACTED_PRODUCTS_OK'
export const IMPACTED_PRODUCTS_NOT_OK = 'Product-Mgmt/IMPACTED_PRODUCTS_NOT_OK'

export const ADD_SOLD_OUT_OK = 'Product-Mgmt/ADD_SOLD_OUT_OK'
export const ADD_SOLD_OUT_NOT_OK = 'Product-Mgmt/ADD_SOLD_OUT_NOT_OK'

export const PRODUCT_SOLD_OUT_PERIODS_OK = 'Product-Mgmt/PRODUCT_SOLD_OUT_PERIODS_OK'
export const PRODUCT_SOLD_OUT_PERIODS_NOT_OK = 'Product-Mgmt/PRODUCT_SOLD_OUT_PERIODS_NOT_OK'

export const DELETE_SOLD_OUT_OK = 'Product-Mgmt/DELETE_SOLD_OUT_OK'
export const DELETE_SOLD_OUT_NOT_OK = 'Product-Mgmt/DELETE_SOLD_OUT_NOT_OK'

export const RESET_SOLD_OUT_STATE = 'Product-Mgmt/RESET_SOLD_OUT_STATE'
