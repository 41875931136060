import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {requestSizePageData} from '../actions/ajax'

import ToolTimeContainer from '../../../components/ToolTimeContainer'
import QueryDisplay from '../../../components/QueryDisplay'
import SizesPage from './SizesPage'

import '../../../static/scss/core-tbt-admin/tbt-admin.scss'

class SizesEntry extends Component {
  static propTypes = {
    sizesPage: PropTypes.object.isRequired,
    requestSizePageData: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        userId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    session: PropTypes.shape({
      session: PropTypes.string,
    }).isRequired,
  }

  componentDidMount() {
    const variables = this.getQueryVariables()
    this.props.requestSizePageData(variables)
  }

  getQueryVariables() {
    const {session, match} = this.props
    return {
      authToken: session.get('token', false),
      id: match.params.userId,
    }
  }

  queryResults() {
    return {
      error: this.props.sizesPage.error,
      props: this.props.sizesPage.data,
    }
  }

  render() {
    return (
      <ToolTimeContainer>
        <QueryDisplay queryResults={this.queryResults()} success={<SizesPage />} />
      </ToolTimeContainer>
    )
  }
}

const mapStateToProps = ({session, sizesPage}) => ({session, sizesPage})

export default connect(mapStateToProps, {requestSizePageData})(SizesEntry)
