import get from 'lodash/get'
import {createSelector} from 'reselect'

import {ApiRequestStates} from '../../api/const'

export const worldpayExpMonth = (state) => get(state, 'worldpay.expMonth')
export const worldpayExpYear = (state) => get(state, 'worldpay.expYear')
export const worldpayLast4 = (state) => get(state, 'worldpay.lastFour')
export const isCardOnFileCharge = (state) => get(state, 'worldpay.isCardOnFileCharge')
export const paypageRegistrationId = (state) => get(state, 'worldpay.paypageRegistrationId')
export const getWorldpayInfoStatus = (state) => get(state, 'worldpay.getWorldpayInfoStatus')
export const formZipCode = (state) => get(state, 'worldpay.formZipCode')

export const worldpayExpirationDate = createSelector(worldpayExpMonth, worldpayExpYear, (month, year) =>
  month && year ? `${month}${year}` : null,
)

export const isGetWorldpayInfoStatusPending = createSelector(
  getWorldpayInfoStatus,
  (status) => status === ApiRequestStates.PENDING,
)
