import React from 'react'
import PropTypes from 'prop-types'

import SizeCheckedBadge from './SizeCheckedBadge'
import FloatLeftRight from '../../../components/ToolTimeFloatLeftRight'
import {FourColumnRow, Label} from './ReusableComponents/FormattedRows'

function SizeSectionContainer({children}) {
  return (
    <section className="sp-form-container">
      <header className="sp-column-headers-container">
        <FourColumnRow
          col2={<Label label="Survey Responses" />}
          col3={<Label label="Recommendations" />}
          col4={
            <FloatLeftRight
              floatContentLeft={<Label label="Assigned Sizes" />}
              floatContentRight={<SizeCheckedBadge />}
            />
          }
        />
      </header>
      <section>{children}</section>
    </section>
  )
}

SizeSectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SizeSectionContainer
