import {ApolloClient, HttpLink, InMemoryCache} from '@apollo/client'

import {getGraphQLHost, getHeaders} from './config'

export default new ApolloClient({
  link: new HttpLink({
    uri: getGraphQLHost(),
    headers: getHeaders(),
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {errorPolicy: 'all'},
    mutate: {errorPolicy: 'all'},
  },
})
