import * as actions from '../constants/ActionTypes'

const initialState = {
  data: undefined, // stores data returned from graphql
  isLoading: true,
  error: undefined,
  surveyIsDisabled: true,
  assignedSizesIsDisabled: false,
}

export default function sizeDataReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SIZE_QUERY_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case actions.SIZE_QUERY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case actions.SIZE_QUERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        data: action.data,
        initializeForm: true,
      }
    case actions.SURVEY_MUTATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          userPerId: {
            ...state.data.userPerId,
            fitProfile: action.data.fitProfile,
            sizes: action.data.sizes,
          },
        },
        surveyIsDisabled: true,
        assignedSizesIsDisabled: false,
      }
    case actions.ASSIGNED_SIZES_MUTATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          userPerid: {
            ...state.data.userPerId,
            sizes: action.data,
          },
        },
      }
    case actions.EDIT_SURVEY:
      return {
        ...state,
        surveyIsDisabled: false,
        assignedSizesIsDisabled: true,
      }
    case actions.RESET_EDITABLE_STATE:
      return {
        ...state,
        surveyIsDisabled: initialState.surveyIsDisabled,
        assignedSizesIsDisabled: initialState.assignedSizesIsDisabled,
      }
    case actions.EMAIL_ERROR:
      return {
        ...state,
        emailError: action.error,
      }
    default:
      return state
  }
}
