import React from 'react'

import {FormTab, LongTextInput, BooleanInput} from 'react-admin'

const ProductSpecsTab = () => (
  <FormTab label="Additional Specs">
    <LongTextInput label="Materials" source="setSpecs.material" />
    <LongTextInput label="Lapel/Collar" source="setSpecs.lapel" />
    <LongTextInput label="Buttons" source="setSpecs.buttons" />
    <LongTextInput label="Additional Bullet (1)" source="setSpecs.additionalBullet1" />
    <LongTextInput label="Additional Bullet (2)" source="setSpecs.additionalBullet2" />
    <LongTextInput label="Additional Bullet (3)" source="setSpecs.additionalBullet3" />
    <LongTextInput label="SEO Tag" source="setSpecs.seoTag" />
    <LongTextInput label="Sizing" source="setSpecs.sizing" />
    <BooleanInput label="Is HTO" source="setSpecs.isHto" />
    <BooleanInput label="Is Party Available" source="setSpecs.isPartyAvailable" />
    <BooleanInput label="Show Product Carousel" source="setSpecs.hasUgc" />
    <BooleanInput label="Show Reviews" source="setSpecs.hasReviews" />
  </FormTab>
)

export default ProductSpecsTab
