import get from 'lodash/get'
import {createSelector, Selector} from 'reselect'

import {ApiRequestStates} from '../../../api/const'
import ProcessOrderPaymentError from '../../../exceptions'
import {RootState} from '../../../store'

import {ProcessPaymentState} from '../../reducers/PaymentPortal/processPayment'

const processPaymentState: Selector<RootState, ProcessPaymentState> = (state) =>
  get(state, 'paymentPortal.processPayment')

export const processPaymentRequestState: Selector<RootState, ApiRequestStates> = (state) =>
  get(processPaymentState(state), 'requestState')

export const isProcessPaymentPending: Selector<RootState, boolean> = createSelector(
  processPaymentRequestState,
  ($requestState) => $requestState === ApiRequestStates.PENDING,
)

export const isProcessPaymentFailure: Selector<RootState, boolean> = createSelector(
  processPaymentRequestState,
  ($requestState) => $requestState === ApiRequestStates.ERROR,
)

export const isProcessPaymentSuccess: Selector<RootState, boolean> = createSelector(
  processPaymentRequestState,
  ($requestState) => $requestState === ApiRequestStates.SUCCESS,
)

export const processPaymentRequestError: Selector<RootState, ProcessOrderPaymentError | null> = (state) =>
  get(processPaymentState(state), 'requestError')

export const processPaymentRequestErrorMessage: Selector<RootState, string | null> = createSelector(
  processPaymentRequestError,
  ($requestError) => $requestError?.message,
)

export const processPaymentRequestInternalErrorCode: Selector<RootState, string | null> = createSelector(
  processPaymentRequestError,
  ($requestError) => $requestError?.internalErrorCode,
)
