import {fsa, fsaPayloadByKeys} from '../helpers'

export const ADD_ITEM_TO_PRODUCT_LIST = 'mti/add item to product list'
export const addItemToProductList = fsaPayloadByKeys(ADD_ITEM_TO_PRODUCT_LIST, ['id', 'product', 'userSizes'])

export const REMOVE_ITEM_FROM_PRODUCT_LIST = 'mti/remove item from product list'
export const removeItemFromProductList = fsaPayloadByKeys(REMOVE_ITEM_FROM_PRODUCT_LIST, ['id'])

export const DISPLAY_SNACKBAR = 'mti/display snackbar'
export const displaySnackbar = fsaPayloadByKeys(DISPLAY_SNACKBAR, ['message'])

export const HIDE_SNACKBAR = 'mti/hide snackbar'
export const hideSnackbar = fsa(HIDE_SNACKBAR)
