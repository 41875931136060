import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import * as store from '../constants/store'
import * as en from '../constants/enums'
import {FourColumnRow, Label} from './ReusableComponents/FormattedRows'
import ReduxFormCheckbox from '../../../components/ReduxFormCheckbox'

function SizeChangedEmailRow(props) {
  return (
    <FourColumnRow
      col1={<Label label="Customer Notifications" />}
      col4={
        <ReduxFormCheckbox
          name={en.assignedFormSizeChangedEmail}
          id={en.assignedFormSizeChangedEmail}
          disabled={props.assignedSizesIsDisabled}
          label="Notify the customer of their updated sizes"
          className="sp-form-field"
        />
      }
      rowClass="sp-form-row"
    />
  )
}

function mapStateToProps(state) {
  return {
    assignedSizesIsDisabled: store.assignedSizesIsDisabled(state),
  }
}

SizeChangedEmailRow.propTypes = {
  assignedSizesIsDisabled: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(SizeChangedEmailRow)
