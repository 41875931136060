import get from 'lodash/get'
import {Selector} from 'reselect'

import {RootState} from '../../../store'
import {Customer} from '../../../types'

export const customer: Selector<RootState, Customer> = (state) => get(state, 'paymentPortal.customer')
export const email: Selector<RootState, string> = (state) => get(customer(state), 'email')
export const firstName: Selector<RootState, string | null> = (state) => get(customer(state), 'firstName')
export const lastName: Selector<RootState, string | null> = (state) => get(customer(state), 'lastName')
export const customerId: Selector<RootState, number | null> = (state) => get(customer(state), 'id')
export const last4CreditCardDigits: Selector<RootState, string> = (state) =>
  get(customer(state), 'last4CreditCardDigits')
