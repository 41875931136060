import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Jumbotron} from 'reactstrap'

const defaultProps = {
  className: undefined,
  lead: undefined,
  title: undefined,
}

const propTypes = {
  className: PropTypes.string,
  lead: PropTypes.string,
  title: PropTypes.string,
}

const ToolTimeJumbotron = ({...props}) => {
  const {className, lead, title, ...attributes} = props

  const classes = classNames(className, ['tooltime-jumbotron'])

  return (
    <Jumbotron {...attributes} className={classes}>
      <div className="tooltime-jumbotron-inner">
        {title ? <h1 className="tooltime-jumbotron-title">{title}</h1> : null}
        {lead ? <p className="tooltime-jumbotron-lead">{lead}</p> : null}
      </div>
    </Jumbotron>
  )
}

ToolTimeJumbotron.defaultProps = defaultProps
ToolTimeJumbotron.propTypes = propTypes

export default ToolTimeJumbotron
