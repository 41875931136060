import axios from 'axios'

import {camelCaseObjectKeys, transformUserInfo} from '../utilities/formatting'
import {constructOptions} from './utils'

const {REACT_APP_MONOLITH_URL, REACT_APP_SIZE_SERVICE_KEY, REACT_APP_SIZE_SERVICE_URL} = window.__RUNTIME_CONFIG__

const constructOptionsForSizeService = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `jwt ${JSON.parse(localStorage.getItem('the-black-tux-admin')).token}`,
    'X-API-Key': REACT_APP_SIZE_SERVICE_KEY,
  },
})

export const getUserInfo = (userId) =>
  axios
    .get(`${REACT_APP_MONOLITH_URL}/users/${userId}/info`, constructOptions())
    .then(camelCaseObjectKeys)
    .then(transformUserInfo)

export const getUserAccountBalance = (userId) =>
  axios
    .get(`${REACT_APP_MONOLITH_URL}/v1/users/${userId}/account-balance`, constructOptions())
    .then(camelCaseObjectKeys)

export const updateUserAccountBalance = (userId, payload) =>
  axios.post(`${REACT_APP_MONOLITH_URL}/v1/users/${userId}/account-balance`, payload, constructOptions())

export const getUserSizes = (userId) =>
  axios
    .get(`${REACT_APP_SIZE_SERVICE_URL}/sizes/user/${userId}/formatted`, constructOptionsForSizeService())
    .then(camelCaseObjectKeys)
