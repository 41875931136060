import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap'

import 'react-select/dist/react-select.css'
import 'react-datepicker/dist/react-datepicker.css'

import {SOLD_OUT_DATES} from '../../routes/routes'
import {getProducts, displayProduct, fetchImpactedProducts, fetchProduct} from '../../actions/products'

import {
  postSoldOutPeriod,
  fetchProductSoldOutPeriods,
  resetSoldOutState,
  deleteSoldOutPeriod,
} from '../../actions/soldOutDates'

import ToolTimeButton from '../../../../components/ToolTimeButton'
import ToolTimeContainer from '../../../../components/ToolTimeContainer'
import ToolTimePageLoading from '../../../../components/ToolTimePageLoading'

class AddSoldOutDates extends Component {
  static propTypes = {
    getProducts: PropTypes.func.isRequired,
    displayProduct: PropTypes.func.isRequired,
    displayedProduct: PropTypes.object,
    postSoldOutPeriod: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    soldOutPeriods: PropTypes.array.isRequired,
    impactedProducts: PropTypes.array,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    fetchProduct: PropTypes.func.isRequired,
    fetchAllInfo: PropTypes.func.isRequired,
    resetSoldOutState: PropTypes.func.isRequired,
    deleteSoldOutPeriod: PropTypes.func.isRequired,
  }

  static defaultProps = {
    displayedProduct: null,
    impactedProducts: undefined,
  }

  state = {
    selectedTransactionType: 'Rental',
    isDropDownOpen: false,
    startDate: '',
    endDate: '',
  }

  componentDidMount() {
    this.props.resetSoldOutState()
    this.props.match.params.id ? this.displayProductInfo() : this.props.getProducts()
  }

  displayProductInfo() {
    const {id} = get(this.props, 'match.params')
    this.props.fetchProduct(id)
    setTimeout(() => {
      this.props.fetchAllInfo(id)
    })
  }

  handleLogChange(data) {
    this.props.displayProduct(data.value)
    setTimeout(() => {
      this.props.fetchAllInfo(this.props.displayedProduct.id)
    })
  }

  handleDateStartChange(date) {
    this.setState({startDate: date})
  }

  handleDateEndChange(date) {
    if (this.state.startDate < date) {
      this.setState({endDate: date})
    }
  }

  dateSubmitCheck() {
    const now = moment()
    return (
      !!this.state.startDate &&
      !!this.state.endDate &&
      this.state.startDate < this.state.endDate &&
      now < this.state.endDate
    )
  }

  handleDateSubmit = (e) => {
    e.preventDefault()
    const payload = {}
    if (this.dateSubmitCheck()) {
      payload.start_date = this.state.startDate.format('YYYY-MM-DD')
      payload.end_date = this.state.endDate.format('YYYY-MM-DD')
      payload.transaction_type = this.state.selectedTransactionType === 'Rental' ? 'rent' : 'buy'
      this.props.postSoldOutPeriod(payload, this.props.displayedProduct.id)
    }
  }

  handleDeleteSoldOut(e, dateId) {
    e.preventDefault()
    this.props.deleteSoldOutPeriod(this.props.displayedProduct.id, dateId)
  }

  handleTransactionTypeDropDown = (transactionType) => {
    this.setState({selectedTransactionType: transactionType})
  }

  toggleTransactionTypeDropdown = () => {
    this.setState((prevState) => ({isDropDownOpen: !prevState.isDropDownOpen}))
  }

  renderSelectField() {
    const isDisabled = !!this.props.match.params.id
    const isClearable = false
    const options = this.props.products
    const placeHolderText = this.props.displayedProduct ? this.props.displayedProduct.name : 'Select a Product'
    const currentValue = this.props.displayedProduct ? this.props.displayedProduct.slug : 'n/a'
    return (
      <Select
        name="form-field-name"
        value={currentValue}
        options={options}
        placeholder={placeHolderText}
        clearable={isClearable}
        disabled={isDisabled}
        onChange={(e) => this.handleLogChange(e)}
      />
    )
  }

  renderSoldOutPeriods(transactionType) {
    const {soldOutPeriods} = this.props
    const datesByTransactionType = soldOutPeriods.filter(
      ({transaction_type: soldOutTransactionType}) => soldOutTransactionType === transactionType,
    )
    const dates = datesByTransactionType.map((date) => {
      const startDate = moment(date.start_date).format('MMM Do, YYYY')
      const endDate = moment(date.end_date).format('MMM Do, YYYY')
      return (
        <ListGroupItem key={date.id}>
          {startDate} - {endDate}
          <span className="col text-right">
            <button onClick={(e) => this.handleDeleteSoldOut(e, date.id)}>DELETE</button>
          </span>
        </ListGroupItem>
      )
    })
    return datesByTransactionType.length > 0 ? dates : <ListGroupItem>N/A</ListGroupItem>
  }

  renderDatePicker() {
    return (
      <div>
        <DatePicker
          {...pickBy({
            selected: this.state.startDate,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          })}
          disabled={!this.props.displayedProduct}
          selectsStart
          onChange={(e) => this.handleDateStartChange(e)}
          placeholderText="Start date"
        />
        <DatePicker
          {...pickBy({
            selected: this.state.endDate,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          })}
          disabled={!this.props.displayedProduct}
          selectsEnd
          onChange={(e) => this.handleDateEndChange(e)}
          placeholderText="End date"
        />
        <br />
        <Button color="success" onClick={this.handleDateSubmit}>
          Add Date Range
        </Button>
      </div>
    )
  }

  renderProductsImpacted() {
    const impacted = this.props.impactedProducts
    if (impacted) {
      const options = impacted.map((product) => <option key={product.slug}>{product.name}</option>)
      return (
        <Input type="select" multiple>
          {impacted.length !== 0 ? options : <option>Not impacting any product sets</option>}
        </Input>
      )
    }
    return <ListGroupItem>n/a</ListGroupItem>
  }

  render() {
    if (this.props.products) {
      return (
        <ToolTimeContainer>
          <Row>
            <Col>
              <h1>Manage Sold Out Periods</h1>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label>Product</Label>
                  {this.renderSelectField()}
                </FormGroup>
              </Col>
            </Row>
            <Row />
            <Row />
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label>Additional Products Impacted</Label>
                  {this.renderProductsImpacted()}
                </FormGroup>
              </Col>
            </Row>
            <Row />
            <Row />
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label>Transaction Type</Label>
                  <Dropdown isOpen={this.state.isDropDownOpen} toggle={this.toggleTransactionTypeDropdown}>
                    <DropdownToggle caret>{this.state.selectedTransactionType}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => this.handleTransactionTypeDropDown('Rental')}>Rental</DropdownItem>
                      <DropdownItem onClick={() => this.handleTransactionTypeDropDown('Buy')}>Buy</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
            <Row />
            <Row />
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label>Dates</Label>
                  <br />
                  {this.renderDatePicker()}
                </FormGroup>
              </Col>
            </Row>
            <Row />
            <Row />
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label>Rent Sold Out Period(s)</Label>
                  <ListGroup>{this.renderSoldOutPeriods('rent')}</ListGroup>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>Buy Sold Out Period(s)</Label>
                  <ListGroup>{this.renderSoldOutPeriods('buy')}</ListGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row />
            <Row />
            <Row>
              <Col>
                <ToolTimeButton buttonTextTitle="Back" size="md" tag={Link} to={SOLD_OUT_DATES} />
              </Col>
            </Row>
          </Form>
        </ToolTimeContainer>
      )
    }
    return <ToolTimePageLoading />
  }
}

function mapStateToProps(state) {
  return {
    products: state.products.products,
    displayedProduct: state.products.displayedProduct,
    impactedProducts: state.products.impactedProducts.related_product_set_list,
    soldOutPeriods: state.soldOutDates.soldOutPeriods,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProducts: () => dispatch(getProducts()),
    displayProduct: (slug) => dispatch(displayProduct(slug)),
    fetchProduct: (id) => dispatch(fetchProduct(id)),
    postSoldOutPeriod: (payload, id) => dispatch(postSoldOutPeriod(payload, id)),
    resetSoldOutState: () => dispatch(resetSoldOutState()),
    deleteSoldOutPeriod: (productId, soldOutId) => dispatch(deleteSoldOutPeriod(productId, soldOutId)),
    fetchAllInfo: (id) => {
      dispatch(fetchImpactedProducts(id))
      dispatch(fetchProductSoldOutPeriods(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSoldOutDates)
