import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = {
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
}

const Throbber = () => <CircularProgress style={styles.loader} />

export default Throbber
