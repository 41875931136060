import {all, call, fork, put, takeLatest} from 'redux-saga/effects'

import * as mtiUserActions from '../../actions/MTI/user'
import * as userApiClient from '../../../api/UsersApiClient'
import {isBadResponse} from '../../../api/helpers'

export function* getUserAccountBalanceWorker({payload: {userId}}) {
  try {
    yield put(mtiUserActions.getUserAccountBalancePending())
    if (!userId) {
      throw new Error('Unable to fetch user account balance. Invalid user ID provided')
    }
    const {data, status} = yield call(userApiClient.getUserAccountBalance, userId)
    if (isBadResponse(status)) {
      throw new Error('Unable to fetch user account balance.')
    }
    yield put(mtiUserActions.getUserAccountBalanceSuccess(data))
  } catch (err) {
    yield put(mtiUserActions.getUserAccountBalanceFailure(err))
  }
}

export function* getUserAccountBalanceSaga() {
  yield takeLatest(mtiUserActions.GET_USER_ACCOUNT_BALANCE, getUserAccountBalanceWorker)
}

export function* getUserInfoWorker({payload: {userId}}) {
  yield put(mtiUserActions.getUserInfoPending())
  try {
    const {data, status} = yield call(userApiClient.getUserInfo, userId)
    if (isBadResponse(status)) {
      throw new Error('Unable to fetch user info.')
    }
    yield put(mtiUserActions.getUserInfoSuccess(data))
  } catch (e) {
    yield put(mtiUserActions.getUserInfoFailure(e))
  }
}

export function* getUserInfoSaga() {
  yield takeLatest(mtiUserActions.GET_USER_INFO, getUserInfoWorker)
}

export function* getUserSizesWorker({payload: {userId}}) {
  yield put(mtiUserActions.getUserSizesPending())
  try {
    const {
      data: {data},
      status,
    } = yield call(userApiClient.getUserSizes, userId)
    if (isBadResponse(status)) {
      throw new Error('Unable to fetch user sizes.')
    }
    yield put(mtiUserActions.getUserSizesSuccess(data))
  } catch (e) {
    yield put(mtiUserActions.getUserSizesFailure(e))
  }
}

export function* getUserSizesSaga() {
  yield takeLatest(mtiUserActions.GET_USER_SIZES, getUserSizesWorker)
}

export default function* mtiUserSagas() {
  yield all([fork(getUserAccountBalanceSaga), fork(getUserInfoSaga), fork(getUserSizesSaga)])
}
