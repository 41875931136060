import React, {Component} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import ActionDeleteIcon from '@material-ui/icons/Delete'
import {red} from '@material-ui/core/colors'

import {deleteProductInSet as deleteProductInSetAction} from '../../../actions/product'

class DeleteProductInSetButton extends Component {
  static propTypes = {
    record: PropTypes.shape({
      productSetId: PropTypes.string.isRequired,
    }),
    deleteProductInSet: PropTypes.func.isRequired,
    source: PropTypes.string.isRequired,
  }

  handleClick = () => {
    const {deleteProductInSet, record, source} = this.props
    const childId = get(record, `${source}.id`)
    deleteProductInSet(record.productSetId, childId)
  }

  render() {
    return (
      <Button onClick={this.handleClick}>
        <ActionDeleteIcon nativeColor={red[500]} />
      </Button>
    )
  }
}

export default connect(null, {deleteProductInSet: deleteProductInSetAction})(DeleteProductInSetButton)
