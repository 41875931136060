import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function Prediction({prediction: {probability, value}}) {
  const percentClasses = classNames({
    'text-success': probability >= 60,
    'text-warning': probability >= 40 && probability < 60,
    'text-danger': probability < 40,
  })
  const percentage = <span className={percentClasses}>{probability}%</span>
  return (
    <span>
      {value} - {percentage}
    </span>
  )
}

Prediction.propTypes = {
  prediction: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    probability: PropTypes.number.isRequired,
  }).isRequired,
}
