import {get} from 'lodash'
import {userQueryName} from './Queries'

export const user = (state) => get(state, `sizesPage.data.${userQueryName}`)
export const survey = (state) => get(state, `sizesPage.data.${userQueryName}.fitProfile`)
export const assignedSizes = (state) => get(state, `sizesPage.data.${userQueryName}.sizes`)
export const assignedSizeValidators = (state) => get(state, 'sizesPage.data.sizeValidators')
export const requiredSizeFields = (state) => get(assignedSizeValidators(state), 'required')
export const surveyValidators = (state) => get(state, 'sizesPage.data.fitProfileValidators')
export const requiredSurveyFields = (state) => get(surveyValidators(state), 'required')
export const formFields = (state) => get(state, 'sizesPage.data.adminSizesFormFields')
export const surveyFormFields = (state) => get(state, 'sizesPage.data.adminSizesFormFields.fitProfile')
export const sizeFormFields = (state) => get(state, 'sizesPage.data.adminSizesFormFields.sizes')
export const predictions = (state, size) =>
  get(state, `sizesPage.data.${userQueryName}.fitProfile.fitPredictions[0].${size}.probabilities`)
export const surveyIsDisabled = (state) => get(state, 'sizesPage.surveyIsDisabled')
export const assignedSizesIsDisabled = (state) => get(state, 'sizesPage.assignedSizesIsDisabled')
export const emailError = (state) => get(state, 'sizesPage.emailError')
