import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {reduxForm, SubmissionError, propTypes} from 'redux-form'
import {connect} from 'react-redux'
import {isEmpty} from 'lodash'

import * as lib from '../constants/lib'
import * as en from '../constants/enums'
import * as store from '../constants/store'

import {ErrorAlert, SuccessAlert} from '../../../components/ToolTimeAlerts'
import SizeSectionContainer from './SizeSectionContainer'
import FormSubmitRow from './FormSubmitRow'
import AuditFields from './AuditFields'
import BodyProfile from './BodyProfile'
import JacketVestSizes from './JacketVestSizes'
import ShirtSizes from './ShirtSizes'
import PantBeltSizes from './PantBeltSizes'
import ShoeSizes from './ShoeSizes'
import SizeCheckDetails from './SizeCheckDetails'

class SizesForm extends Component {
  constructor(props) {
    super(props)

    this.computeField = this.computeField.bind(this)
    this.saveAssignedSizes = this.saveAssignedSizes.bind(this)
    this.saveSurvey = this.saveSurvey.bind(this)
  }

  saveAssignedSizes(formValues) {
    const errors = lib.markMissingFields(formValues, this.props.requiredSizeFields)
    if (!isEmpty(errors)) {
      throw new SubmissionError(errors)
    }
    const assignedSizes = en.assignedSizes.convertToApiFields(formValues)
    const assignedSizesToSave = {...assignedSizes, [en.assignedApiSizeChecked]: true}
    return this.props.submitAssignedSizes(assignedSizesToSave)
  }

  saveSurvey(formValues) {
    const errors = lib.markMissingFields(formValues, this.props.requiredSurveyFields)
    if (!isEmpty(errors)) {
      throw new SubmissionError(errors)
    }
    const surveyToSave = en.survey.convertToApiFields(formValues)
    return this.props.submitSurvey(surveyToSave)
  }

  // takes in a field name and a calculation function to compute a value for the field
  computeField(formField, calculation) {
    // this function is passed to the onChange Field prop which takes in the new value of the field
    return (event, newVal) => {
      const calculatedValue = calculation(newVal)
      this.props.change(formField, calculatedValue)
    }
  }

  formErrors() {
    let errorMessage = 'Changes cannot be saved. An error occurred.'
    if (this.props.error) {
      // If error details exist, display them
      errorMessage = 'Changes can not be saved. Error: '
      this.props.error.forEach((error) => {
        errorMessage += ` ${error}`
      })
    }
    return <ErrorAlert key="form-error">{errorMessage}</ErrorAlert>
  }

  renderAlerts() {
    const formErrors = this.props.invalid && this.formErrors()
    const emailError = this.props.emailError && <ErrorAlert key="email-error">{this.props.emailError}</ErrorAlert>
    const success = this.props.submitSucceeded && this.props.pristine && (
      <SuccessAlert key="success-alert">Submit succeeded!</SuccessAlert>
    )
    return [success, emailError, formErrors]
  }

  render() {
    const Alerts = this.renderAlerts()

    return (
      <section className="pt-3">
        <h2>Sizes</h2>
        {Alerts}
        <SizeSectionContainer>
          <AuditFields />
          <form>
            <BodyProfile />
            <JacketVestSizes computeField={this.computeField} />
            <PantBeltSizes computeField={this.computeField} />
            <ShirtSizes />
            <ShoeSizes />
            <SizeCheckDetails />
            {Alerts}
            <FormSubmitRow
              reset={this.props.reset}
              saveSurvey={this.props.handleSubmit(this.saveSurvey)}
              saveAssignedSizes={this.props.handleSubmit(this.saveAssignedSizes)}
              submitting={this.props.submitting}
            />
          </form>
        </SizeSectionContainer>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    survey: store.survey(state) || undefined,
    requiredSizeFields: en.assignedSizes.requiredFormFields(store.requiredSizeFields(state)),
    requiredSurveyFields: en.survey.requiredFormFields(store.requiredSurveyFields(state)),
    emailError: store.emailError(state),
  }
}

SizesForm.propTypes = {
  ...propTypes,
  requiredSizeFields: PropTypes.array.isRequired,
  requiredSurveyFields: PropTypes.array.isRequired,
  submitSurvey: PropTypes.func.isRequired,
  submitAssignedSizes: PropTypes.func.isRequired,
}

const SizesReduxForm = reduxForm({
  form: en.formName,
})(SizesForm)

export default connect(mapStateToProps)(SizesReduxForm)
