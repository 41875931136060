import React from 'react'
import PropTypes from 'prop-types'
import {formValueSelector} from 'redux-form'
import {connect} from 'react-redux'
import * as en from '../constants/enums'
import * as store from '../constants/store'
import {valueToLabel} from '../constants/lib'
import {FourColumnRow, Label} from './ReusableComponents/FormattedRows'
import {AssignedSizesSelectField} from './ReusableComponents/FormSelectFields'

// PREFERRED FIT //
function PreferredFitUnconnected({fit, surveyFormFields}) {
  const fitLabel = valueToLabel(surveyFormFields.preferredFit)(fit)
  return <div className="sp-display-field">{fitLabel || en.noneLabel}</div>
}

PreferredFitUnconnected.defaultProps = {
  fit: undefined,
  surveyFormFields: {},
}

PreferredFitUnconnected.propTypes = {
  fit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  surveyFormFields: PropTypes.object,
}

const selector = formValueSelector(en.formName)

function mapStateToProps(state) {
  return {
    fit: selector(state, en.surveyFormPreferredFit),
    surveyFormFields: store.surveyFormFields(state) || undefined,
  }
}

export const PreferredFit = connect(mapStateToProps)(PreferredFitUnconnected)

// PREFERRED FIT ROW //
export function PreferredFitRow({label, assignedSizes}) {
  return (
    <FourColumnRow
      col1={<Label label={label} />}
      col2={<PreferredFit />}
      col4={<AssignedSizesSelectField {...assignedSizes} />}
      rowClass="sp-form-row"
    />
  )
}

PreferredFitRow.propTypes = {
  label: PropTypes.string.isRequired,
  assignedSizes: PropTypes.object.isRequired,
}
